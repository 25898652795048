import React from 'react';
import {
  Box,
  Heading,
  Text,
  VStack,
  HStack,
  Button,
  useColorModeValue,
  Icon,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Divider,
} from '@chakra-ui/react';
import { FaRegCreditCard, FaHistory, FaFileInvoiceDollar, FaCheckCircle } from 'react-icons/fa';

const Billing = () => {
  const bgColor = useColorModeValue('gray.50', 'gray.700');
  const cardBgColor = useColorModeValue('white', 'gray.600');

  return (
    <Box maxWidth="container.xl" margin="auto" p={8}>
      <VStack spacing={8} align="stretch">
        <Heading as="h1" size="2xl">Billing</Heading>
        
        {/* Subscription Details */}
        <Box bg={bgColor} borderRadius="lg" p={6}>
          <VStack spacing={4} align="stretch">
            <HStack justify="space-between">
              <Heading as="h2" size="lg">Your Subscription</Heading>
              <Icon as={FaCheckCircle} color="green.500" boxSize={6} />
            </HStack>
            <Stat>
              <StatLabel>Current Plan</StatLabel>
              <StatNumber>Premium Plan</StatNumber>
              <StatHelpText>Billed monthly</StatHelpText>
            </Stat>
            <Text>Enjoy full access to all features!</Text>
            <HStack>
              <Button colorScheme="red" variant="outline">Cancel Subscription</Button>
              <Button colorScheme="blue">Manage Subscription</Button>
            </HStack>
          </VStack>
        </Box>

        <Divider />

        <HStack spacing={4} align="stretch">
          {/* Payment Methods Card */}
          <Box flex={1} bg={cardBgColor} borderRadius="lg" p={6} boxShadow="md">
            <VStack align="start" spacing={4}>
              <Icon as={FaRegCreditCard} boxSize={8} color="blue.500" />
              <Heading as="h3" size="md">Payment Methods</Heading>
              <Text>Manage your payment methods and add new ones.</Text>
              <Button variant="outline" colorScheme="blue">Manage</Button>
            </VStack>
          </Box>

          {/* Billing History Card */}
          <Box flex={1} bg={cardBgColor} borderRadius="lg" p={6} boxShadow="md">
            <VStack align="start" spacing={4}>
              <Icon as={FaHistory} boxSize={8} color="green.500" />
              <Heading as="h3" size="md">Billing History</Heading>
              <Text>View your past invoices and payment history.</Text>
              <Button variant="outline" colorScheme="green">View History</Button>
            </VStack>
          </Box>

          {/* Invoices Card */}
          <Box flex={1} bg={cardBgColor} borderRadius="lg" p={6} boxShadow="md">
            <VStack align="start" spacing={4}>
              <Icon as={FaFileInvoiceDollar} boxSize={8} color="purple.500" />
              <Heading as="h3" size="md">Invoices</Heading>
              <Text>Download and manage your invoices.</Text>
              <Button variant="outline" colorScheme="purple">Manage Invoices</Button>
            </VStack>
          </Box>
        </HStack>
      </VStack>
    </Box>
  );
};

export default Billing;