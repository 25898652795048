import React, { useState, useRef, useCallback } from 'react';
import {
  Box,
  Button,
  Text,
  VStack,
  HStack,
  Flex,
  Icon,
  Avatar,
  useColorMode,
  Skeleton,
  InputGroup,
  InputLeftElement,
  Input,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  Checkbox,
} from '@chakra-ui/react';
import { toast } from 'react-toastify';
import { FaStar, FaEnvelope, FaEnvelopeOpen, FaClock, FaInbox, FaSearch, FaArchive, FaSync, FaEllipsisV, FaTrash } from 'react-icons/fa';
import InfiniteScroll from 'react-infinite-scroll-component';
import axios from 'axios';

const ConversationListSkeleton = () => {
  return (
    <VStack spacing={4} p={4}>
      {[...Array(5)].map((_, i) => (
        <HStack key={i} width="100%" spacing={4} p={4} borderWidth="1px" borderRadius="md">
          <Skeleton borderRadius="full" boxSize="48px" />
          <VStack align="start" flex={1} spacing={2}>
            <Skeleton height="20px" width="150px" />
            <Skeleton height="16px" width="200px" />
          </VStack>
          <Skeleton height="20px" width="20px" borderRadius="full" />
          <Skeleton height="20px" width="20px" borderRadius="full" />
        </HStack>
      ))}
    </VStack>
  );
};

const ConversationList = ({
  contacts,
  setContacts,
  onSelectContact,
  selectedContactId,
  onUpdateContact,
  hasMore,
  loadMore,
  activeTab,
  setActiveTab,
  userTags,
  loading,
  onSearch,
  onRefresh,
}) => {
  const { colorMode } = useColorMode();
  const isDark = colorMode === 'dark';

  const listRef = useRef(null);
  const [searchQuery, setSearchQuery] = useState('');
  const [isBulkMode, setIsBulkMode] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState([]);

  const handleSelectContact = async (contact) => {
    if (contact.unread) {
      try {
        const token = JSON.parse(localStorage.getItem('userInfo')).token;
        const response = await axios.put(
          `/api/contacts/${contact._id}/mark-read`,
          {},
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.data) {
          onUpdateContact({ 
            ...contact, 
            ...response.data, 
            unread: false,
            messages: contact.messages || response.data.messages,
            lastMessage: contact.lastMessage || response.data.lastMessage
          });
        }
      } catch (error) {
        console.error('Error marking contact as read:', error);
        toast.error('Failed to mark contact as read');
      }
    }
    onSelectContact(contact);
  };

  const handleStarContact = async (e, contactId) => {
    e.stopPropagation();
    try {
      const token = JSON.parse(localStorage.getItem('userInfo')).token;
      const response = await fetch(`/api/contacts/${contactId}/star`, {
        method: 'PUT',
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });
      if (!response.ok) throw new Error('Failed to star contact');
      const updatedContact = await response.json();
      onUpdateContact(updatedContact);
    } catch (error) {
      console.error('Error starring contact:', error);
      toast.error('Failed to star contact');
    }
  };

  const handleToggleUnread = async (e, contact) => {
    e.stopPropagation();
    try {
      const token = JSON.parse(localStorage.getItem('userInfo')).token;
      const response = await axios.put(
        `/api/contacts/${contact._id}/toggle-unread`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data) {
        // Preserve the existing messages when updating the contact
        onUpdateContact({
          ...response.data,
          messages: contact.messages || response.data.messages,
          lastMessage: contact.lastMessage || response.data.lastMessage
        });
      } else {
        throw new Error('Failed to toggle unread status');
      }
    } catch (error) {
      console.error('Error toggling unread status:', error);
      toast.error('Failed to update unread status');
    }
  };

  const handleToggleArchive = async (e, contact) => {
    e.stopPropagation();
    try {
      const token = JSON.parse(localStorage.getItem('userInfo')).token;
      const response = await axios.put(
        `/api/contacts/${contact._id}/toggle-archive`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data) {
        // Preserve the existing messages and add archived status
        onUpdateContact({
          ...contact,
          ...response.data,
          archived: response.data.archived,
          messages: contact.messages || response.data.messages,
          lastMessage: contact.lastMessage || response.data.lastMessage
        });
      }
    } catch (error) {
      console.error('Error toggling archive status:', error);
      toast.error('Failed to update archive status');
    }
  };

  const bgColor = isDark ? 'gray.800' : 'white';
  const hoverBgColor = isDark ? 'gray.700' : 'gray.100';
  const borderColor = isDark ? 'gray.600' : 'gray.200';
  const textColor = isDark ? 'white' : 'gray.800';
  const mutedTextColor = isDark ? 'gray.400' : 'gray.500';
  const unreadTextColor = isDark ? 'blue.300' : 'blue.700';
  const scrollbarTrackColor = isDark ? '#2D3748' : '#f1f1f1';
  const scrollbarThumbColor = isDark ? '#4A5568' : '#888';
  const scrollbarThumbHoverColor = isDark ? '#2D3748' : '#555';

  // Move getTagColor inside the component with access to props and isDark
  const getTagColor = (tagIds) => {
    if (Array.isArray(tagIds) && tagIds.length > 0) {
      const firstTagId = tagIds[0];
      // Find the tag by ID in userTags
      const tag = Object.values(userTags).find(t => t._id === firstTagId);
      if (tag) {
        return tag.color;
      }
    }
    // Default color if no tags or tag color not found
    return isDark ? 'gray.600' : 'gray.300';
  };

  // Simplified search handler
  const handleSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
    
    // Clean phone numbers only if the query contains digits
    if (/\d/.test(query)) {
      // If query has consecutive digits with formatting characters, clean it
      const cleanedQuery = query.replace(/\(?\d[\d\s\-\(\)]+\d/g, match => {
        return match.replace(/[^\d]/g, '');
      });
      onSearch(cleanedQuery);
    } else {
      onSearch(query);
    }
  };

  // Update the refresh handler
  const handleRefresh = useCallback(() => {
    setSearchQuery(''); // Clear search
    onRefresh(); // Call the parent's refresh handler
  }, [onRefresh]);

  // Add bulk action handlers
  const handleBulkSelect = (contactId) => {
    setSelectedContacts(prev => 
      prev.includes(contactId) 
        ? prev.filter(id => id !== contactId)
        : [...prev, contactId]
    );
  };

  const handleSelectAll = () => {
    setSelectedContacts(prev => 
      prev.length === contacts.length ? [] : contacts.map(c => c._id)
    );
  };

  const handleBulkAction = async (action) => {
    if (selectedContacts.length === 0) {
      toast.warning('Please select conversations first');
      return;
    }

    try {
      const token = JSON.parse(localStorage.getItem('userInfo')).token;
      const response = await axios.post(
        `/api/contacts/bulk-${action}`,
        { contactIds: selectedContacts },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.data.success) {
        // Clear selections and exit bulk mode
        setSelectedContacts([]);
        setIsBulkMode(false);
        
        // Show success message
        toast.success(`Bulk ${action} completed successfully`);
        
        // Refresh the list for all bulk actions
        onRefresh();
      }
    } catch (error) {
      console.error(`Error performing bulk ${action}:`, error);
      toast.error(`Failed to ${action} selected conversations`);
    }
  };

  return (
    <Box height="100%" display="flex" flexDirection="column">
      {/* Static header section */}
      <Box flex="0 0 auto">
        {/* Tabs */}
        <HStack spacing={2} mb={4}>
          <Button
            onClick={() => setActiveTab('unread')}
            variant={activeTab === 'unread' ? 'solid' : 'ghost'}
            colorScheme={activeTab === 'unread' ? 'blue' : 'gray'}
            _focus={{ boxShadow: 'none' }}
            _hover={{ bg: activeTab === 'unread' ? 'blue.500' : 'gray.200' }}
            leftIcon={<Icon as={FaEnvelope} />}
          >
            Unread
          </Button>
          <Button
            onClick={() => setActiveTab('recents')}
            variant={activeTab === 'recents' ? 'solid' : 'ghost'}
            colorScheme={activeTab === 'recents' ? 'blue' : 'gray'}
            _focus={{ boxShadow: 'none' }}
            _hover={{ bg: activeTab === 'recents' ? 'blue.500' : 'gray.200' }}
            leftIcon={<Icon as={FaClock} />}
          >
            Recents
          </Button>
          <Button
            onClick={() => setActiveTab('starred')}
            variant={activeTab === 'starred' ? 'solid' : 'ghost'}
            colorScheme={activeTab === 'starred' ? 'blue' : 'gray'}
            _focus={{ boxShadow: 'none' }}
            _hover={{ bg: activeTab === 'starred' ? 'blue.500' : 'gray.200' }}
            leftIcon={<Icon as={FaStar} />}
          >
            Starred
          </Button>
          <Button
            onClick={() => setActiveTab('all')}
            variant={activeTab === 'all' ? 'solid' : 'ghost'}
            colorScheme={activeTab === 'all' ? 'blue' : 'gray'}
            _focus={{ boxShadow: 'none' }}
            _hover={{ bg: activeTab === 'all' ? 'blue.500' : 'gray.200' }}
            leftIcon={<Icon as={FaInbox} />}
          >
            All
          </Button>
        </HStack>

        {/* Search bar */}
        <HStack spacing={2} mb={4}>
          <InputGroup flex="1">
            <InputLeftElement pointerEvents="none">
              <Icon as={FaSearch} color="gray.300" />
            </InputLeftElement>
            <Input
              value={searchQuery}
              onChange={handleSearch}
              placeholder="Search conversations..."
              borderRadius="md"
              bg={isDark ? 'gray.700' : 'white'}
              _placeholder={{ color: isDark ? 'gray.400' : 'gray.500' }}
            />
          </InputGroup>
          <Menu>
            <MenuButton
              as={IconButton}
              aria-label="Bulk actions"
              icon={<FaEllipsisV />}
              variant="ghost"
              size="md"
            />
            <MenuList>
              <MenuItem onClick={() => setIsBulkMode(!isBulkMode)}>
                {isBulkMode ? 'Exit Bulk Mode' : 'Enter Bulk Mode'}
              </MenuItem>
              {isBulkMode && (
                <>
                  <MenuItem onClick={handleSelectAll}>
                    {selectedContacts.length === contacts.length 
                      ? 'Deselect All' 
                      : 'Select All'}
                  </MenuItem>
                  <MenuItem 
                    onClick={() => handleBulkAction('star')}
                    icon={<FaStar color="gold" />}
                  >
                    Star Selected
                  </MenuItem>
                  <MenuItem 
                    onClick={() => handleBulkAction('unstar')}
                    icon={<FaStar color="gray" />}
                  >
                    Unstar Selected
                  </MenuItem>
                  <MenuItem 
                    onClick={() => handleBulkAction('archive')}
                    icon={<FaArchive />}
                  >
                    Archive Selected
                  </MenuItem>
                  <MenuItem 
                    onClick={() => handleBulkAction('unarchive')}
                    icon={<FaInbox />}
                  >
                    Unarchive Selected
                  </MenuItem>
                  <MenuItem 
                    onClick={() => handleBulkAction('mark-read')}
                    icon={<FaEnvelopeOpen />}
                  >
                    Mark Read
                  </MenuItem>
                  <MenuItem 
                    onClick={() => handleBulkAction('mark-unread')}
                    icon={<FaEnvelope />}
                  >
                    Mark Unread
                  </MenuItem>
                </>
              )}
            </MenuList>
          </Menu>
          <Button
            onClick={handleRefresh}
            isLoading={loading}
            variant="ghost"
            size="md"
            aria-label="Refresh conversations"
          >
            <Icon as={FaSync} />
          </Button>
        </HStack>
      </Box>

      {/* Scrollable conversation list */}
      <Box
        flex="1 1 auto"
        id="scrollableDiv"
        overflowY="auto"
        position="relative"
        sx={{
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-track': {
            background: scrollbarTrackColor,
          },
          '&::-webkit-scrollbar-thumb': {
            background: scrollbarThumbColor,
            borderRadius: '6px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: scrollbarThumbHoverColor,
          },
        }}
      >
        {loading && contacts.length === 0 ? (
          <ConversationListSkeleton />
        ) : (
          <InfiniteScroll
            dataLength={contacts.length}
            next={loadMore}
            hasMore={hasMore}
            loader={<ConversationListSkeleton />}
            scrollableTarget="scrollableDiv"
            style={{ overflow: 'hidden' }}
          >
            {contacts.length > 0 ? (
              <VStack spacing={2} align="stretch" px={2}>
                {contacts.map((contact, index) => (
                  <Box
                    key={contact._id}
                    onClick={() => isBulkMode ? handleBulkSelect(contact._id) : handleSelectContact(contact)}
                    p={4}
                    cursor="pointer"
                    bg={
                      contact._id === selectedContactId
                        ? isDark
                          ? 'blue.700'
                          : 'blue.100'
                        : bgColor
                    }
                    _hover={{
                      bg:
                        contact._id === selectedContactId
                          ? isDark
                            ? 'blue.700'
                            : 'blue.100'
                          : hoverBgColor,
                    }}
                    transition="all 0.2s ease"
                    width="100%"
                    borderRadius="lg"
                    boxShadow="sm"
                    border="1px solid"
                    borderColor={borderColor}
                  >
                    <HStack spacing={4}>
                      {isBulkMode && (
                        <Checkbox
                          isChecked={selectedContacts.includes(contact._id)}
                          onChange={(e) => {
                            e.stopPropagation();
                            handleBulkSelect(contact._id);
                          }}
                          colorScheme="blue"
                        />
                      )}
                      <Box position="relative">
                        <Avatar
                          name={contact.firstName || contact.contactNumber}
                          bg={getTagColor(contact.tags)}
                          color={isDark ? 'white' : 'gray.800'}
                          size="md"
                        />
                        {contact.unread && (
                          <Box
                            position="absolute"
                            top="-2px"
                            right="-2px"
                            width="14px"
                            height="14px"
                            borderRadius="full"
                            bg={isDark ? 'blue.300' : 'blue.500'}
                            border="2px solid"
                            borderColor={isDark ? 'gray.800' : 'white'}
                          />
                        )}
                      </Box>
                      <Box flex="1" maxW="70%">
                        <Text
                          fontWeight="bold"
                          color={contact.unread ? unreadTextColor : textColor}
                          noOfLines={1}
                        >
                          {(contact.firstName || contact.lastName
                            ? `${contact.firstName || ''} ${contact.lastName || ''}`.trim()
                            : contact.contactNumber).length > 20 
                            ? `${(contact.firstName || contact.lastName
                                ? `${contact.firstName || ''} ${contact.lastName || ''}`.trim()
                                : contact.contactNumber).slice(0, 20)}...`
                            : (contact.firstName || contact.lastName
                                ? `${contact.firstName || ''} ${contact.lastName || ''}`.trim()
                                : contact.contactNumber)}
                        </Text>
                        <Text fontSize="sm" color={mutedTextColor} noOfLines={1}>
                          {(contact.lastMessage?.text || 
                            (contact.messages && contact.messages.length > 0 
                              ? contact.messages[contact.messages.length - 1].text 
                              : 'No messages'))
                            .replace(/\\n/g, ' ')  // Replace newlines with spaces for preview
                          }
                        </Text>
                      </Box>
                      <Icon
                        as={FaStar}
                        boxSize={5}
                        color={
                          contact.starred
                            ? 'yellow.400'
                            : isDark
                            ? 'gray.600'
                            : 'gray.300'
                        }
                        onClick={(e) => handleStarContact(e, contact._id)}
                        cursor="pointer"
                      />
                      <Icon
                        as={contact.unread ? FaEnvelope : FaEnvelopeOpen}
                        boxSize={5}
                        color={contact.unread ? (isDark ? 'blue.300' : 'blue.500') : (isDark ? 'gray.600' : 'gray.300')}
                        onClick={(e) => handleToggleUnread(e, contact)}
                        cursor="pointer"
                      />
                      <Icon
                        as={FaArchive}
                        boxSize={5}
                        color={
                          contact.archived
                            ? (isDark ? 'purple.300' : 'purple.500')
                            : (isDark ? 'gray.600' : 'gray.300')
                        }
                        onClick={(e) => handleToggleArchive(e, contact)}
                        cursor="pointer"
                      />
                    </HStack>
                  </Box>
                ))}
              </VStack>
            ) : (
              <Flex 
                height="calc(100vh - 200px)" 
                justify="center" 
                align="center"
              >
                <Text 
                  fontSize="lg" 
                  color={mutedTextColor}
                  textAlign="center"
                >
                  {searchQuery 
                    ? "No conversations match your search" 
                    : activeTab === 'all' 
                      ? "You don't have any messages yet."
                      : activeTab === 'unread' 
                        ? "You don't have any unread messages."
                        : activeTab === 'starred' 
                          ? "You don't have any starred messages."
                          : "You don't have any recent conversations."
                  }
                </Text>
              </Flex>
            )}
          </InfiniteScroll>
        )}
      </Box>
    </Box>
  );
};

export default ConversationList;
