import React from 'react';
import {
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  Box,
  Icon,
  useColorModeValue,
} from '@chakra-ui/react';

const StatCard = ({ title, value, icon, helpText, isLoading }) => {
  const bgColor = useColorModeValue('white', 'gray.800');
  const hoverBg = useColorModeValue('gray.50', 'gray.700');

  return (
    <Box
      p={6}
      bg={bgColor}
      borderRadius="xl"
      boxShadow="lg"
      transition="all 0.3s"
      _hover={{ bg: hoverBg, transform: 'translateY(-2px)', boxShadow: 'xl' }}
    >
      <Stat>
        <StatLabel
          fontSize="lg"
          fontWeight="medium"
          isTruncated
          display="flex"
          alignItems="center"
          gap={2}
        >
          <Icon as={icon} color="blue.500" boxSize={5} />
          {title}
        </StatLabel>
        <StatNumber
          fontSize="3xl"
          fontWeight="bold"
          mt={2}
        >
          {isLoading ? (
            <Box as="span" opacity={0.4}>
              -
            </Box>
          ) : (
            new Intl.NumberFormat().format(value)
          )}
        </StatNumber>
        <StatHelpText color="gray.500">
          {helpText}
        </StatHelpText>
      </Stat>
    </Box>
  );
};

export default StatCard; 