import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import { 
  Box, 
  Input, 
  Button, 
  Spinner, 
  Text, 
  Flex, 
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalHeader, 
  ModalBody, 
  ModalCloseButton,
  InputGroup,
  InputLeftElement,
  Icon,
  Stack,
  VStack,
  useColorModeValue,
  SimpleGrid,
  FormControl,
  FormLabel,
  Avatar,
  Heading,
  Divider,
  Card,
  CardHeader,
  CardBody,
  HStack,
  IconButton,
  Wrap,
  WrapItem,
  Badge,
  Tag,
  TagLabel,
  TagCloseButton,
  Select,
  useDisclosure,
  Skeleton,
} from '@chakra-ui/react';
import { FaSearch, FaPlus } from 'react-icons/fa';
import { EditIcon, PhoneIcon, EmailIcon, InfoIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';
import { getToken } from '../utils/auth'; // Make sure this path is correct
import Pagination from './Pagination'; // Add this import
import { toast } from 'react-toastify';
import { debounce } from 'lodash'; // Make sure to install lodash if you haven't already
import * as api from '../utils/api'; // Add this import

const TagSelector = ({ availableTags = [], selectedTags = [], onTagChange }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleTagClick = (tagId) => {
    const newSelectedTags = selectedTags.includes(tagId)
      ? selectedTags.filter(t => t !== tagId)
      : [...selectedTags, tagId];
    onTagChange(newSelectedTags);
  };

  const filteredTags = availableTags.filter(tag => 
    tag.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
    !selectedTags.includes(tag.id) // Exclude selected tags
  );

  return (
    <Box>
      <Input
        placeholder="Search tags..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        mb={4}
      />
      <Flex direction="column">
        <Box mb={4}>
          <Text fontWeight="bold" mb={2}>Available Tags:</Text>
          <Wrap>
            {filteredTags.map(tag => (
              <WrapItem key={tag.id}>
                <Tag 
                  size="md" 
                  cursor="pointer"
                  onClick={() => handleTagClick(tag.id)}
                  backgroundColor={tag.color || 'gray.200'} // Apply tag color
                  color="white"
                >
                  <TagLabel>{tag.name}</TagLabel>
                </Tag>
              </WrapItem>
            ))}
          </Wrap>
        </Box>
        <Box>
          <Text fontWeight="bold" mb={2}>Added Tags:</Text>
          <Wrap>
            {selectedTags.map(tagId => {
              const tag = availableTags.find(t => t.id === tagId);
              return tag ? (
                <WrapItem key={tagId}>
                  <Tag 
                    size="md"
                    backgroundColor={tag.color || 'blue.500'} // Apply tag color
                    color="white"
                  >
                    <TagLabel>{tag.name}</TagLabel>
                    <TagCloseButton onClick={() => handleTagClick(tagId)} />
                  </Tag>
                </WrapItem>
              ) : null;
            })}
          </Wrap>
        </Box>
      </Flex>
    </Box>
  );
};

const ContactList = () => {
  const [contacts, setContacts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState('');
  const [debouncedSearch, setDebouncedSearch] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [selectedContact, setSelectedContact] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editedContact, setEditedContact] = useState(null);
  const [availableTags, setAvailableTags] = useState([]);
  const [opportunities, setOpportunities] = useState([]);

  const bgColor = useColorModeValue('white', 'gray.800');
  const cardBgColor = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const inputBgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const hoverBgColor = useColorModeValue('gray.100', 'gray.700');
  const headerBgColor = useColorModeValue('gray.50', 'gray.700');

  const { isOpen: isContactDetailsOpen, onOpen: openContactDetails, onClose: closeContactDetails } = useDisclosure();

  const fetchContacts = useCallback(async () => {
    setLoading(true);
    try {
      const data = await api.get(`/api/contacts?page=${currentPage}&limit=10&search=${debouncedSearch}`);
      setContacts(data.contacts);
      setTotalPages(data.totalPages);
    } catch (error) {
      setError('Failed to fetch contacts');
      toast.error('Failed to fetch contacts');
      console.error(`Error fetching contacts:`, error);
    } finally {
      setLoading(false);
    }
  }, [currentPage, debouncedSearch]);

  useEffect(() => {
    fetchContacts();
  }, [fetchContacts]);

  // Debounce the search input
  useEffect(() => {
    const debouncedSearchChange = debounce(() => {
      setDebouncedSearch(search);
      setCurrentPage(1); // Reset to the first page on search
    }, 300); // Wait for 300ms of inactivity before triggering the search

    debouncedSearchChange();

    return () => {
      debouncedSearchChange.cancel();
    };
  }, [search]);

  const handleSearchChange = (e) => {
    setSearch(e.target.value);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const fetchContactDetails = async (contactId) => {
    try {
      const token = getToken();
      const [contactResponse, tagsResponse] = await Promise.all([
        axios.get(`/api/contacts/${contactId}`, {
          headers: { Authorization: `Bearer ${token}` }
        }),
        axios.get('/api/tags', {
          headers: { Authorization: `Bearer ${token}` }
        })
      ]);

      const contactData = {
        ...contactResponse.data,
        tags: Array.isArray(contactResponse.data.tags) 
          ? contactResponse.data.tags 
          : contactResponse.data.tags.split(',').map(tag => tag.trim())
      };

      setSelectedContact(contactData);
      setEditedContact(contactData);
      setAvailableTags(tagsResponse.data.map(tag => ({ id: tag._id, name: tag.name, color: tag.color })));
      openContactDetails(); // Open the modal after fetching details
    } catch (error) {
      console.error(`Error fetching contact details: ${error.message}\n${error.stack}`);
      toast.error('Failed to fetch contact details');
      setSelectedContact(null);
      setEditedContact(null);
    }
  };

  const handleEdit = () => setEditMode(true);

  const handleSave = async () => {
    try {
      const token = getToken();
      const contactToSend = {
        ...editedContact,
        tags: editedContact.tags // Ensure tags are sent as an array of IDs
      };
      const response = await axios.put(`/api/contacts/${selectedContact._id}`, contactToSend, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const updatedContact = {
        ...response.data,
        tags: editedContact.tags // Ensure tags remain as an array
      };
      
      // Update the contact in the contacts list
      setContacts(prevContacts => 
        prevContacts.map(contact => 
          contact._id === updatedContact._id ? updatedContact : contact
        )
      );

      toast.success('Contact updated successfully');
      
      // Close the modal
      closeContactDetails();
      
      // Reset states
      setSelectedContact(null);
      setEditedContact(null);
      setEditMode(false);
    } catch (error) {
      console.error(`Error updating contact: ${error.message}\n${error.stack}`);
      toast.error('Failed to update contact');
    }
  };

  const handleCancel = () => {
    setEditedContact(selectedContact);
    setEditMode(false);
  };

  const handleChange = (field, value) => setEditedContact({ ...editedContact, [field]: value });

  const handleTagsChange = (selectedTagIds) => setEditedContact({ ...editedContact, tags: selectedTagIds });

  const fields = [
    { name: 'firstName', label: 'First Name', icon: InfoIcon },
    { name: 'lastName', label: 'Last Name', icon: InfoIcon },
    { name: 'phone', label: 'Phone', icon: PhoneIcon },
    { name: 'email', label: 'Email', icon: EmailIcon },
    { name: 'address', label: 'Address', icon: InfoIcon },
    { name: 'city', label: 'City', icon: InfoIcon },
    { name: 'state', label: 'State', icon: InfoIcon },
  ];

  const ContactRow = ({ contact }) => (
    <Box
      w="100%"
      p={2}
      borderBottom="1px"
      borderColor={borderColor}
      onClick={() => fetchContactDetails(contact._id)} // Ensure this calls fetchContactDetails
      cursor="pointer"
      bg={bgColor}
    >
      <Box
        p={3}
        borderRadius="md"
        transition="all 0.2s"
        _hover={{
          bg: hoverBgColor,
          transform: 'translateY(-4px)',
          boxShadow: 'lg',
          zIndex: 2,
        }}
      >
        <Flex>
          <Box flex="1" minWidth="0" pr={2}>
            <Text color={useColorModeValue('blue.600', 'blue.200')} fontWeight="medium" isTruncated>
              {`${contact.firstName} ${contact.lastName}`}
            </Text>
          </Box>
          <Box flex="1" minWidth="0" pr={2}>
            <Text isTruncated color={textColor}>{contact.phone || 'N/A'}</Text>
          </Box>
          <Box flex="1" minWidth="0">
            <Text isTruncated color={textColor}>{contact.email || 'N/A'}</Text>
          </Box>
        </Flex>
      </Box>
    </Box>
  );

  const renderContactList = () => {
    if (loading) {
      return (
        <Box borderWidth="1px" borderRadius="lg" overflow="hidden" borderColor={borderColor}>
          <Flex bg={headerBgColor} p={3} fontWeight="bold" color={textColor}>
            <Box flex="1" pr={2}>Name</Box>
            <Box flex="1" pr={2}>Phone</Box>
            <Box flex="1">Email</Box>
          </Flex>
          <VStack spacing={0} align="stretch">
            {[...Array(5)].map((_, i) => (
              <Box key={i} p={2} borderBottom="1px" borderColor={borderColor}>
                <Flex>
                  <Skeleton height="20px" flex="1" mr={4} />
                  <Skeleton height="20px" flex="1" mr={4} />
                  <Skeleton height="20px" flex="1" />
                </Flex>
              </Box>
            ))}
          </VStack>
        </Box>
      );
    }

    if (error) {
      return <Text color="red.500" textAlign="center">{error}</Text>;
    }

    return (
      <>
        <Box borderWidth="1px" borderRadius="lg" overflow="hidden" borderColor={borderColor}>
          <Flex bg={headerBgColor} p={3} fontWeight="bold" color={textColor}>
            <Box flex="1" pr={2}>Name</Box>
            <Box flex="1" pr={2}>Phone</Box>
            <Box flex="1">Email</Box>
          </Flex>
          <VStack spacing={0} align="stretch">
            {contacts.map(contact => (
              <ContactRow key={contact._id} contact={contact} />
            ))}
          </VStack>
        </Box>

        <Pagination
          currentPage={currentPage}
          totalPages={totalPages}
          onPageChange={handlePageChange}
        />
      </>
    );
  };

  useEffect(() => {
    fetchOpportunities();
  }, []);

  const fetchOpportunities = async () => {
    try {
      const token = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).token : '';
      const { data } = await axios.get('/api/opportunities', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setOpportunities(data);
    } catch (error) {
      console.error('Error fetching opportunities:', error);
      toast.error('Failed to fetch opportunities');
    }
  };

  const handleOpportunityChange = async (contactId, opportunityId) => {
    try {
      const token = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).token : '';
      const { data } = await axios.put(`/api/contacts/${contactId}`, {
        opportunity: opportunityId
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setContacts(prevContacts => 
        prevContacts.map(c => c._id === contactId ? data : c)
      );
      setSelectedContact(data);
      toast.success('Opportunity updated successfully');
    } catch (error) {
      console.error('Error updating opportunity:', error);
      toast.error('Failed to update opportunity');
    }
  };

  return (
    <Box width="100%" bg={bgColor} color={textColor}>
      <Stack spacing={6}>
        <Flex justify="space-between" align="center" wrap="wrap">
          <InputGroup maxW="md" mb={{ base: 4, md: 0 }}>
            <InputLeftElement pointerEvents="none">
              <Icon as={FaSearch} color="gray.300" />
            </InputLeftElement>
            <Input
              value={search}
              onChange={handleSearchChange}
              placeholder="Search contacts"
              borderRadius="md"
            />
          </InputGroup>
        </Flex>

        {renderContactList()}

        <Modal isOpen={isContactDetailsOpen} onClose={closeContactDetails} size="2xl">
          <ModalOverlay />
          <ModalContent bg={bgColor} mt={10}>
            <ModalCloseButton 
              position="absolute"
              right={3}
              top={3}
              zIndex={1}
              bg={cardBgColor}
              borderRadius="full"
            />
            <ModalBody p={6}>
              {selectedContact ? (
                <Card bg={cardBgColor} shadow="md" borderRadius="lg" overflow="hidden">
                  <CardHeader pt={10}>
                    <Flex justify="space-between" align="center">
                      <HStack spacing={4}>
                        <Avatar size="lg" name={`${selectedContact.firstName} ${selectedContact.lastName}`} src={selectedContact.avatar} />
                        <Box>
                          <Heading size="md" color={textColor}>
                            {selectedContact.firstName} {selectedContact.lastName}
                          </Heading>
                          <Text color="gray.500">{selectedContact.email}</Text>
                        </Box>
                      </HStack>
                      {!editMode && (
                        <IconButton
                          aria-label="Edit contact"
                          icon={<EditIcon />}
                          onClick={handleEdit}
                          colorScheme="blue"
                          variant="ghost"
                        />
                      )}
                    </Flex>
                  </CardHeader>
                  <Divider />
                  <CardBody>
                    <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                      {fields.map((field) => (
                        <FormControl key={field.name}>
                          <FormLabel color="gray.500" fontSize="sm">
                            <HStack spacing={1}>
                              <field.icon />
                              <Text>{field.label}</Text>
                            </HStack>
                          </FormLabel>
                          {editMode ? (
                            <Input
                              value={editedContact[field.name]}
                              onChange={(e) => handleChange(field.name, e.target.value)}
                              bg={inputBgColor}
                              size="sm"
                            />
                          ) : (
                            <Text fontSize="sm" color={textColor}>{selectedContact[field.name]}</Text>
                          )}
                        </FormControl>
                      ))}
                    </SimpleGrid>
                    
                    <VStack spacing={4} mt={6} align="stretch">
                      <FormControl>
                        <FormLabel color="gray.500" fontSize="sm">Tags</FormLabel>
                        {editMode ? (
                          <TagSelector
                            availableTags={availableTags}
                            selectedTags={editedContact.tags}
                            onTagChange={handleTagsChange}
                          />
                        ) : (
                          <Wrap>
                            {selectedContact.tags.map(tagId => {
                              const tag = availableTags.find(t => t.id === tagId);
                              return tag ? (
                                <WrapItem key={tagId}>
                                  <Tag
                                    size="md"
                                    backgroundColor={tag.color || 'blue.500'}
                                    color="white"
                                  >
                                    {tag.name}
                                  </Tag>
                                </WrapItem>
                              ) : null;
                            })}
                          </Wrap>
                        )}
                      </FormControl>
                    </VStack>

                    <FormControl mt={4}>
                      <FormLabel color="gray.500" fontSize="sm">Opportunity</FormLabel>
                      <Select
                        value={selectedContact?.opportunity?._id || selectedContact?.opportunity || ''}
                        onChange={(e) => handleOpportunityChange(selectedContact._id, e.target.value)}
                        bg={inputBgColor}
                        size="sm"
                        placeholder="Select an opportunity"
                      >
                        {opportunities.map((opp) => (
                          <option key={opp._id} value={opp._id}>
                            {opp.name}
                          </option>
                        ))}
                      </Select>
                    </FormControl>

                    {editMode && (
                      <HStack justify="flex-end" spacing={4} mt={6}>
                        <Button onClick={handleCancel} variant="outline" leftIcon={<CloseIcon />} size="sm">
                          Cancel
                        </Button>
                        <Button onClick={handleSave} colorScheme="green" leftIcon={<CheckIcon />} size="sm">
                          Save
                        </Button>
                      </HStack>
                    )}
                  </CardBody>
                </Card>
              ) : (
                <Text>Loading contact details...</Text>
              )}
            </ModalBody>
          </ModalContent>
        </Modal>
      </Stack>
    </Box>
  );
};

export default ContactList;
