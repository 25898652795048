import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getToken } from '../utils/auth';
import OpportunityList from '../components/OpportunityList';
import { Box, Skeleton, Stack, VStack, Text, Button, Container, useColorModeValue } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import Header from '../components/Header';
import NewOpportunityForm from '../components/NewOpportunityForm';

const Opportunities = () => {
  const [loading, setLoading] = useState(true);
  const [opportunities, setOpportunities] = useState([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [error, setError] = useState(null);

  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const cardBgColor = useColorModeValue('white', 'gray.700');

  useEffect(() => {
    const fetchOpportunities = async () => {
      try {
        const token = getToken();
        const { data } = await axios.get('/api/opportunities', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setOpportunities(data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching opportunities:', error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchOpportunities();
  }, []);

  const handleNewOpportunityCreated = (newOpportunity) => {
    setOpportunities([newOpportunity, ...opportunities]);
    setIsFormOpen(false);
  };

  if (loading) {
    return (
      <>
        <Header />
        <Box bg={bgColor} minHeight="100vh" py={10}>
          <Container maxW="container.2xl">
            <Stack spacing={4}>
              {[...Array(3)].map((_, i) => (
                <Box key={i} bg={cardBgColor} borderRadius="xl" p={6} boxShadow="lg">
                  <Skeleton height="40px" mb={4} />
                  <Stack spacing={2}>
                    <Skeleton height="20px" />
                    <Skeleton height="20px" />
                    <Skeleton height="20px" />
                  </Stack>
                </Box>
              ))}
            </Stack>
          </Container>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header />
      <Box bg={bgColor} minHeight="100vh" py={10}>
        <Container maxW="container.2xl">
          {opportunities.length === 0 ? (
            <Box bg={cardBgColor} borderRadius="xl" p={8} boxShadow="lg">
              <VStack spacing={4} align="center">
                <Text fontSize="lg" fontWeight="medium" color="gray.500">
                  No opportunities yet
                </Text>
                <Text color="gray.500" textAlign="center">
                  Create your first opportunity to start tracking potential deals
                </Text>
                <Button
                  leftIcon={<AddIcon />}
                  colorScheme="blue"
                  size="lg"
                  borderRadius="full"
                  boxShadow="md"
                  onClick={() => setIsFormOpen(true)}
                >
                  New Opportunity
                </Button>
              </VStack>
            </Box>
          ) : (
            <OpportunityList 
              initialLoading={loading}
            />
          )}
        </Container>
      </Box>

      {isFormOpen && (
        <NewOpportunityForm
          onClose={() => setIsFormOpen(false)}
          onOpportunityCreated={handleNewOpportunityCreated}
        />
      )}
    </>
  );
};

export default Opportunities;
