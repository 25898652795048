import React, { useState, useEffect } from 'react';
import {
  Box, Flex, Heading, Input, Button, InputGroup, InputLeftAddon, useColorModeValue, Icon,
  VStack, HStack, Text, Card, CardBody, IconButton, Tooltip, Alert, AlertIcon,
  AlertTitle, AlertDescription, Grid, GridItem, useToast, Tabs, TabList, TabPanels, Tab, TabPanel,
  Spinner, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,
  Skeleton
} from '@chakra-ui/react';
import { SearchIcon, InfoIcon, AddIcon } from '@chakra-ui/icons';
import { FaGlobe, FaMapMarkerAlt, FaPhoneAlt, FaCommentAlt, FaEnvelope } from 'react-icons/fa';
import axios from 'axios';
import { getToken } from '../utils/auth';
import { motion } from 'framer-motion';
import Header from '../components/Header';

const FeatureIcon = ({ icon, label }) => (
  <Tooltip label={label}>
    <Icon as={icon} boxSize={5} color="blue.500" />
  </Tooltip>
);

const ResultCard = ({ number, onViewDetails, onPurchase, isPurchased = false, isLoading }) => {
  const handleViewDetails = () => {
    if (number.id) {
      onViewDetails(number);
    } else {
      console.error('Phone number ID is missing:', number);
      // You can show an error toast here
    }
  };

  const phoneNumber = number.phone_number || number.number;

  return (
    <motion.div whileHover={{ scale: 1.02 }} whileTap={{ scale: 0.98 }}>
      <Card boxShadow="md" borderRadius="lg">
        <CardBody>
          <Flex justifyContent="space-between" alignItems="center">
            <VStack align="start" spacing={2}>
              <Text fontWeight="bold" fontSize="xl">{phoneNumber}</Text>
              {!isPurchased && (
                <HStack spacing={3}>
                  <FeatureIcon icon={FaPhoneAlt} label="Voice" />
                  <FeatureIcon icon={FaCommentAlt} label="SMS" />
                  <FeatureIcon icon={FaEnvelope} label="MMS" />
                </HStack>
              )}
              <Text fontSize="sm" color="gray.500">Monthly Cost: $1.10</Text>
            </VStack>
            <HStack>
              {!isPurchased && (
                <Tooltip label={isLoading ? "Processing..." : "Purchase Number"}>
                  <IconButton
                    icon={<AddIcon />}
                    onClick={() => onPurchase(number)}
                    aria-label="Purchase Number"
                    colorScheme="green"
                    isLoading={isLoading}
                    isDisabled={isLoading}
                  />
                </Tooltip>
              )}
            </HStack>
          </Flex>
        </CardBody>
      </Card>
    </motion.div>
  );
};

const SearchResultsSkeleton = () => (
  <VStack spacing={4} align="stretch">
    <Skeleton height="40px" width="200px" mb={2} />
    {[...Array(3)].map((_, i) => (
      <Card key={i}>
        <CardBody>
          <HStack spacing={4}>
            <Skeleton height="40px" width="150px" />
            <Skeleton height="40px" flex="1" />
            <Skeleton height="40px" width="100px" />
          </HStack>
        </CardBody>
      </Card>
    ))}
  </VStack>
);

const NumberDetailsSkeleton = () => (
  <VStack align="start" spacing={4} width="100%">
    <Skeleton height="24px" width="60%" />
    <Skeleton height="24px" width="80%" />
    <Skeleton height="24px" width="40%" />
    <Skeleton height="24px" width="70%" />
    <Skeleton height="24px" width="50%" />
    <Skeleton height="24px" width="90%" />
    <Box width="100%" pl={4}>
      <VStack align="start" spacing={3}>
        <Skeleton height="20px" width="40%" />
        <Skeleton height="20px" width="60%" />
        <Skeleton height="20px" width="50%" />
        <Skeleton height="20px" width="45%" />
      </VStack>
    </Box>
  </VStack>
);

const PhoneNumbers = () => {
  const [searchParams, setSearchParams] = useState({
    area_code: '',
  });
  const [searchResults, setSearchResults] = useState([]);
  const [purchasedNumbers, setPurchasedNumbers] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [purchasedLoading, setPurchasedLoading] = useState(false);
  const [error, setError] = useState(null);
  const [notice, setNotice] = useState(null);
  const [selectedNumber, setSelectedNumber] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [numberOrderDetails, setNumberOrderDetails] = useState(null);

  const toast = useToast();
  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const cardBgColor = useColorModeValue('white', 'gray.700');

  const handleInputChange = (e) => {
    const value = e.target.value.replace(/\D/g, '').slice(0, 3);
    setSearchParams({ ...searchParams, [e.target.name]: value });
  };

  const searchNumbers = async () => {
    if (searchParams.area_code.length !== 3) {
      setError("Please enter a valid 3-digit area code.");
      return;
    }

    setSearchLoading(true);
    setError(null);
    setNotice(null);
    setSearchResults([]);
    try {
      const queryParams = new URLSearchParams({
        'country_code': 'US',
        'area_code': searchParams.area_code,
        'features': 'sms,mms,voice',
        'limit': '15'
      });

      const response = await axios.get(`/api/phone-numbers/search?${queryParams}`);
      
      setSearchResults(response.data.data);

      if (response.data.data.length === 0) {
        setNotice(`No numbers found for area code ${searchParams.area_code}`);
      }
    } catch (err) {
      if (err.response && err.response.status === 404) {
        setNotice(`No phone numbers available for area code ${searchParams.area_code}`);
      } else {
        setError(`An error occurred: ${err.message}`);
      }
    } finally {
      setSearchLoading(false);
    }
  };

  const fetchPurchasedNumbers = async () => {
    setPurchasedLoading(true);
    setError(null);
    try {
      const token = getToken();
      const response = await axios.get('/api/phone-numbers/purchased', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log('Purchased numbers response:', response.data);
      // Ensure we're using the correct data structure
      setPurchasedNumbers(response.data.data.map(item => ({
        phone_number: item.phone_number
      })));
    } catch (err) {
      console.error("Error fetching purchased numbers:", err);
      setError(`Failed to fetch purchased numbers: ${err.message}`);
    } finally {
      setPurchasedLoading(false);
    }
  };

  useEffect(() => {
    const token = getToken();
    if (token) {
      fetchPurchasedNumbers();
    }
  }, []);

  const viewNumberDetails = async (number) => {
    if (!number.id) {
      console.error('Attempting to view details for a number without an ID:', number);
      toast({
        title: "Error",
        description: "Unable to view details for this number",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    setSelectedNumber(number);
    setIsModalOpen(true);
    try {
      const token = getToken();
      console.log('Fetching details for phone number ID:', number.id);
      const response = await axios.get(`/api/phone-numbers/${number.id}/messaging`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log('Phone number messaging settings response:', response.data);
      setNumberOrderDetails(response.data.data);
    } catch (err) {
      console.error("Error fetching phone number messaging settings:", err);
      let errorMessage = "Failed to fetch phone number messaging settings";
      if (err.response) {
        switch (err.response.status) {
          case 404:
            errorMessage = "Phone number not found";
            break;
          case 403:
            errorMessage = "Permission denied to access phone number details";
            break;
        }
      }
      toast({
        title: "Error",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedNumber(null);
    setNumberOrderDetails(null);
  };

  const purchaseNumber = async (number) => {
    setSearchLoading(true);
    try {
      const token = getToken();
      const response = await axios.post('/api/phone-numbers/purchase', {
        phone_number: number.phone_number,
      }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      console.log('Purchase response:', response.data);
      
      if (response.data && response.data.success) {
        const purchasedNumber = response.data.data;
        toast({
          title: "Number Purchased",
          description: `Successfully purchased ${purchasedNumber.phone_number}`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        fetchPurchasedNumbers();
        
        // Fetch a new batch of numbers after successful purchase
        searchNumbers();
      } else {
        throw new Error('Purchase was not successful');
      }
    } catch (err) {
      console.error('Purchase error:', err.response?.data || err.message);
      
      if (err.response?.status === 422) {
        toast({
          title: "Purchase Failed",
          description: "This number is currently unavailable. Please try another number or try again later.",
          status: "warning",
          duration: 5000,
          isClosable: true,
        });
        // Refresh the search results
        searchNumbers();
      } else {
        toast({
          title: "Purchase Failed",
          description: err.response?.data?.error || err.message,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } finally {
      setSearchLoading(false);
    }
  };

  return (
    <>
      <Header />
      <Box p={8} bg={bgColor} minH="100vh">
        <Tabs isFitted variant="soft-rounded" colorScheme="blue">
          <TabList mb="1em">
            <Tab _selected={{ color: 'white', bg: 'blue.500' }}>Search Numbers</Tab>
            <Tab _selected={{ color: 'white', bg: 'blue.500' }}>Purchased Numbers</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 0.5 }}>
                <Card bg={cardBgColor} p={6} borderRadius="lg" boxShadow="lg" mb={6}>
                  <VStack spacing={4} align="stretch">
                    <Grid templateColumns="repeat(12, 1fr)" gap={4}>
                      <GridItem colSpan={[12, 8, 10]}>
                        <InputGroup size="lg">
                          <InputLeftAddon children={<Icon as={FaMapMarkerAlt} />} />
                          <Input
                            name="area_code"
                            value={searchParams.area_code}
                            onChange={handleInputChange}
                            placeholder="Enter Area Code"
                            _placeholder={{ color: 'gray.500' }}
                          />
                        </InputGroup>
                      </GridItem>
                      <GridItem colSpan={[12, 4, 2]}>
                        <Button
                          leftIcon={<SearchIcon />}
                          colorScheme="blue"
                          onClick={searchNumbers}
                          isLoading={searchLoading}
                          loadingText="Searching"
                          width="100%"
                          size="lg"
                        >
                          Search
                        </Button>
                      </GridItem>
                    </Grid>
                  </VStack>
                </Card>

                {error && (
                  <Alert status="error" mb={6}>
                    <AlertIcon />
                    <AlertTitle mr={2}>Error</AlertTitle>
                    <AlertDescription>{error}</AlertDescription>
                  </Alert>
                )}

                {notice && (
                  <Alert status="info" mb={6}>
                    <AlertIcon />
                    <AlertDescription>{notice}</AlertDescription>
                  </Alert>
                )}

                {searchLoading ? (
                  <SearchResultsSkeleton />
                ) : (
                  searchResults.length > 0 && (
                    <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} transition={{ duration: 0.5 }}>
                      <VStack spacing={4} align="stretch">
                        <Heading size="lg" mb={2}>Search Results</Heading>
                        {searchResults.map((number, index) => (
                          <ResultCard
                            key={index}
                            number={number}
                            onViewDetails={viewNumberDetails}
                            onPurchase={purchaseNumber}
                            isLoading={searchLoading}
                          />
                        ))}
                      </VStack>
                    </motion.div>
                  )
                )}
              </motion.div>
            </TabPanel>
            <TabPanel>
              <VStack spacing={4} align="stretch">
                {purchasedLoading ? (
                  <SearchResultsSkeleton />
                ) : purchasedNumbers.length > 0 ? (
                  purchasedNumbers.map((number, index) => (
                    <ResultCard
                      key={index}
                      number={number}
                      onViewDetails={viewNumberDetails}
                      isPurchased={true}
                    />
                  ))
                ) : (
                  <Text>No purchased numbers found.</Text>
                )}
              </VStack>
            </TabPanel>
          </TabPanels>
        </Tabs>

        <Modal isOpen={isModalOpen} onClose={closeModal} size="xl">
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Phone Number Messaging Settings</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              {!numberOrderDetails ? (
                <NumberDetailsSkeleton />
              ) : (
                <VStack align="start" spacing={4}>
                  <Text><strong>Phone Number:</strong> {numberOrderDetails.phone_number}</Text>
                  <Text><strong>Messaging Profile ID:</strong> {numberOrderDetails.messaging_profile_id}</Text>
                  <Text><strong>Country Code:</strong> {numberOrderDetails.country_code}</Text>
                  <Text><strong>Type:</strong> {numberOrderDetails.type}</Text>
                  <Text><strong>Traffic Type:</strong> {numberOrderDetails.traffic_type}</Text>
                  <Text><strong>Messaging Product:</strong> {numberOrderDetails.messaging_product}</Text>
                  {numberOrderDetails.health && (
                    <>
                      <Text><strong>Health:</strong></Text>
                      <VStack align="start" pl={4}>
                        <Text>Message Count: {numberOrderDetails.health.message_count}</Text>
                        <Text>Inbound/Outbound Ratio: {numberOrderDetails.health.inbound_outbound_ratio.toFixed(2)}</Text>
                        <Text>Success Ratio: {numberOrderDetails.health.success_ratio.toFixed(2)}</Text>
                        <Text>Spam Ratio: {numberOrderDetails.health.spam_ratio.toFixed(2)}</Text>
                      </VStack>
                    </>
                  )}
                  <Text><strong>Eligible Messaging Products:</strong> {numberOrderDetails.eligible_messaging_products.join(', ')}</Text>
                </VStack>
              )}
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={closeModal}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>
    </>
  );
};

export default PhoneNumbers;
