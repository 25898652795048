import React, { useRef } from 'react';
import { VStack, HStack, Button, FormControl, FormLabel, Box, Input, Select, IconButton, Textarea, Text, useColorModeValue, Tooltip, Menu, MenuButton, MenuList, MenuItem, useToast } from '@chakra-ui/react';
import { AddIcon, TimeIcon, DeleteIcon, ChevronUpIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { FaRandom, FaUser } from 'react-icons/fa';

const WorkflowItem = React.memo(({ item, index, onRemove, onChange, onMoveUp, onMoveDown, isFirst, isLast }) => {
  const messageBg = useColorModeValue('blue.50', 'blue.900');
  const delayBg = useColorModeValue('green.50', 'green.900');
  const textColor = useColorModeValue('gray.800', 'white');
  const textareaRef = useRef(null);

  const handleInsertVariable = () => {
    const textarea = textareaRef.current;
    if (textarea) {
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const text = textarea.value;
      const before = text.substring(0, start);
      const after = text.substring(end, text.length);
      const newText = `${before}{{x|y}}${after}`;
      onChange(index, { ...item, text: newText });
      
      // Set cursor position after the inserted variable
      setTimeout(() => {
        textarea.selectionStart = textarea.selectionEnd = start + 7; // Changed from 6 to 7
        textarea.focus();
      }, 0);
    }
  };

  const handleInsertPlaceholder = (placeholder) => {
    const textarea = textareaRef.current;
    if (textarea) {
      const start = textarea.selectionStart;
      const end = textarea.selectionEnd;
      const text = textarea.value;
      const before = text.substring(0, start);
      const after = text.substring(end, text.length);
      const newText = `${before}{{${placeholder}}}${after}`;
      onChange(index, { ...item, text: newText });
      
      setTimeout(() => {
        textarea.selectionStart = textarea.selectionEnd = start + placeholder.length + 4;
        textarea.focus();
      }, 0);
    }
  };

  const handleItemChange = (index, newItem) => {
    // Don't convert newlines here - just update the display value
    onChange(index, newItem);
  };

  return (
    <Box
      mb={4}
      p={4}
      bg={item.type === 'message' ? messageBg : delayBg}
      borderRadius="lg"
      boxShadow="md"
    >
      <VStack spacing={4} align="stretch">
        {item.type === 'message' ? (
          <HStack align="flex-start">
            <Textarea
              ref={textareaRef}
              placeholder="Message text"
              value={item.text || ''}
              onChange={(e) => handleItemChange(index, { ...item, text: e.target.value })}
              resize="vertical"
              color={textColor}
              whiteSpace="pre-wrap"
              sx={{
                '&': {
                  whiteSpace: 'pre-wrap'
                },
                '&::placeholder': {
                  whiteSpace: 'pre-wrap'
                }
              }}
            />
            <Tooltip label="Insert variable: Use this to randomize messages by adding multiple words separated by pipes (|) that will be selected at random when workflow is used. There is no limit to how many words you can use. For example, {{Hey|Hi|Hello}}." placement="top">
              <IconButton
                icon={<FaRandom />}
                onClick={handleInsertVariable}
                aria-label="Insert variable"
                size="sm"
              />
            </Tooltip>
            <Menu>
              <Tooltip label="Insert contact placeholder: Use this to add personalized information about the contact." placement="top">
                <MenuButton as={IconButton} icon={<FaUser />} size="sm" />
              </Tooltip>
              <MenuList>
                <MenuItem onClick={() => handleInsertPlaceholder('firstName')}>First Name</MenuItem>
                <MenuItem onClick={() => handleInsertPlaceholder('lastName')}>Last Name</MenuItem>
                <MenuItem onClick={() => handleInsertPlaceholder('phone')}>Phone</MenuItem>
                <MenuItem onClick={() => handleInsertPlaceholder('email')}>Email</MenuItem>
                <MenuItem onClick={() => handleInsertPlaceholder('address')}>Address</MenuItem>
                <MenuItem onClick={() => handleInsertPlaceholder('city')}>City</MenuItem>
                <MenuItem onClick={() => handleInsertPlaceholder('state')}>State</MenuItem>
                <MenuItem onClick={() => handleInsertPlaceholder('occupation')}>Occupation</MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        ) : (
          <HStack>
            <Input
              type="number"
              placeholder="Delay"
              value={item.delay}
              onChange={(e) => onChange(index, { ...item, delay: parseInt(e.target.value) })}
              width="150px"
              color={textColor}
            />
            <Select
              value={item.delayUnit || 'minutes'}
              onChange={(e) => onChange(index, { ...item, delayUnit: e.target.value })}
              width="120px"
              color={textColor}
            >
              <option value="minutes">Minutes</option>
              <option value="hours">Hours</option>
              <option value="days">Days</option>
            </Select>
            <Text fontSize="sm" color={textColor}>
              {item.delay} {item.delayUnit || 'minutes'} delay
            </Text>
          </HStack>
        )}
        <HStack justify="flex-end">
          <IconButton
            icon={<ChevronUpIcon />}
            onClick={() => onMoveUp(index)}
            isDisabled={isFirst}
            aria-label="Move up"
          />
          <IconButton
            icon={<ChevronDownIcon />}
            onClick={() => onMoveDown(index)}
            isDisabled={isLast}
            aria-label="Move down"
          />
          <IconButton
            icon={<DeleteIcon />}
            onClick={() => onRemove(index)}
            colorScheme="red"
            aria-label="Remove item"
          />
        </HStack>
      </VStack>
    </Box>
  );
});

const WorkflowElements = ({ workflowItems, handleAddItem, handleRemoveItem, handleItemChange, handleMoveItem }) => {
  const formLabelColor = useColorModeValue('gray.700', 'gray.200');
  const toast = useToast();

  const handleAddDelay = () => {
    const lastItem = workflowItems[workflowItems.length - 1];
    if (lastItem && lastItem.type === 'delay') {
      toast({
        title: "Delays Combined",
        description: "Consecutive delays will be automatically combined into a single delay when saving.",
        status: "info",
        duration: 5000,
        isClosable: true,
      });
    }
    handleAddItem('delay');
  };

  return (
    <FormControl>
      <FormLabel fontSize="lg" mb={4} color={formLabelColor}>Workflow Items</FormLabel>
      <VStack spacing={4} align="stretch" mb={6}>
        {workflowItems.map((item, index) => (
          <WorkflowItem
            key={item.id}
            item={item}
            index={index}
            onRemove={handleRemoveItem}
            onChange={handleItemChange}
            onMoveUp={() => handleMoveItem(index, 'up')}
            onMoveDown={() => handleMoveItem(index, 'down')}
            isFirst={index === 0}
            isLast={index === workflowItems.length - 1}
          />
        ))}
      </VStack>
      <HStack mt={4} justify="center">
        <Button
          leftIcon={<AddIcon />}
          onClick={() => handleAddItem('message')}
          colorScheme="blue"
          size="lg"
        >
          Add Message
        </Button>
        <Button
          leftIcon={<TimeIcon />}
          onClick={handleAddDelay}
          colorScheme="green"
          size="lg"
        >
          Add Delay
        </Button>
      </HStack>
    </FormControl>
  );
};

export default WorkflowElements;
