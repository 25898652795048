import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Box, Button, FormControl, FormLabel, Input, Text, VStack, useColorMode } from '@chakra-ui/react';

const Register = ({ setIsAuthenticated }) => {
  const { colorMode } = useColorMode();
  const isDark = colorMode === 'dark';

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      console.log('Attempting registration...', { name, email }); // Debug log
      const response = await axios.post(`${process.env.REACT_APP_API_URL}/api/users/register`, { name, email, password });
      localStorage.setItem('userInfo', JSON.stringify(response.data));
      setIsAuthenticated(true);
      toast.success('Registration successful');
      console.log('Registration successful:', response.data);
      navigate('/dashboard');
    } catch (err) {
      setError(err.response?.data?.message || 'Registration failed');
      toast.error(err.response?.data?.message || 'Registration failed');
      console.error('Registration error:', err.response?.data || err.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box display="flex" justifyContent="center" alignItems="center" h="100vh" bg={isDark ? "gray.800" : "gray.50"}>
      <Box bg={isDark ? "gray.700" : "white"} p={6} rounded="md" shadow="md" w="full" maxW="md">
        <Text fontSize="2xl" mb={4} textAlign="center" color={isDark ? "white" : "black"}>Register</Text>
        {error && <Text color="red.500" mb={4}>{error}</Text>}
        <form onSubmit={handleSubmit}>
          <VStack spacing={4}>
            <FormControl>
              <FormLabel color={isDark ? "gray.200" : "gray.700"}>Name</FormLabel>
              <Input
                type="text"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                isDisabled={loading}
                bg={isDark ? "gray.600" : "white"}
                color={isDark ? "white" : "black"}
              />
            </FormControl>
            <FormControl>
              <FormLabel color={isDark ? "gray.200" : "gray.700"}>Email</FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
                isDisabled={loading}
                bg={isDark ? "gray.600" : "white"}
                color={isDark ? "white" : "black"}
              />
            </FormControl>
            <FormControl>
              <FormLabel color={isDark ? "gray.200" : "gray.700"}>Password</FormLabel>
              <Input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
                isDisabled={loading}
                bg={isDark ? "gray.600" : "white"}
                color={isDark ? "white" : "black"}
              />
            </FormControl>
            <Button type="submit" colorScheme="blue" w="full" isLoading={loading}>Register</Button>
          </VStack>
        </form>
        <Text mt={4} textAlign="center" color={isDark ? "gray.200" : "gray.600"}>
          Already have an account? <Link to="/login" style={{color: isDark ? 'lightblue' : 'blue'}}>Login here</Link>
        </Text>
      </Box>
    </Box>
  );
};

export default Register;
