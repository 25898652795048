import React from 'react';
import { VStack, HStack, Button, useColorModeValue, Box } from '@chakra-ui/react';

const DayChecklist = ({ selectedDays, onChange }) => {
  const days = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  const activeBg = useColorModeValue('blue.500', 'blue.200');
  const activeColor = useColorModeValue('white', 'gray.800');
  const inactiveBg = useColorModeValue('gray.100', 'gray.700');
  const inactiveColor = useColorModeValue('gray.800', 'white');

  const toggleDay = (day) => {
    const updatedDays = selectedDays.includes(day)
      ? selectedDays.filter(d => d !== day)
      : [...selectedDays, day];
    onChange(updatedDays);
  };

  const handleSelectAll = () => {
    onChange(days);
  };

  const handleUnselectAll = () => {
    onChange([]);
  };

  return (
    <VStack spacing={4} align="stretch">
      <Box>
        <HStack spacing={4} justify="flex-start">
          <Button
            size="sm"
            colorScheme="blue"
            onClick={handleSelectAll}
          >
            Select All
          </Button>
          <Button
            size="sm"
            colorScheme="red"
            onClick={handleUnselectAll}
          >
            Unselect All
          </Button>
        </HStack>
      </Box>
      <HStack spacing={2} wrap="wrap" justify="flex-start">
        {days.map((day) => (
          <Button
            key={day}
            size="sm"
            onClick={() => toggleDay(day)}
            bg={selectedDays.includes(day) ? activeBg : inactiveBg}
            color={selectedDays.includes(day) ? activeColor : inactiveColor}
            _hover={{
              bg: selectedDays.includes(day) ? 'blue.600' : 'gray.200',
            }}
            borderRadius="full"
          >
            {day}
          </Button>
        ))}
      </HStack>
    </VStack>
  );
};

export default DayChecklist;
