import React, { useState, useEffect } from 'react';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import { BrowserRouter as Router, Route, Routes, useLocation, Navigate } from 'react-router-dom';
import theme from './theme/theme';
import Dashboard from './pages/Dashboard';
import Login from './pages/Login';
import Register from './pages/Register';
import Conversations from './pages/Conversations';
import Contacts from './pages/Contacts';
import ContactProfile from './pages/ContactProfile';
import WorkflowEditor from './components/WorkflowEditor'; // Import WorkflowEditor component
import Opportunities from './pages/Opportunities';
import Tags from './pages/Tags';
import Uploads from './pages/Uploads';
import PhoneNumbers from './pages/PhoneNumbers';
import Settings from './pages/Settings';
import Sidebar from './components/Sidebar';
import axios from 'axios';
import { Spinner, Box, Text } from '@chakra-ui/react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WorkflowsList from './pages/WorkflowsList'; // Import WorkflowsList component
import NotFound from './pages/404'; // Add this import
import { useColorMode } from '@chakra-ui/react';
import Responses from './pages/Responses'; // Add this import
import DLC from './pages/DLC';

axios.defaults.baseURL = '';
axios.defaults.withCredentials = true;
axios.defaults.headers.common['Content-Type'] = 'application/json';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  useEffect(() => {
    const userInfo = localStorage.getItem('userInfo');
    if (userInfo) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
    setLoading(false);
  }, []);

  if (loading) {
    return (
      <ChakraProvider theme={theme}>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <Spinner size="xl" />
        </Box>
      </ChakraProvider>
    );
  }

  if (error) {
    return (
      <ChakraProvider theme={theme}>
        <ColorModeScript initialColorMode={theme.config.initialColorMode} />
        <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
          <Text color="red.500">{error}</Text>
        </Box>
      </ChakraProvider>
    );
  }

  return (
    <ChakraProvider theme={theme}>
      <ColorModeScript initialColorMode={theme.config.initialColorMode} />
      <ToastContainer />
      <Router>
        <AppContent isAuthenticated={isAuthenticated} setIsAuthenticated={setIsAuthenticated} />
      </Router>
    </ChakraProvider>
  );
}

function AppContent({ isAuthenticated, setIsAuthenticated }) {
  const location = useLocation();
  const { colorMode, setColorMode } = useColorMode();

  useEffect(() => {
    const fetchUserData = async () => {
      const userInfo = localStorage.getItem('userInfo');
      if (userInfo) {
        setIsAuthenticated(true);
        const token = JSON.parse(userInfo).token;
        try {
          const { data } = await axios.get('/users/profile', {
            withCredentials: true,
            headers: {
              'Authorization': `Bearer ${token}`,
              'Content-Type': 'application/json'
            }
          });
          console.log('User data fetched:', data); // Log user data
          setColorMode(data.darkMode ? 'dark' : 'light');
          localStorage.setItem('userDarkMode', data.darkMode);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      } else {
        setIsAuthenticated(false);
        localStorage.removeItem('userDarkMode');
      }
    };

    fetchUserData();
  }, [setIsAuthenticated, setColorMode]);

  // Add this effect to handle color mode changes
  useEffect(() => {
    const handleStorageChange = () => {
      const darkMode = localStorage.getItem('userDarkMode');
      if (darkMode !== null) {
        setColorMode(JSON.parse(darkMode) ? 'dark' : 'light');
      }
    };

    window.addEventListener('storage', handleStorageChange);
    return () => window.removeEventListener('storage', handleStorageChange);
  }, [setColorMode]);

  useEffect(() => {
    const interceptor = axios.interceptors.response.use(
      response => response,
      error => {
        if (error.response && error.response.status === 401) {
          setIsAuthenticated(false);
          localStorage.removeItem('userInfo');
          toast.error('Session expired. Please log in again.');
          window.location.href = "/login";  // Use window.location.href to redirect
        }
        return Promise.reject(error);
      }
    );

    return () => {
      axios.interceptors.response.eject(interceptor);
    };
  }, [setIsAuthenticated]);

  if (!isAuthenticated && location.pathname !== '/login' && location.pathname !== '/register') {
    return <Navigate to="/login" />;
  }

  return (
    <div className="flex">
      {isAuthenticated && location.pathname !== '/login' && location.pathname !== '/register' && <Sidebar setIsAuthenticated={setIsAuthenticated} />}
      <div className="flex-1">
        <Routes>
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/login" element={<Login setIsAuthenticated={setIsAuthenticated} />} />
          <Route path="/register" element={<Register setIsAuthenticated={setIsAuthenticated} />} />
          <Route path="/conversations" element={<Conversations />} />
          <Route path="/contacts" element={<Contacts />} />
          <Route path="/contacts/:id" element={<ContactProfile />} />
          <Route path="/workflows" element={<WorkflowsList />} />
          <Route path="/workflows/edit/:id" element={<WorkflowEditor />} />
          <Route path="/workflows/new" element={<WorkflowEditor />} />
          <Route path="/opportunities" element={<Opportunities />} />
          <Route path="/tags" element={<Tags />} />
          <Route path="/responses" element={<Responses />} />
          <Route path="/uploads" element={<Uploads />} />
          <Route path="/phone-numbers" element={<PhoneNumbers />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/10dlc" element={<DLC />} />
          
          <Route path="/" element={
            isAuthenticated ? <Navigate to="/dashboard" replace /> : <Navigate to="/login" replace />
          } />
          
          <Route path="*" element={<NotFound />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;