import React, { useState, useEffect, useRef, useCallback } from 'react';
import {
  Box,
  VStack,
  Input,
  Button,
  Text,
  Flex,
  Center,
  Icon,
  Spinner,
  IconButton,
  useColorMode,
  Skeleton,
  Textarea,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
} from '@chakra-ui/react';
import { FiSend, FiX, FiUser, FiCheck, FiClock, FiCheckCircle, FiAlertCircle, FiLoader, FiHelpCircle, FiList } from 'react-icons/fi';
import { BiMessageSquareError } from 'react-icons/bi';
import { toast } from 'react-toastify';
import sseManager from '../utils/sseManager';

const MessageView = ({ contact, onClose, isInModal = false, onUpdateContact, quickResponses }) => {
  const [messageText, setMessageText] = useState('');
  const [error, setError] = useState('');
  const [sendingMessage, setSendingMessage] = useState(false);
  const messagesContainerRef = useRef(null);
  const { colorMode } = useColorMode();
  const isDark = colorMode === 'dark';
  const textareaRef = useRef(null);

  // Update the color scheme
  const bgColor = isDark ? 'gray.900' : 'gray.50';
  const headerBgColor = isDark ? 'gray.800' : 'white';
  const headerTextColor = isDark ? 'gray.100' : 'gray.700';
  const headerSubTextColor = isDark ? 'gray.400' : 'gray.500';
  const messageBgColor = isDark ? 'gray.800' : 'white';
  const inputBgColor = isDark ? 'gray.800' : 'white';
  const scrollbarTrackColor = isDark ? '#1A202C' : '#EDF2F7';
  const scrollbarThumbColor = isDark ? '#2D3748' : '#CBD5E0';
  const scrollbarThumbHoverColor = isDark ? '#4A5568' : '#A0AEC0';

  // Add this function to handle textarea height
  const adjustTextareaHeight = useCallback(() => {
    const textarea = textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto'; // Reset height
      textarea.style.height = `${Math.min(textarea.scrollHeight, 120)}px`; // Set new height up to max
    }
  }, []);

  useEffect(() => {
    if (contact) {
      setMessageText('');
      setError('');
      if (textareaRef.current) {
        textareaRef.current.style.height = '40px'; // Reset height when contact changes
      }
      setTimeout(scrollToBottom, 0);
    }
  }, [contact]);

  // Update this useEffect to scroll after messages are updated
  useEffect(() => {
    if (contact?.messages) {
      setTimeout(scrollToBottom, 0);
    }
  }, [contact?.messages]);

  // Add SSE listener for workflow messages
  useEffect(() => {
    if (contact?._id) {
      const handleWorkflowMessage = (data) => {
        if (data.type === 'messageSent' && data.contactId === contact._id) {
          // Update contact's messages through onUpdateContact instead of local state
          const updatedContact = {
            ...contact,
            messages: [...(contact.messages || []), data.message],
            lastMessage: data.message,
            lastMessageAt: data.message.timestamp
          };
          onUpdateContact(updatedContact);
        }
      };

      sseManager.addListener('messageSent', handleWorkflowMessage);
      return () => {
        sseManager.removeListener('messageSent', handleWorkflowMessage);
      };
    }
  }, [contact?._id, onUpdateContact]);

  // Update status updates effect
  useEffect(() => {
    if (contact?._id) {
      const handleMessageStatus = (data) => {
        if (data.type === 'messageStatus' && data.contactId === contact._id) {
          // Update contact's messages through onUpdateContact
          const updatedContact = {
            ...contact,
            messages: contact.messages.map(msg =>
              msg.messageId === data.messageId
                ? { ...msg, status: data.status }
                : msg
            )
          };
          onUpdateContact(updatedContact);
        }
      };

      sseManager.addListener('messageStatus', handleMessageStatus);
      return () => {
        sseManager.removeListener('messageStatus', handleMessageStatus);
      };
    }
  }, [contact?._id, onUpdateContact]);

  const scrollToBottom = () => {
    if (messagesContainerRef.current) {
      const element = messagesContainerRef.current;
      element.scrollTop = element.scrollHeight;
    }
  };

  const handleSendMessage = async () => {
    if (!messageText.trim()) return;
    setSendingMessage(true);

    const tempId = Date.now().toString();
    const newMessage = {
      _id: tempId,
      sender: contact.userNumber,
      receiver: contact.contactNumber,
      text: messageText,
      timestamp: new Date().toISOString(),
      pending: true,
      direction: 'outbound',
      status: 'queued'
    };

    // Clear input and scroll before sending
    setMessageText('');
    
    // Update contact cache with the new message
    const updatedContact = {
      ...contact,
      messages: [...(contact.messages || []), newMessage],
      lastMessage: newMessage,
      lastMessageAt: newMessage.timestamp
    };
    
    // Update UI optimistically
    onUpdateContact(updatedContact);
    scrollToBottom();

    try {
      const response = await fetch('/api/messages/send', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${
            localStorage.getItem('userInfo')
              ? JSON.parse(localStorage.getItem('userInfo')).token
              : ''
          }`,
        },
        body: JSON.stringify({
          sender: contact.userNumber,
          receiver: contact.contactNumber,
          text: messageText,
          messagingProfileId: contact.messagingProfileId,
        }),
      });

      const data = await response.json();

      if (response.ok && data.success) {
        const sentMessage = {
          ...newMessage,
          _id: data.messageId,
          messageId: data.telnyxMessageId,
          pending: false,
        };
        
        // Update contact cache with the confirmed message
        const finalUpdatedContact = {
          ...contact,
          messages: [...(contact.messages || []).filter(m => m._id !== tempId), sentMessage],
          lastMessage: sentMessage,
          lastMessageAt: sentMessage.timestamp
        };
        
        onUpdateContact(finalUpdatedContact);
      } else {
        throw new Error(data.error || 'Failed to send message');
      }
    } catch (error) {
      console.error('Error sending message:', error);
      toast.error('Failed to send message. Please try again.');
    } finally {
      setSendingMessage(false);
    }
  };

  // Add handleQuickResponseSelect function
  const handleQuickResponseSelect = (response) => {
    setMessageText(response.content);
    if (textareaRef.current) {
      textareaRef.current.focus();
      adjustTextareaHeight();
    }
  };

  // Display message when no conversation is selected
  if (!contact) {
    return (
      <Center height="100%">
        <Text fontSize="xl" color="gray.500" textAlign="center">
          Please select a conversation to display messages.
        </Text>
      </Center>
    );
  }

  const displayName = (contact.firstName || contact.lastName
    ? `${contact.firstName || ''} ${contact.lastName || ''}`.trim()
    : contact.contactNumber).length > 25
    ? `${(contact.firstName || contact.lastName
        ? `${contact.firstName || ''} ${contact.lastName || ''}`.trim()
        : contact.contactNumber).slice(0, 25)}...`
    : (contact.firstName || contact.lastName
        ? `${contact.firstName || ''} ${contact.lastName || ''}`.trim()
        : contact.contactNumber);

  const StatusIcon = ({ status, pending }) => {
    if (pending) return <Icon as={FiClock} color="gray.200" ml={1} boxSize={3} />;
    
    switch (status) {
      case 'delivered':
        return <Icon as={FiCheckCircle} color="green.300" ml={1} boxSize={3} title="Delivered" />;
      case 'sent':
        return <Icon as={FiCheck} color="blue.300" ml={1} boxSize={3} title="Sent" />;
      case 'queued':
        return <Icon as={FiClock} color="gray.300" ml={1} boxSize={3} title="Queued" />;
      case 'sending':
        return (
          <Icon 
            as={FiLoader} 
            color="blue.300" 
            ml={1} 
            boxSize={3} 
            className="spinning" 
            title="Sending"
          />
        );
      case 'sending_failed':
        return <Icon as={FiAlertCircle} color="red.300" ml={1} boxSize={3} title="Sending Failed" />;
      case 'delivery_failed':
        return <Icon as={FiAlertCircle} color="red.300" ml={1} boxSize={3} title="Delivery Failed" />;
      case 'delivery_unconfirmed':
        return <Icon as={FiHelpCircle} color="yellow.300" ml={1} boxSize={3} title="Delivery Unconfirmed" />;
      default:
        return null;
    }
  };

  return (
    <Flex
      flexDirection="column"
      height={isInModal ? '70vh' : 'calc(100vh - 64px)'}
      bg={bgColor}
      p={4}
      overflowY="hidden"
    >
      <Box
        mb={4}
        p={4}
        bg={headerBgColor}
        borderRadius="2xl"
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        boxShadow="sm"
        flexShrink={0}
      >
        <Flex alignItems="center" gap={3}>
          <Center
            bg={isDark ? 'gray.700' : 'gray.100'}
            w={10}
            h={10}
            borderRadius="full"
            color={isDark ? 'gray.300' : 'gray.600'}
          >
            <Icon as={FiUser} boxSize={5} />
          </Center>
          <Box>
            <Text 
              fontSize="lg" 
              fontWeight="600" 
              color={headerTextColor} 
              noOfLines={1}
            >
              {displayName}
            </Text>
            <Text 
              fontSize="sm" 
              color={headerSubTextColor}
              noOfLines={1}
            >
              {contact.contactNumber}
            </Text>
          </Box>
        </Flex>
        {isInModal && (
          <IconButton
            icon={<FiX />}
            onClick={onClose}
            aria-label="Close Message View"
            variant="ghost"
            borderRadius="full"
            _hover={{
              bg: isDark ? 'gray.700' : 'gray.100'
            }}
          />
        )}
      </Box>
      <Box
        flex={1}
        bg={messageBgColor}
        borderRadius="2xl"
        boxShadow="sm"
        display="flex"
        flexDirection="column"
        overflow="hidden"
        minHeight="200px"
      >
        <VStack
          flex={1}
          overflowY="auto"
          p={6}
          spacing={4}
          alignItems="stretch"
          ref={messagesContainerRef}
          sx={{
            '&::-webkit-scrollbar': {
              width: '6px',
            },
            '&::-webkit-scrollbar-track': {
              background: scrollbarTrackColor,
            },
            '&::-webkit-scrollbar-thumb': {
              background: scrollbarThumbColor,
              borderRadius: '6px',
            },
            '&::-webkit-scrollbar-thumb:hover': {
              background: scrollbarThumbHoverColor,
            },
          }}
        >
          {error ? (
            <Center flex={1} flexDirection="column">
              <Icon as={BiMessageSquareError} boxSize={12} color="red.400" />
              <Text fontSize="lg" color="red.500" mt={2}>
                {error}
              </Text>
            </Center>
          ) : contact.messages.length > 0 ? (
            contact.messages.map((msg, index) => (
              <Box
                key={msg._id || index}
                alignSelf={
                  msg.sender === contact.userNumber ? 'flex-end' : 'flex-start'
                }
                bg={
                  msg.sender === contact.userNumber ? 'blue.500' : isDark ? 'gray.700' : 'gray.100'
                }
                color={
                  msg.sender === contact.userNumber ? 'white' : isDark ? 'gray.100' : 'gray.800'
                }
                p={3}
                borderRadius="2xl"
                maxWidth="80%"
                boxShadow="sm"
                opacity={msg.pending ? 0.7 : 1}
                transition="all 0.2s"
                _hover={{
                  transform: 'translateY(-1px)',
                  boxShadow: 'md',
                }}
              >
                <Text whiteSpace="pre-wrap">
                  {msg.text.replace(/\\n/g, '\n')}
                </Text>
                <Flex 
                  fontSize="xs" 
                  color={msg.sender === contact.userNumber ? 'gray.200' : 'gray.500'} 
                  mt={1}
                  alignItems="center"
                >
                  <Text>{new Date(msg.timestamp).toLocaleString()}</Text>
                  {msg.sender === contact.userNumber && msg.direction === 'outbound' && (
                    <StatusIcon status={msg.status} pending={msg.pending} />
                  )}
                </Flex>
              </Box>
            ))
          ) : (
            <Center flex={1} flexDirection="column">
              <Icon as={BiMessageSquareError} boxSize={12} color="gray.400" />
              <Text fontSize="lg" color="gray.500" mt={2}>
                No messages to display
              </Text>
            </Center>
          )}
        </VStack>
        <Box 
          p={4}
          bg={headerBgColor}
          borderRadius="2xl"
          boxShadow="sm"
          mt={4}
          flexShrink={0}
        >
          <Flex>
            <Textarea
              ref={textareaRef}
              value={messageText}
              onChange={(e) => {
                setMessageText(e.target.value);
                adjustTextareaHeight();
              }}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  handleSendMessage();
                }
                if (e.key === 'Enter' && e.shiftKey) {
                  adjustTextareaHeight();
                }
              }}
              placeholder="Type your message... (Shift+Enter for new line)"
              mr={2}
              bg={inputBgColor}
              borderRadius="lg"
              borderColor={isDark ? 'gray.600' : 'gray.200'}
              resize="none"
              minH="40px"
              maxH="120px"
              overflowY="auto"
              sx={{
                '&::-webkit-scrollbar': {
                  width: '6px',
                },
                '&::-webkit-scrollbar-track': {
                  background: scrollbarTrackColor,
                },
                '&::-webkit-scrollbar-thumb': {
                  background: scrollbarThumbColor,
                  borderRadius: '6px',
                },
                '&::-webkit-scrollbar-thumb:hover': {
                  background: scrollbarThumbHoverColor,
                },
              }}
              _focus={{
                borderColor: 'blue.400',
                boxShadow: '0 0 0 1px var(--chakra-colors-blue-400)',
              }}
              _hover={{
                borderColor: isDark ? 'gray.500' : 'gray.300',
              }}
              _placeholder={{
                color: isDark ? 'gray.500' : 'gray.400',
              }}
              isDisabled={!contact || sendingMessage}
            />
            <Menu placement="top-end">
              <MenuButton
                as={IconButton}
                icon={<FiList />}
                variant="ghost"
                mr={2}
                isDisabled={!contact || sendingMessage}
                aria-label="Quick Responses"
                title="Quick Responses"
                _hover={{
                  bg: isDark ? 'gray.700' : 'gray.100'
                }}
              />
              <MenuList maxH="300px" overflowY="auto">
                {quickResponses?.length > 0 ? (
                  quickResponses.map((response) => (
                    <MenuItem
                      key={response._id}
                      onClick={() => handleQuickResponseSelect(response)}
                    >
                      <Box>
                        <Text fontWeight="medium">{response.title}</Text>
                        <Text fontSize="sm" color="gray.500" noOfLines={1}>
                          {response.content}
                        </Text>
                      </Box>
                    </MenuItem>
                  ))
                ) : (
                  <MenuItem isDisabled>No quick responses available</MenuItem>
                )}
              </MenuList>
            </Menu>
            <Button
              onClick={handleSendMessage}
              colorScheme="blue"
              rightIcon={<FiSend />}
              borderRadius="full"
              px={6}
              _hover={{ 
                transform: 'translateY(-1px)',
                boxShadow: 'md',
              }}
              transition="all 0.2s"
              isDisabled={!contact || sendingMessage}
              isLoading={sendingMessage}
            >
              Send
            </Button>
          </Flex>
        </Box>
      </Box>
    </Flex>
  );
};

export default MessageView;
