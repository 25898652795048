import React, { useState } from 'react';
import {
  Box,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Button,
  useToast,
  Card,
  CardBody,
  Heading,
  Text,
  Alert,
  AlertIcon,
} from '@chakra-ui/react';
import axios from 'axios';

const BrandRegistration = ({ onRegistrationComplete }) => {
  const [formData, setFormData] = useState({
    companyName: '',
    ein: '',
    street: '',
    city: '',
    state: '',
    zipCode: '',
    country: ''
  });
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const userInfo = localStorage.getItem('userInfo');
      if (!userInfo) {
        throw new Error('No user info found');
      }

      const { token } = JSON.parse(userInfo);
      const { data } = await axios.post('/api/10dlc/brand', 
        {
          companyName: formData.companyName,
          ein: formData.ein,
          address: {
            street: formData.street,
            city: formData.city,
            state: formData.state,
            zipCode: formData.zipCode,
            country: formData.country
          }
        },
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      // Store success message in sessionStorage
      sessionStorage.setItem('registrationSuccess', 'brand');
      
      // Refresh immediately
      window.location.reload();

    } catch (error) {
      toast({
        title: 'Registration Failed',
        description: error.response?.data?.message || 'An error occurred during registration',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  return (
    <Card>
      <CardBody>
        <VStack spacing={6} as="form" onSubmit={handleSubmit}>
          <Box width="100%">
            <Heading size="md" mb={2}>Brand Registration</Heading>
            <Text color="gray.500" fontSize="sm">
              Register your business brand for 10DLC messaging compliance
            </Text>
          </Box>

          <Alert status="info" borderRadius="md">
            <AlertIcon />
            All fields are required for brand verification
          </Alert>

          <FormControl isRequired>
            <FormLabel>Company Legal Name</FormLabel>
            <Input
              placeholder="Enter your company's legal name"
              value={formData.companyName}
              onChange={(e) => setFormData({...formData, companyName: e.target.value})}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>EIN (Tax ID)</FormLabel>
            <Input
              placeholder="XX-XXXXXXX"
              value={formData.ein}
              onChange={(e) => setFormData({...formData, ein: e.target.value})}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Street Address</FormLabel>
            <Input
              placeholder="Enter street address"
              value={formData.street}
              onChange={(e) => setFormData({...formData, street: e.target.value})}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>City</FormLabel>
            <Input
              placeholder="Enter city"
              value={formData.city}
              onChange={(e) => setFormData({...formData, city: e.target.value})}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>State</FormLabel>
            <Input
              placeholder="Enter state"
              value={formData.state}
              onChange={(e) => setFormData({...formData, state: e.target.value})}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>ZIP Code</FormLabel>
            <Input
              placeholder="Enter ZIP code"
              value={formData.zipCode}
              onChange={(e) => setFormData({...formData, zipCode: e.target.value})}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Country</FormLabel>
            <Input
              placeholder="Enter country"
              value={formData.country}
              onChange={(e) => setFormData({...formData, country: e.target.value})}
            />
          </FormControl>

          <Button
            type="submit"
            colorScheme="blue"
            isLoading={loading}
            width="full"
            size="lg"
            mt={4}
          >
            Submit Brand Registration
          </Button>
        </VStack>
      </CardBody>
    </Card>
  );
};

export default BrandRegistration; 