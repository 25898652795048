import React, { useState, useEffect, useCallback, useRef } from 'react';
import axios from 'axios';
import NewOpportunityForm from './NewOpportunityForm';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown, faPlus, faPencilAlt, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Box, Button, Text, IconButton, Flex, Collapse, VStack, Fade, useDisclosure, Heading, Badge, Tooltip, useColorModeValue, Progress, Input, Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, Checkbox, Menu, MenuButton, MenuList, MenuItem, Popover, PopoverTrigger, PopoverContent, PopoverArrow, PopoverCloseButton, PopoverHeader, PopoverBody, PopoverFooter, Grid, GridItem, HStack, Alert, AlertIcon, AlertTitle, Spinner, Skeleton } from '@chakra-ui/react';
import { getToken } from '../utils/auth';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import OpportunityActions from './OpportunityActions';
import MessageView from './MessageView';
import { FaRobot } from 'react-icons/fa';
import { CloseIcon, CheckIcon } from '@chakra-ui/icons';

const OpportunityList = ({ initialLoading }) => {
  const [opportunities, setOpportunities] = useState([]);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [expandedRows, setExpandedRows] = useState({});
  const [error, setError] = useState('');
  const [pipelines, setPipelines] = useState([]);
  const [isSwitchPipelineOpen, setIsSwitchPipelineOpen] = useState(false);
  const [selectedPipeline, setSelectedPipeline] = useState('');
  const [currentContact, setCurrentContact] = useState(null);
  const [selectedContact, setSelectedContact] = useState(null);
  const { isOpen: isMessageViewOpen, onOpen: onMessageViewOpen, onClose: onMessageViewClose } = useDisclosure();
  const navigate = useNavigate();
  const [opportunityContacts, setOpportunityContacts] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const updateQueue = useRef([]);
  const [isSelectionMode, setIsSelectionMode] = useState(false);
  const [selectedContacts, setSelectedContacts] = useState({});
  const [isWorkflowModalOpen, setIsWorkflowModalOpen] = useState(false);
  const [workflows, setWorkflows] = useState([]);
  const [userTags, setUserTags] = useState([]);
  const [isLoadingContacts, setIsLoadingContacts] = useState(true);
  const [isLoadingWorkflows, setIsLoadingWorkflows] = useState(true);
  const [quickResponses, setQuickResponses] = useState([]);

  const fetchOpportunities = async () => {
    try {
      const token = getToken();
      const { data } = await axios.get('/api/opportunities', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setOpportunities(data);
    } catch (error) {
      setError('Failed to fetch opportunities');
      console.error(`Error fetching opportunities: ${error.message}\n${error.stack}`);
    }
  };

  const fetchAllContacts = async () => {
    setIsLoadingContacts(true);
    try {
      const token = getToken();
      const contactPromises = opportunities.map(opportunity =>
        axios.get(`/api/opportunities/${opportunity._id}/contacts`, {
          headers: { Authorization: `Bearer ${token}` }
        })
      );
      const contactResponses = await Promise.all(contactPromises);
      const contactsData = contactResponses.reduce((acc, response, index) => {
        acc[opportunities[index]._id] = response.data.contacts;
        return acc;
      }, {});
      setOpportunityContacts(contactsData);
    } catch (error) {
      console.error(`Error fetching all contacts: ${error.message}\n${error.stack}`);
    } finally {
      setIsLoadingContacts(false);
    }
  };

  const fetchPipelines = async () => {
    try {
      const token = getToken();
      const { data } = await axios.get('/api/opportunities/switch', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setPipelines(data);
    } catch (error) {
      console.error(`Error fetching pipelines: ${error.message}\n${error.stack}`);
    }
  };

  const fetchTags = useCallback(async () => {
    try {
      const token = getToken();
      const response = await axios.get('/api/tags', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setUserTags(response.data.map(tag => tag.name));
    } catch (error) {
      console.error('Error fetching tags:', error);
      toast.error('Failed to fetch tags');
    }
  }, []);

  const fetchQuickResponses = useCallback(async () => {
    try {
      const token = getToken();
      const { data } = await axios.get('/api/responses', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setQuickResponses(data);
    } catch (error) {
      console.error('Error fetching quick responses:', error);
      toast.error('Failed to fetch quick responses');
    }
  }, []);

  useEffect(() => {
    fetchTags();
    fetchOpportunities();
  }, [fetchTags]);

  useEffect(() => {
    if (opportunities.length > 0) {
      fetchAllContacts();
    }
  }, [opportunities]);

  useEffect(() => {
    fetchPipelines();
  }, []);

  useEffect(() => {
    fetchQuickResponses();
  }, [fetchQuickResponses]);

  const handleNewOpportunityCreated = (newOpportunity) => {
    setOpportunities([newOpportunity, ...opportunities]);
    window.location.reload();
  };

  const handleContactRemoved = (contactId) => {
    setOpportunities(prevOpportunities =>
      prevOpportunities.map(opportunity => ({
        ...opportunity,
        contacts: opportunity.contacts.filter(contact => contact._id !== contactId)
      }))
    );
  };

  const handlePipelineUpdated = useCallback((contact, newPipelineId) => {
    console.log('handlePipelineUpdated called', { contact, newPipelineId });
    
    if (!contact || !contact._id) {
      console.error('Invalid contact object:', contact);
      return;
    }

    updateQueue.current.push({ contact, newPipelineId });

    if (isUpdating) {
      console.log('Update already in progress, queued for later');
      return;
    }

    const processQueue = async () => {
      setIsUpdating(true);

      while (updateQueue.current.length > 0) {
        const { contact, newPipelineId } = updateQueue.current.shift();
        
        try {
          const token = getToken();
          await axios.put(`/api/contacts/${contact._id}/opportunity`, { opportunityId: newPipelineId }, {
            headers: { Authorization: `Bearer ${token}` }
          });

          console.log('API call successful, updating state');
          setOpportunities(prevOpportunities => {
            return prevOpportunities.map(opportunity => {
              if (opportunity.contacts && opportunity.contacts.some(c => c._id === contact._id)) {
                return {
                  ...opportunity,
                  contacts: opportunity.contacts.filter(c => c._id !== contact._id)
                };
              }
              if (opportunity._id === newPipelineId) {
                return {
                  ...opportunity,
                  contacts: [...(opportunity.contacts || []), contact]
                };
              }
              return opportunity;
            });
          });
        } catch (error) {
          console.error('Error updating contact opportunity:', error);
        }
      }

      setIsUpdating(false);
    };

    processQueue();
  }, [isUpdating]);

  const toggleRow = async (id) => {
    setExpandedRows(prevState => ({
      ...prevState,
      [id]: !prevState[id]
    }));
  };

  const handleRemoveFromPipeline = async (contact) => {
    try {
      const token = getToken();
      await axios.put(`/api/contacts/${contact._id}/opportunity`, { opportunityId: null }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      toast.success('Contact removed from pipeline');
      handleContactRemoved(contact._id);
    } catch (error) {
      toast.error('Failed to remove contact from pipeline');
      console.error(`Error removing contact from pipeline: ${error.message}\n${error.stack}`);
    }
  };

  const handlePipelineChange = (pipelineId) => {
    setSelectedPipeline(pipelineId);
  };

  const confirmSwitchPipeline = async () => {
    if (!selectedPipeline) {
      toast.error('Please select a pipeline to switch to');
      return;
    }

    try {
      const token = getToken();
      await axios.put(`/api/contacts/${currentContact._id}/opportunity`, { opportunityId: selectedPipeline }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      toast.success('Contact moved to new pipeline');
      handleContactRemoved(currentContact._id);
      setIsSwitchPipelineOpen(false);
      handlePipelineUpdated(currentContact, selectedPipeline);
    } catch (error) {
      toast.error('Failed to move contact to new pipeline');
      console.error(`Error moving contact to new pipeline: ${error.message}\n${error.stack}`);
    }
  };

  const handleOpenMessageView = (contact) => {
    setSelectedContact(contact);
    onMessageViewOpen();
  };

  const handleCloseMessageView = () => {
    setSelectedContact(null);
    onMessageViewClose();
  };

  const handleEditOpportunityName = async (opportunityId, newName) => {
    try {
      const token = getToken();
      await axios.put(`/api/opportunities/${opportunityId}`, { name: newName }, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setOpportunities(opportunities.map(opp => 
        opp._id === opportunityId ? { ...opp, name: newName } : opp
      ));
      toast.success('Pipeline name updated successfully');
    } catch (error) {
      console.error(`Error updating pipeline name: ${error.message}\n${error.stack}`);
      toast.error('Failed to update pipeline name');
    }
  };

  const handleDeleteOpportunity = async (opportunityId) => {
    if (window.confirm('Are you sure you want to delete this pipeline?')) {
      try {
        const token = getToken();
        await axios.delete(`/api/opportunities/${opportunityId}`, {
          headers: { Authorization: `Bearer ${token}` }
        });
        setOpportunities(opportunities.filter(opp => opp._id !== opportunityId));
        toast.success('Pipeline deleted successfully');
      } catch (error) {
        console.error(`Error deleting pipeline: ${error.message}\n${error.stack}`);
        toast.error('Failed to delete pipeline');
      }
    }
  };

  const toggleSelectionMode = () => {
    setIsSelectionMode(!isSelectionMode);
    setSelectedContacts({});
  };

  const handleContactSelection = (contactId) => {
    setSelectedContacts(prev => ({
      ...prev,
      [contactId]: !prev[contactId]
    }));
  };

  const handleBulkAction = async (action) => {
    const selectedContactIds = Object.keys(selectedContacts).filter(id => selectedContacts[id]);
    
    if (selectedContactIds.length === 0) {
      toast.warning('Please select contacts first');
      return;
    }

    switch (action) {
      case 'addToWorkflow':
        try {
          const token = getToken();
          const checkPromises = selectedContactIds.map(contactId =>
            axios.get(`/api/individual-drips/contact/${contactId}`, {
              headers: { Authorization: `Bearer ${token}` }
            })
          );

          const results = await Promise.all(checkPromises);
          const contactsInWorkflow = results
            .filter(result => result.data.length > 0)
            .map(result => result.data[0].contact);

          if (contactsInWorkflow.length > 0) {
            const newSelectedContacts = { ...selectedContacts };
            contactsInWorkflow.forEach(contact => {
              delete newSelectedContacts[contact];
            });
            setSelectedContacts(newSelectedContacts);

            toast.warning(
              `${contactsInWorkflow.length} contact(s) were unselected because they are already in workflows`
            );

            const remainingContacts = Object.keys(newSelectedContacts).filter(id => newSelectedContacts[id]);
            if (remainingContacts.length === 0) {
              return;
            }
          }

          setIsWorkflowModalOpen(true);
        } catch (error) {
          console.error('Error checking contacts workflow status:', error);
          toast.error('Failed to check contacts workflow status');
        }
        break;
    }
  };

  const handleWorkflowAssignment = async (workflowId) => {
    if (!workflowId) {
      toast.error('Please select a workflow');
      return;
    }

    const selectedContactIds = Object.keys(selectedContacts).filter(id => selectedContacts[id]);
    
    try {
      const token = getToken();
      
      const checkPromises = selectedContactIds.map(contactId =>
        axios.get(`/api/individual-drips/contact/${contactId}`, {
          headers: { Authorization: `Bearer ${token}` }
        })
      );

      const results = await Promise.all(checkPromises);
      const eligibleContacts = selectedContactIds.filter((contactId, index) => 
        results[index].data.length === 0
      );

      if (eligibleContacts.length === 0) {
        toast.error('No eligible contacts to add to workflow');
        setIsWorkflowModalOpen(false);
        setSelectedContacts({});
        setIsSelectionMode(false);
        return;
      }

      const promises = eligibleContacts.map(contactId =>
        axios.post('/api/individual-drips', {
          workflowId,
          contactId
        }, {
          headers: { Authorization: `Bearer ${token}` }
        })
      );

      await Promise.all(promises);
      toast.success(`Added ${eligibleContacts.length} contacts to workflow`);
      setIsWorkflowModalOpen(false);
      setSelectedContacts({});
      setIsSelectionMode(false);
    } catch (error) {
      console.error('Error adding contacts to workflow:', error);
      toast.error(error.response?.data?.message || 'Failed to add contacts to workflow');
    }
  };

  const handleUpdateContact = useCallback((updatedContact) => {
    setOpportunities(prevOpportunities =>
      prevOpportunities.map(opportunity => ({
        ...opportunity,
        contacts: opportunity.contacts?.map(contact =>
          contact._id === updatedContact._id ? updatedContact : contact
        ) || []
      }))
    );
  }, []);

  useEffect(() => {
    const fetchWorkflows = async () => {
      setIsLoadingWorkflows(true);
      try {
        const token = getToken();
        const response = await axios.get('/api/workflows', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setWorkflows(response.data);
      } catch (error) {
        console.error('Error fetching workflows:', error);
        toast.error('Failed to fetch workflows');
      } finally {
        setIsLoadingWorkflows(false);
      }
    };

    fetchWorkflows();
  }, []);

  return (
    <Box 
      p={8} 
      boxShadow="lg" 
      borderRadius="2xl" 
      bg={useColorModeValue('white', 'gray.800')}
      maxW="100%"
      overflow="hidden"
    >
      <Box mb={8}>
        <Flex 
          justifyContent="space-between" 
          alignItems="center" 
          mb={6}
          flexWrap="wrap"
          gap={4}
        >
          <Heading size="lg" color={useColorModeValue('gray.700', 'white')}>
            Sales Pipelines
          </Heading>
          <Flex gap={3}>
            <Button
              onClick={toggleSelectionMode}
              colorScheme={isSelectionMode ? "red" : "blue"}
              size="md"
              variant={isSelectionMode ? "solid" : "outline"}
              leftIcon={isSelectionMode ? <CloseIcon /> : <CheckIcon />}
              transition="all 0.2s"
            >
              {isSelectionMode ? "Cancel Selection" : "Select Contacts"}
            </Button>
            {isSelectionMode && (
              <Menu>
                <MenuButton
                  as={Button}
                  rightIcon={<FontAwesomeIcon icon={faChevronDown} />}
                  colorScheme="blue"
                  variant="outline"
                >
                  Actions
                </MenuButton>
                <MenuList>
                  <MenuItem 
                    onClick={() => handleBulkAction('addToWorkflow')} 
                    icon={<FaRobot />}
                    _hover={{ bg: 'blue.50' }}
                  >
                    Add to Workflow
                  </MenuItem>
                </MenuList>
              </Menu>
            )}
            <Button
              leftIcon={<FontAwesomeIcon icon={faPlus} />}
              onClick={() => setIsFormOpen(true)}
              colorScheme="blue"
              size="md"
              variant="solid"
              shadow="md"
              _hover={{
                transform: 'translateY(-1px)',
                shadow: 'lg',
              }}
            >
              New Pipeline
            </Button>
          </Flex>
        </Flex>
      </Box>

      {isFormOpen && (
        <NewOpportunityForm
          onClose={() => setIsFormOpen(false)}
          onOpportunityCreated={handleNewOpportunityCreated}
        />
      )}

      {error ? (
        <Alert status="error" borderRadius="lg">
          <AlertIcon />
          <AlertTitle>{error}</AlertTitle>
        </Alert>
      ) : (
        <VStack spacing={6} align="stretch">
          {initialLoading ? (
            <VStack spacing={4} align="stretch">
              {[1, 2, 3].map((_, index) => (
                <Box
                  key={index}
                  borderWidth="1px"
                  borderRadius="xl"
                  overflow="hidden"
                  boxShadow="sm"
                >
                  <Flex p={4} alignItems="center" justifyContent="space-between">
                    <HStack spacing={4} flex="1">
                      <Skeleton height="24px" width="200px" />
                      <Skeleton height="20px" width="40px" borderRadius="full" />
                    </HStack>
                    <HStack spacing={2}>
                      <Skeleton height="32px" width="32px" borderRadius="md" />
                      <Skeleton height="32px" width="32px" borderRadius="md" />
                    </HStack>
                  </Flex>
                </Box>
              ))}
            </VStack>
          ) : (
            opportunities.map(opportunity => (
              <OpportunityItem
                key={opportunity._id}
                opportunity={opportunity}
                contacts={opportunityContacts[opportunity._id] || []}
                isLoadingContacts={isLoadingContacts}
                isLoadingWorkflows={isLoadingWorkflows}
                expandedRows={expandedRows}
                toggleRow={toggleRow}
                handleRemoveFromPipeline={handleRemoveFromPipeline}
                handlePipelineUpdated={handlePipelineUpdated}
                pipelines={pipelines}
                navigate={navigate}
                onEdit={handleEditOpportunityName}
                onDelete={handleDeleteOpportunity}
                onOpenMessageView={handleOpenMessageView}
                isSelectionMode={isSelectionMode}
                selectedContacts={selectedContacts}
                onContactSelection={handleContactSelection}
              />
            ))
          )}
        </VStack>
      )}

      <Modal isOpen={isMessageViewOpen} onClose={handleCloseMessageView} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            {selectedContact && (
              <MessageView
                contact={selectedContact}
                onUpdateContact={handleUpdateContact}
                isInModal={true}
                onClose={handleCloseMessageView}
                quickResponses={quickResponses}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isWorkflowModalOpen} onClose={() => setIsWorkflowModalOpen(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Select Workflow</ModalHeader>
          <ModalCloseButton />
          <ModalBody pb={6}>
            <VStack spacing={4} align="stretch">
              {workflows
                .filter(workflow => workflow.status === 'published')
                .map(workflow => (
                  <Button
                    key={workflow._id}
                    onClick={() => handleWorkflowAssignment(workflow._id)}
                    variant="outline"
                    justifyContent="flex-start"
                    size="md"
                    py={3}
                  >
                    {workflow.name}
                  </Button>
                ))}
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

const OpportunityItem = ({ opportunity, contacts, isLoadingContacts, isLoadingWorkflows, expandedRows, toggleRow, handleRemoveFromPipeline, handlePipelineUpdated, pipelines, navigate, onEdit, onDelete, onOpenMessageView, isSelectionMode, selectedContacts, onContactSelection }) => {
  const { isOpen, onToggle } = useDisclosure();
  const [isEditing, setIsEditing] = useState(false);
  const [editName, setEditName] = useState(opportunity.name);
  const bgColor = useColorModeValue('gray.50', 'gray.700');
  const hoverBgColor = useColorModeValue('gray.100', 'gray.600');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const [workflowInfoMap, setWorkflowInfoMap] = useState({});
  const [isLoadingWorkflowInfo, setIsLoadingWorkflowInfo] = useState(true);

  const totalContacts = contacts.length;

  const handleEditSubmit = () => {
    onEdit(opportunity._id, editName);
    setIsEditing(false);
  };

  const allContactsSelected = contacts.length > 0 && contacts.every(contact => selectedContacts[contact._id]);

  const handleSelectAllContacts = (e) => {
    if (contacts.length === 0) return;
    const isChecked = e.target.checked;
    contacts.forEach(contact => {
      onContactSelection(contact._id, isChecked);
    });
  };

  const renderHeaderActions = () => (
    <Flex align="center">
      <Tooltip label="Edit Pipeline Name">
        <IconButton
          icon={<FontAwesomeIcon icon={faPencilAlt} />}
          size="sm"
          mr={2}
          onClick={(e) => {
            e.stopPropagation();
            setIsEditing(!isEditing);
          }}
        />
      </Tooltip>
      <Tooltip label="Delete Pipeline">
        <IconButton
          icon={<FontAwesomeIcon icon={faTrashAlt} />}
          size="sm"
          colorScheme="red"
          mr={4}
          onClick={(e) => {
            e.stopPropagation();
            onDelete(opportunity._id);
          }}
        />
      </Tooltip>
      <Box
        as={FontAwesomeIcon}
        icon={faChevronDown}
        transform={isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
        transition="transform 0.3s"
        cursor="pointer"
        onClick={(e) => {
          e.stopPropagation();
          toggleRow(opportunity._id);
          onToggle();
        }}
      />
    </Flex>
  );

  useEffect(() => {
    const fetchWorkflowInfo = async () => {
      if (isLoadingContacts || isLoadingWorkflows) return; // Don't fetch if still loading initial data
      
      setIsLoadingWorkflowInfo(true);
      try {
        const token = getToken();
        const promises = contacts.map(contact =>
          axios.get(`/api/individual-drips/contact/${contact._id}`, {
            headers: { Authorization: `Bearer ${token}` }
          })
        );

        const responses = await Promise.all(promises);
        const workflowMap = {};
        contacts.forEach((contact, index) => {
          const activeWorkflow = responses[index].data[0]?.workflow;
          workflowMap[contact._id] = activeWorkflow ? {
            id: activeWorkflow._id,
            name: activeWorkflow.name,
            runningState: activeWorkflow.runningState
          } : null;
        });

        setWorkflowInfoMap(workflowMap);
      } catch (error) {
        console.error('Error fetching workflow info:', error);
        toast.error('Failed to fetch workflow information');
      } finally {
        setIsLoadingWorkflowInfo(false);
      }
    };

    fetchWorkflowInfo();
  }, [contacts, isLoadingContacts, isLoadingWorkflows]);

  return (
    <Box
      borderWidth="1px"
      borderRadius="xl"
      borderColor={borderColor}
      overflow="hidden"
      boxShadow="sm"
      _hover={{ boxShadow: 'md' }}
      transition="all 0.2s"
      bg={useColorModeValue('white', 'gray.700')}
    >
      <Flex
        justify="space-between"
        align="center"
        p={5}
        bg={bgColor}
        _hover={{ bg: hoverBgColor }}
        cursor="pointer"
        onClick={() => {
          toggleRow(opportunity._id);
          onToggle();
        }}
      >
        <Flex align="center" flex={1} gap={4}>
          <Box
            as={FontAwesomeIcon}
            icon={faChevronDown}
            transform={isOpen ? 'rotate(180deg)' : 'rotate(0deg)'}
            transition="transform 0.3s"
            color={useColorModeValue('gray.500', 'gray.400')}
          />
          {isEditing ? (
            <Input
              value={editName}
              onChange={(e) => setEditName(e.target.value)}
              onBlur={handleEditSubmit}
              onKeyPress={(e) => e.key === 'Enter' && handleEditSubmit()}
              size="md"
              width="auto"
              mr={2}
            />
          ) : (
            <Text fontSize="lg" fontWeight="semibold">{opportunity.name}</Text>
          )}
          {isLoadingContacts ? (
            <Skeleton height="20px" width="60px" borderRadius="full" />
          ) : (
            <Badge borderRadius="full" px={2}>
              {totalContacts} contacts
            </Badge>
          )}
        </Flex>
        <HStack spacing={2}>
          {renderHeaderActions()}
        </HStack>
      </Flex>

      <Collapse in={expandedRows[opportunity._id]} animateOpacity>
        <Box p={5}>
          <Grid
            templateColumns={{
              base: "1fr",
              md: isSelectionMode 
                ? "40px minmax(200px, 2fr) minmax(150px, 2fr) minmax(200px, 2fr) 120px 200px 150px"
                : "minmax(200px, 2fr) minmax(150px, 2fr) minmax(200px, 2fr) 120px 200px 150px"
            }}
            gap={6}
            mb={4}
            px={4}
            py={3}
            bg={useColorModeValue('gray.50', 'gray.700')}
            borderRadius="lg"
            alignItems="center"
          >
            {isSelectionMode && <GridItem>Select</GridItem>}
            <GridItem>Name</GridItem>
            <GridItem>Phone</GridItem>
            <GridItem>Email</GridItem>
            <GridItem textAlign="center">State</GridItem>
            <GridItem textAlign="center">Workflow</GridItem>
            <GridItem textAlign="center">Actions</GridItem>
          </Grid>

          {isLoadingContacts ? (
            <VStack spacing={4} p={4}>
              {[1, 2, 3].map((_, index) => (
                <Grid
                  key={index}
                  templateColumns={{
                    base: "1fr",
                    md: isSelectionMode 
                      ? "40px minmax(200px, 2fr) minmax(150px, 2fr) minmax(200px, 2fr) 120px 200px 150px"
                      : "minmax(200px, 2fr) minmax(150px, 2fr) minmax(200px, 2fr) 120px 200px 150px"
                  }}
                  gap={6}
                  w="100%"
                >
                  {isSelectionMode && (
                    <GridItem>
                      <Skeleton height="20px" width="20px" />
                    </GridItem>
                  )}
                  <GridItem><Skeleton height="20px" /></GridItem>
                  <GridItem><Skeleton height="20px" /></GridItem>
                  <GridItem><Skeleton height="20px" /></GridItem>
                  <GridItem><Skeleton height="20px" /></GridItem>
                  <GridItem><Skeleton height="20px" /></GridItem>
                  <GridItem><Skeleton height="20px" /></GridItem>
                </Grid>
              ))}
            </VStack>
          ) : (
            <VStack spacing={2} align="stretch">
              {contacts.length > 0 ? (
                contacts.map(contact => (
                  <ContactItem
                    key={contact._id}
                    contact={contact}
                    opportunity={opportunity}
                    handleRemoveFromPipeline={handleRemoveFromPipeline}
                    pipelines={pipelines}
                    handlePipelineUpdated={handlePipelineUpdated}
                    navigate={navigate}
                    onOpenMessageView={onOpenMessageView}
                    hoverBgColor={hoverBgColor}
                    isSelectionMode={isSelectionMode}
                    isSelected={selectedContacts[contact._id]}
                    onContactSelection={onContactSelection}
                    workflowInfo={workflowInfoMap[contact._id]}
                    isLoadingWorkflow={isLoadingWorkflows || isLoadingWorkflowInfo}
                  />
                ))
              ) : (
                <Box 
                  textAlign="center" 
                  py={8}
                  color="gray.500"
                >
                  <Text>No contacts assigned to this pipeline.</Text>
                </Box>
              )}
            </VStack>
          )}
        </Box>
      </Collapse>
    </Box>
  );
};

const ContactItem = ({ 
  contact, 
  opportunity, 
  handleRemoveFromPipeline, 
  pipelines, 
  handlePipelineUpdated, 
  navigate, 
  onOpenMessageView, 
  hoverBgColor,
  isSelectionMode, 
  isSelected, 
  onContactSelection,
  workflowInfo,
  isLoadingWorkflow
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const bgColor = useColorModeValue('white', 'gray.800');

  const getWorkflowBadgeColor = (runningState) => {
    switch (runningState) {
      case 'running':
        return 'green';
      case 'paused':
        return 'yellow';
      default:
        return 'purple';
    }
  };

  return (
    <Grid
      templateColumns={{
        base: "1fr",
        md: isSelectionMode 
          ? "40px minmax(200px, 2fr) minmax(150px, 2fr) minmax(200px, 2fr) 120px 200px 150px"
          : "minmax(200px, 2fr) minmax(150px, 2fr) minmax(200px, 2fr) 120px 200px 150px"
      }}
      gap={6}
      py={3}
      px={4}
      alignItems="center"
      bg={bgColor}
      _hover={{
        bg: !isMenuOpen ? hoverBgColor : bgColor,
        transform: !isMenuOpen ? 'translateX(2px)' : 'none',
      }}
      transition="all 0.2s"
      borderRadius="lg"
      boxShadow="sm"
      position="relative"
    >
      {isSelectionMode && (
        <GridItem>
          <Checkbox
            isChecked={isSelected}
            onChange={() => onContactSelection(contact._id)}
          />
        </GridItem>
      )}
      <GridItem>{contact.firstName} {contact.lastName}</GridItem>
      <GridItem>{contact.phone}</GridItem>
      <GridItem>{contact.email}</GridItem>
      <GridItem textAlign="center">
        <Badge colorScheme={contact.state === 'Completed' ? 'green' : 'yellow'}>
          {contact.state}
        </Badge>
      </GridItem>
      <GridItem textAlign="center">
        {isLoadingWorkflow ? (
          <Skeleton height="24px" width="120px" />
        ) : workflowInfo ? (
          <Tooltip label={`Status: ${workflowInfo.runningState}`}>
            <Badge 
              colorScheme={getWorkflowBadgeColor(workflowInfo.runningState)}
              cursor="pointer"
              onClick={() => navigate(`/workflows/${workflowInfo.id}`)}
            >
              {workflowInfo.name}
            </Badge>
          </Tooltip>
        ) : (
          <Text color="gray.500" fontSize="sm">Not in workflow</Text>
        )}
      </GridItem>
      <GridItem 
        textAlign="center"
        position="relative"
        zIndex={isMenuOpen ? 1100 : 1}
      >
        <Box position="relative">
          <OpportunityActions
            contact={contact}
            onContactRemoved={handleRemoveFromPipeline}
            pipelines={pipelines}
            currentPipelineId={opportunity._id}
            onPipelineUpdated={handlePipelineUpdated}
            navigate={navigate}
            onOpenMessageView={onOpenMessageView}
            onMenuOpen={() => setIsMenuOpen(true)}
            onMenuClose={() => setIsMenuOpen(false)}
          />
        </Box>
      </GridItem>
    </Grid>
  );
};

export default OpportunityList;
