import axios from 'axios';
import { getAuthHeaders } from './authHeaders';

// Add a request interceptor
axios.interceptors.request.use(
  config => {
    console.log('Making request to:', config.url, 'with method:', config.method);
    return config;
  },
  error => {
    console.error('Request error:', error);
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios.interceptors.response.use(
  response => {
    console.log('Received response from:', response.config.url, 'status:', response.status);
    return response;
  },
  error => {
    console.error('Response error:', error);
    return Promise.reject(error);
  }
);

export const get = async (url, config = {}) => {
  console.log('Making GET request to:', url); // Debug log
  try {
    const response = await axios.get(url, {
      ...getAuthHeaders(),
      ...config,
    });
    console.log(`GET request to ${url} successful:`, response.data);
    return response.data;
  } catch (error) {
    console.error(`Error in GET request to ${url}:`, error);
    throw error;
  }
};

export const post = async (url, data, config = {}) => {
  console.log('Making POST request to:', url, 'with data:', data); // Debug log
  try {
    const response = await axios.post(url, data, {
      ...getAuthHeaders(),
      ...config,
    });
    console.log(`POST request to ${url} successful:`, response.data);
    return response.data;
  } catch (error) {
    console.error(`Error in POST request to ${url}:`, error);
    throw error;
  }
};

export const put = async (url, data, config = {}) => {
  try {
    const response = await axios.put(url, data, {
      ...getAuthHeaders(),
      ...config,
    });
    console.log(`PUT request to ${url} successful:`, response.data);
    return response.data;
  } catch (error) {
    console.error(`Error in PUT request to ${url}: ${error.message}\n${error.stack}`);
    throw error;
  }
};

export const del = async (url, config = {}) => {
  try {
    const response = await axios.delete(url, {
      ...getAuthHeaders(),
      ...config,
    });
    console.log(`DELETE request to ${url} successful:`, response.data);
    return response.data;
  } catch (error) {
    console.error(`Error in DELETE request to ${url}: ${error.message}\n${error.stack}`);
    throw error;
  }
};