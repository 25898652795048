import React, { useState, useEffect } from 'react';
import ContactList from '../components/ContactList';
import * as api from '../utils/api';
import { Box, Skeleton, Stack, VStack, Text, Button, Container, useColorModeValue } from '@chakra-ui/react';
import { AddIcon } from '@chakra-ui/icons';
import Header from '../components/Header';

const Contacts = () => {
  const [loading, setLoading] = useState(true);
  const [contacts, setContacts] = useState([]);
  const [error, setError] = useState(null);

  const bgColor = useColorModeValue('gray.50', 'gray.800');
  const cardBgColor = useColorModeValue('white', 'gray.700');

  useEffect(() => {
    const fetchContacts = async () => {
      try {
        const data = await api.get('/api/contacts?page=1&limit=10');
        setContacts(data.contacts);
      } catch (err) {
        console.error('Error fetching contacts:', err);
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchContacts();
  }, []);

  if (loading) {
    return (
      <>
        <Header />
        <Box bg={bgColor} minHeight="100vh" py={10}>
          <Container maxW="container.xl">
            <Stack spacing={4}>
              {[...Array(5)].map((_, i) => (
                <Skeleton key={i} height="80px" />
              ))}
            </Stack>
          </Container>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header />
      <Box bg={bgColor} minHeight="100vh" py={10}>
        <Container maxW="container.xl">
          {contacts.length === 0 ? (
            <Box bg={cardBgColor} borderRadius="xl" p={8} boxShadow="lg">
              <VStack spacing={4} align="center">
                <Text fontSize="lg" fontWeight="medium" color="gray.500">
                  No contacts yet
                </Text>
                <Text color="gray.500" textAlign="center">
                  Visit the upload section to import your contacts
                </Text>
              </VStack>
            </Box>
          ) : (
            <ContactList />
          )}
        </Container>
      </Box>
    </>
  );
};

export default Contacts;
