import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Header from '../components/Header';
import { Box, Flex, Text, Button, SimpleGrid, useDisclosure, VStack, Container, useColorModeValue, Skeleton } from '@chakra-ui/react';
import { AddIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import ResponseForm from '../components/ResponseForm'; // We'll create this next

const EmptyState = ({ onOpen }) => (
  <Box bg={useColorModeValue('white', 'gray.700')} borderRadius="xl" p={8} boxShadow="lg">
    <VStack spacing={4} align="center">
      <Text fontSize="lg" fontWeight="medium" color="gray.500">
        No quick responses yet
      </Text>
      <Text color="gray.500" textAlign="center">
        Create your first quick response to speed up your messaging
      </Text>
      <Button
        onClick={onOpen}
        leftIcon={<AddIcon />}
        colorScheme="blue"
        size="lg"
        borderRadius="full"
        boxShadow="md"
      >
        New Response
      </Button>
    </VStack>
  </Box>
);

const ResponseCard = ({ response, onDelete, onEdit }) => {
  const textColor = useColorModeValue('gray.600', 'gray.200');
  
  return (
    <Box 
      p={5} 
      bg={useColorModeValue('white', 'gray.700')} 
      borderRadius="lg" 
      boxShadow="md"
      _hover={{ transform: 'translateY(-2px)', boxShadow: 'lg' }}
      transition="all 0.2s"
    >
      <Text fontWeight="bold" mb={2} color={useColorModeValue('gray.800', 'white')}>
        {response.title}
      </Text>
      <Text 
        noOfLines={3} 
        mb={4} 
        color={textColor}
      >
        {response.content}
      </Text>
      <Flex justify="flex-end" gap={2}>
        <Button size="sm" leftIcon={<EditIcon />} onClick={() => onEdit(response)}>
          Edit
        </Button>
        <Button size="sm" leftIcon={<DeleteIcon />} colorScheme="red" onClick={() => onDelete(response._id)}>
          Delete
        </Button>
      </Flex>
    </Box>
  );
};

const Responses = () => {
  const [responses, setResponses] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [editingResponse, setEditingResponse] = useState(null);

  const bgColor = useColorModeValue('gray.50', 'gray.800');

  useEffect(() => {
    fetchResponses();
  }, []);

  const fetchResponses = async () => {
    setLoading(true);
    try {
      const token = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).token : '';
      const { data } = await axios.get('/api/responses', {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setResponses(data);
    } catch (error) {
      console.error('Error fetching responses:', error);
      setError('Failed to fetch responses');
    } finally {
      setLoading(false);
    }
  };

  const handleNewResponse = (newResponse) => {
    setResponses([...responses, newResponse]);
    onClose();
    setEditingResponse(null);
  };

  const handleEdit = (response) => {
    setEditingResponse(response);
    onOpen();
  };

  const handleDelete = async (responseId) => {
    try {
      const token = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')).token : '';
      await axios.delete(`/api/responses/${responseId}`, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      setResponses(responses.filter(response => response._id !== responseId));
    } catch (error) {
      console.error('Error deleting response:', error);
    }
  };

  if (loading) {
    return (
      <>
        <Header />
        <Box bg={bgColor} minHeight="100vh" py={10}>
          <Container maxW="container.xl">
            <SimpleGrid columns={[1, 2, 3]} spacing={6}>
              {[1, 2, 3].map((i) => (
                <Skeleton key={i} height="200px" borderRadius="lg" />
              ))}
            </SimpleGrid>
          </Container>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header />
      <Box bg={bgColor} minHeight="100vh" py={10}>
        <Container maxW="container.xl">
          <VStack spacing={8} align="stretch">
            {responses.length > 0 && (
              <Flex justify="flex-end">
                <Button
                  onClick={() => {
                    setEditingResponse(null);
                    onOpen();
                  }}
                  leftIcon={<AddIcon />}
                  colorScheme="blue"
                  size="lg"
                  borderRadius="full"
                  boxShadow="md"
                >
                  New Response
                </Button>
              </Flex>
            )}
            
            {responses.length === 0 ? (
              <EmptyState onOpen={onOpen} />
            ) : (
              <SimpleGrid columns={[1, 2, 3]} spacing={6}>
                {responses.map(response => (
                  <ResponseCard
                    key={response._id}
                    response={response}
                    onDelete={handleDelete}
                    onEdit={handleEdit}
                  />
                ))}
              </SimpleGrid>
            )}
          </VStack>
        </Container>
        
        <ResponseForm 
          isOpen={isOpen} 
          onClose={() => {
            onClose();
            setEditingResponse(null);
          }}
          onSubmit={handleNewResponse}
          editingResponse={editingResponse}
        />
      </Box>
    </>
  );
};

export default Responses; 