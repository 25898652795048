import React, { useState } from 'react';
import {
  Box,
  VStack,
  FormControl,
  FormLabel,
  Input,
  Select,
  Button,
  useToast,
  Card,
  CardBody,
  Heading,
  Text,
  Alert,
  AlertIcon,
  Textarea,
} from '@chakra-ui/react';
import axios from 'axios';

const CampaignRegistration = ({ registration, onRegistrationComplete }) => {
  const [formData, setFormData] = useState({
    campaignName: '',
    useCase: '',
    messageFlow: '',
    description: ''
  });
  const [loading, setLoading] = useState(false);
  const toast = useToast();

  const useCaseOptions = [
    { value: 'marketing', label: 'Marketing Messages' },
    { value: 'authentication', label: 'Authentication / 2FA' },
    { value: 'customer_service', label: 'Customer Service' },
    { value: 'polling', label: 'Polling and Voting' },
    { value: 'info_notifications', label: 'Informational Notifications' }
  ];

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const userInfo = localStorage.getItem('userInfo');
      if (!userInfo) {
        throw new Error('No user info found');
      }

      const { token } = JSON.parse(userInfo);
      const { data } = await axios.post('/api/10dlc/campaign',
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`
          }
        }
      );

      // Store success message in sessionStorage
      sessionStorage.setItem('registrationSuccess', 'campaign');
      
      // Refresh immediately
      window.location.reload();

    } catch (error) {
      toast({
        title: 'Registration Failed',
        description: error.response?.data?.message || 'An error occurred during registration',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      setLoading(false);
    }
  };

  return (
    <Card>
      <CardBody>
        <VStack spacing={6} as="form" onSubmit={handleSubmit}>
          <Box width="100%">
            <Heading size="md" mb={2}>Campaign Registration</Heading>
            <Text color="gray.500" fontSize="sm">
              Register a new messaging campaign for your approved brand
            </Text>
          </Box>

          <Alert status="info" borderRadius="md">
            <AlertIcon />
            Campaign details must accurately reflect your messaging intent
          </Alert>

          <FormControl isRequired>
            <FormLabel>Campaign Name</FormLabel>
            <Input
              placeholder="Enter campaign name"
              value={formData.campaignName}
              onChange={(e) => setFormData({...formData, campaignName: e.target.value})}
            />
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Use Case</FormLabel>
            <Select
              placeholder="Select use case"
              value={formData.useCase}
              onChange={(e) => setFormData({...formData, useCase: e.target.value})}
            >
              {useCaseOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </Select>
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Message Flow</FormLabel>
            <Select
              placeholder="Select message flow"
              value={formData.messageFlow}
              onChange={(e) => setFormData({...formData, messageFlow: e.target.value})}
            >
              <option value="2way">Two-Way (Interactive)</option>
              <option value="1way">One-Way (Notifications Only)</option>
            </Select>
          </FormControl>

          <FormControl isRequired>
            <FormLabel>Campaign Description</FormLabel>
            <Textarea
              placeholder="Describe your campaign's purpose and message content"
              value={formData.description}
              onChange={(e) => setFormData({...formData, description: e.target.value})}
              rows={4}
            />
          </FormControl>

          <Button
            type="submit"
            colorScheme="blue"
            isLoading={loading}
            width="full"
            size="lg"
            mt={4}
          >
            Submit Campaign Registration
          </Button>
        </VStack>
      </CardBody>
    </Card>
  );
};

export default CampaignRegistration; 