import React from 'react';
import { Box, Heading, Text, Button, VStack, Image, useColorMode } from '@chakra-ui/react';
import { Link } from 'react-router-dom';

function NotFound() {
  const { colorMode } = useColorMode();
  const isDark = colorMode === 'dark';

  return (
    <Box 
      minHeight="90vh"
      display="flex" 
      alignItems="center" 
      justifyContent="center" 
      bg={isDark ? "gray.800" : "gray.50"}
      pt="-5vh"
    >
      <VStack spacing={4} textAlign="center">
        <Image 
          src="/404-illustration.jpeg" 
          alt="404 Illustration" 
          boxSize="200px" 
        />
        <Heading
          as="h1"
          size="2xl"
          bgGradient={isDark ? "linear(to-r, teal.200, teal.400)" : "linear(to-r, teal.400, teal.600)"}
          backgroundClip="text"
        >
          404
        </Heading>
        <Text fontSize="md" fontWeight="bold" color={isDark ? "white" : "black"}>
          Oops! Page Not Found
        </Text>
        <Text color={isDark ? "gray.300" : "gray.600"} maxW="sm" fontSize="sm">
          The page you're looking for doesn't exist or has been moved.
        </Text>
        <Button
          as={Link}
          to="/"
          colorScheme="teal"
          size="sm"
          fontWeight="bold"
          mt={1}
          _hover={{
            transform: 'translateY(-2px)',
            boxShadow: 'lg',
          }}
        >
          Return to Home
        </Button>
      </VStack>
    </Box>
  );
}

export default NotFound;
