import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { useToast } from '@chakra-ui/react';
import { Box, Button, FormControl, FormLabel, Input, VStack, HStack, Text, Spinner, Divider, Switch, useColorModeValue, Skeleton } from '@chakra-ui/react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import { EditIcon, TimeIcon } from '@chakra-ui/icons';
import { getAuthHeaders } from '../utils/authHeaders';
import { getToken } from '../utils/auth';
import WorkflowElements from './WorkflowElements';
import WorkflowSettings from './WorkflowSettings';

// Move color mode values outside the component
const bgColor = {
  light: 'white',
  dark: 'gray.800',
};

const textColor = {
  light: 'gray.800',
  dark: 'white',
};

const borderColor = {
  light: 'gray.200',
  dark: 'gray.600',
};

const WorkflowEditor = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [workflow, setWorkflow] = useState(null);
  const [loading, setLoading] = useState(true);
  const [name, setName] = useState('');
  const [workflowItems, setWorkflowItems] = useState([]);
  const [settings, setSettings] = useState({
    days: [],
    maxTextsPerPeriod: 0,
    acceptableTimes: { start: '09:00', end: '17:00' },
    tags: [],
  });
  const [error, setError] = useState('');
  const [availableTags, setAvailableTags] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const [phoneNumbers, setPhoneNumbers] = useState([]);
  const [assignedNumbers, setAssignedNumbers] = useState([]);
  const [status, setStatus] = useState('draft');
  const [runningState, setRunningState] = useState('idle');
  const toast = useToast();
  const [userPhoneNumbers, setUserPhoneNumbers] = useState([]);

  // Use a single useColorModeValue call
  const colorMode = useColorModeValue('light', 'dark');

  // Capitalize the first letter of runningState
  const capitalizedRunningState = runningState.charAt(0).toUpperCase() + runningState.slice(1);

  useEffect(() => {
    const fetchWorkflowAndUserData = async () => {
      setLoading(true);
      try {
        const config = getAuthHeaders();

        if (id) {
          // Existing workflow: fetch workflow data
          const { data } = await axios.get(`/api/workflows/${id}`, config);
          setWorkflow(data.workflow);
          setName(data.workflow.name);
          
          // Convert stored \n back to actual newlines for editing
          const processedItems = data.workflow.items.map(item => {
            if (item.type === 'message' && item.text) {
              return {
                ...item,
                text: item.text.replace(/\\n/g, '\n')
              };
            }
            return item;
          });
          
          setWorkflowItems(processedItems);
          setSettings(data.workflow.settings);
          setAssignedNumbers(data.workflow.assignedNumbers || []);
          setStatus(data.workflow.status);
          setRunningState(data.workflow.runningState);
          setUserPhoneNumbers(data.userPhoneNumbers);
        } else {
          // New workflow: fetch user data to get phone numbers
          const { data } = await axios.get('/api/users/profile', config);
          setUserPhoneNumbers(data.phoneNumbers || []);
        }

        const { data: tagsData } = await axios.get('/api/tags', config);
        setAvailableTags(tagsData);
      } catch (error) {
        setError('Failed to fetch workflow details or user data');
        console.error(`Error fetching data: ${error.message}\n${error.stack}`);
      } finally {
        setLoading(false);
      }
    };

    fetchWorkflowAndUserData();
  }, [id]);

  const convertDelayToMinutes = (delay, delayUnit) => {
    switch (delayUnit) {
      case 'days':
        return delay * 24 * 60;
      case 'hours':
        return delay * 60;
      default:
        return delay;
    }
  };

  const handleItemChange = (index, newItem) => {
    const newItems = [...workflowItems];
    newItems[index] = newItem;
    setWorkflowItems(newItems);
  };

  const handleAddItem = (type) => {
    const newItem = type === 'message'
      ? { id: `${type}-${workflowItems.length}`, type: 'message', text: '' }
      : { id: `${type}-${workflowItems.length}`, type: 'delay', delay: 0, delayUnit: 'minutes' };
    setWorkflowItems([...workflowItems, newItem]);
  };

  const handleRemoveItem = (index) => {
    const newItems = workflowItems.filter((_, i) => i !== index);
    setWorkflowItems(newItems);
  };

  const handleMoveItem = (index, direction) => {
    if (
      (direction === 'up' && index === 0) ||
      (direction === 'down' && index === workflowItems.length - 1)
    ) {
      return;
    }

    const newItems = [...workflowItems];
    const targetIndex = direction === 'up' ? index - 1 : index + 1;
    [newItems[index], newItems[targetIndex]] = [newItems[targetIndex], newItems[index]];
    setWorkflowItems(newItems);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSettings({ ...settings, [name]: value });
  };

  const handleDaysChange = (selectedDays) => {
    setSettings({ ...settings, days: selectedDays });
  };

  const handleAcceptableTimesChange = (newTimes) => {
    setSettings({ ...settings, acceptableTimes: newTimes });
  };

  const handleTagsChange = (selectedTags) => {
    setSettings({ ...settings, tags: selectedTags });
  };

  const handleNumbersChange = (selectedNumbers) => {
    setAssignedNumbers(selectedNumbers);
  };

  const validateForm = () => {
    console.log('Validating form with the following data:');
    console.log('Name:', name);
    console.log('Workflow Items:', workflowItems);
    console.log('Settings:', settings);
    console.log('Assigned Numbers:', assignedNumbers);
    console.log('Status:', status);

    if (!name) {
      console.log('Name is missing');
      toast({
        title: "Error",
        description: "Please enter a workflow name",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return false;
    }

    if (runningState !== 'idle') {
      console.log('Workflow is not in idle state');
      toast({
        title: "Cannot save changes",
        description: "Workflow must be in idle state to save changes or change status.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return false;
    }

    if (status === 'published') {
      if (workflowItems.length === 0) {
        console.log('No workflow items');
        toast({
          title: "Error",
          description: "Please add at least one message or delay to the workflow",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return false;
      }
      if (assignedNumbers.length === 0) {
        console.log('No assigned numbers');
        toast({
          title: "Error",
          description: "Please assign at least one phone number to the workflow",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return false;
      }
      if (!settings.days.length) {
        console.log('No days selected');
        toast({
          title: "Error",
          description: "Please select at least one day for the workflow",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return false;
      }
      if (!settings.maxTextsPerPeriod) {
        console.log('Max texts per period not set');
        toast({
          title: "Error",
          description: "Please set the maximum number of texts per period",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return false;
      }
      if (!settings.acceptableTimes.start || !settings.acceptableTimes.end) {
        console.log('Acceptable times not set');
        toast({
          title: "Error",
          description: "Please set the acceptable time range for sending messages",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return false;
      }
      const emptyMessageIndex = workflowItems.findIndex(item => item.type === 'message' && (!item.text || item.text.trim() === ''));
      if (emptyMessageIndex !== -1) {
        console.log('Empty message text');
        toast({
          title: "Error",
          description: `Please enter text for message #${emptyMessageIndex + 1}`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return false;
      }
      if (!assignedNumbers || assignedNumbers.length === 0) {
        console.log('No phone numbers assigned');
        toast({
          title: "Error",
          description: "Please assign at least one phone number to the workflow",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return false;
      }

      // Add time validation
      const { start, end } = settings.acceptableTimes;
      const [startHours, startMinutes] = start.split(':').map(Number);
      const [endHours, endMinutes] = end.split(':').map(Number);

      if (startHours < 9 || startHours > 20 || endHours < 9 || endHours > 20) {
        console.log('Invalid time range');
        toast({
          title: "Error",
          description: "Acceptable times must be between 9:00 AM and 8:00 PM",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return false;
      }

      if (!settings.dripsPerMinute || settings.dripsPerMinute < 1) {
        console.log('Messages per minute not set or invalid');
        toast({
          title: "Error",
          description: "Please set a valid number of messages per minute (minimum 1)",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return false;
      }
    }
    return true;
  };

  const validateTimes = (start, end) => {
    const [startHours, startMinutes] = start.split(':').map(Number);
    const [endHours, endMinutes] = end.split(':').map(Number);

    const isStartValid = startHours > 9 || (startHours === 9 && startMinutes === 0);
    const isEndValid = endHours < 20 || (endHours === 20 && endMinutes === 0);

    return isStartValid && isEndValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');

    if (!validateForm()) {
      return;
    }

    try {
      const config = getAuthHeaders();

      const items = workflowItems.map(item => {
        if (item.type === 'message') {
          return {
            ...item,
            // Keep newlines as single \n when saving
            text: item.text
          };
        }
        if (item.type === 'delay') {
          return {
            ...item,
            delayInMinutes: convertDelayToMinutes(item.delay, item.delayUnit)
          };
        }
        return item;
      });

      // Log the converted text for debugging
      console.log('Converted workflow items:', items);

      const workflowData = {
        name,
        items,
        settings: {
          ...settings,
          ...(settings.tags && settings.tags.length > 0 && { tags: settings.tags }),
        },
        assignedNumbers,
        status,
      };

      let response;
      if (id) {
        response = await axios.put(`/api/workflows/${id}`, workflowData, config);
      } else {
        response = await axios.post('/api/workflows', workflowData, config);
      }

      if (response.status === 200 || response.status === 201) {
        toast({
          title: "Success",
          description: `Workflow ${id ? 'updated' : 'created'} successfully`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        navigate('/workflows'); // Only navigate if submission is successful
      }
    } catch (error) {
      console.error(`Error ${id ? 'updating' : 'creating'} workflow:`, error.response?.data || error.message);
      if (error.response?.data?.errors) {
        error.response.data.errors.forEach(err => 
          toast({
            title: "Error",
            description: err,
            status: "error",
            duration: 5000,
            isClosable: true,
          })
        );
      } else {
        toast({
          title: "Error",
          description: `Failed to ${id ? 'update' : 'create'} workflow. Please try again.`,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  // Replace the loading spinner with skeleton
  if (loading) {
    return (
      <Box maxWidth="900px" margin="auto" p={8}>
        {/* Header Skeleton */}
        <Skeleton height="40px" width="300px" mx="auto" mb={8} />
        
        {/* Name Input Skeleton */}
        <Skeleton height="32px" mb={8} />

        {/* Tabs Skeleton */}
        <HStack mb={8}>
          <Skeleton height="40px" width="50%" />
          <Skeleton height="40px" width="50%" />
        </HStack>

        {/* Content Skeleton */}
        <VStack spacing={4} align="stretch">
          {[...Array(3)].map((_, i) => (
            <Box key={i}>
              <Skeleton height="100px" mb={2} />
              <HStack justify="flex-end">
                <Skeleton height="32px" width="32px" />
                <Skeleton height="32px" width="32px" />
                <Skeleton height="32px" width="32px" />
              </HStack>
            </Box>
          ))}
        </VStack>

        {/* Bottom Buttons Skeleton */}
        <HStack spacing={4} justify="flex-end" mt={8}>
          <Skeleton height="40px" width="100px" />
          <Skeleton height="40px" width="150px" />
        </HStack>
      </Box>
    );
  }

  return (
    <Box className="workflow-editor" maxWidth="900px" margin="auto" p={8} bg={bgColor[colorMode]} color={textColor[colorMode]}>
      <Text fontSize="3xl" fontWeight="bold" mb={8} textAlign="center">
        {id ? 'Edit Workflow' : 'Create Workflow'}
      </Text>
      {error && <Text color="red.500" textAlign="center">{error}</Text>}
      <form onSubmit={handleSubmit}>
        <VStack spacing={8} align="stretch">
          <FormControl>
            <FormLabel fontSize="lg">Workflow Name</FormLabel>
            <Input
              size="lg"
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
              borderColor={borderColor[colorMode]}
              _hover={{ borderColor: colorMode === 'light' ? "gray.300" : "gray.500" }}
              _focus={{ borderColor: "blue.500", boxShadow: "0 0 0 1px #3182ce" }}
            />
          </FormControl>

          <Tabs
            selectedIndex={activeTab}
            onSelect={(index) => setActiveTab(index)}
            isFitted
            variant="enclosed"
            colorScheme="blue"
          >
            <TabList mb="1em">
              <Tab _selected={{ color: "blue.500", borderColor: "blue.500" }}>
                <HStack>
                  <EditIcon />
                  <Text>Workflow Elements</Text>
                </HStack>
              </Tab>
              <Tab _selected={{ color: "blue.500", borderColor: "blue.500" }}>
                <HStack>
                  <TimeIcon />
                  <Text>Settings</Text>
                </HStack>
              </Tab>
            </TabList>

            <TabPanel>
              <WorkflowElements
                workflowItems={workflowItems}
                setWorkflowItems={setWorkflowItems}
                handleAddItem={handleAddItem}
                handleRemoveItem={handleRemoveItem}
                handleItemChange={handleItemChange}
                handleMoveItem={handleMoveItem}
              />
            </TabPanel>

            <TabPanel>
              <WorkflowSettings
                settings={settings}
                setSettings={setSettings}
                availableTags={availableTags}
                phoneNumbers={userPhoneNumbers}
                assignedNumbers={assignedNumbers}
                setAssignedNumbers={setAssignedNumbers}
                handleChange={handleChange}
                handleDaysChange={handleDaysChange}
                handleAcceptableTimesChange={handleAcceptableTimesChange}
                handleTagsChange={handleTagsChange}
                handleNumbersChange={handleNumbersChange}
              />
            </TabPanel>
          </Tabs>

          <Divider />

          <FormControl display="flex" alignItems="center" mb={4}>
            <FormLabel htmlFor="workflow-status" mb="0">
              Workflow Status: {capitalizedRunningState}
            </FormLabel>
          </FormControl>

          <FormControl display="flex" alignItems="center">
            <FormLabel htmlFor="workflow-status" mb="0">
              Publish Workflow
            </FormLabel>
            <Switch
              id="workflow-status"
              isChecked={status === 'published'}
              onChange={(e) => {
                if (runningState !== 'idle') {
                  toast({
                    title: "Cannot change status",
                    description: "Workflow must be in idle state to change status.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                  });
                  return;
                }
                setStatus(e.target.checked ? 'published' : 'draft');
              }}
              isDisabled={runningState !== 'idle'}
            />
          </FormControl>

          <HStack spacing={4} justify="flex-end">
            <Button onClick={() => navigate('/workflows')} variant="outline" size="lg">
              Cancel
            </Button>
            <Button type="submit" colorScheme="blue" size="lg">
              {id ? (status === 'published' ? 'Update & Publish' : 'Update Draft') : (status === 'published' ? 'Create & Publish' : 'Save Draft')}
            </Button>
          </HStack>
        </VStack>
      </form>
    </Box>
  );
};

WorkflowEditor.defaultProps = {
  onWorkflowCreated: () => {},
};

export default WorkflowEditor;
