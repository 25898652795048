import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, Flex, Text, useColorModeValue, Center, Skeleton, VStack } from '@chakra-ui/react';
import { toast } from 'react-toastify';
import axios from 'axios';
import ConversationList from '../components/ConversationList';
import MessageView from '../components/MessageView';
import ContactInfo from '../components/ContactInfo';
import sseManager from '../utils/sseManager';
import { useLocation } from 'react-router-dom';
import debounce from 'lodash/debounce';
import Header from '../components/Header';

// Add these cache utility functions at the top of the file
const CONVERSATIONS_CACHE_KEY = 'conversationsCache';
const CONTACT_INFO_CACHE_KEY = 'contactInfoCache';
const CACHE_EXPIRY = 3600000; // 1 hour in milliseconds

const getCache = (key) => {
  const cache = localStorage.getItem(key);
  if (!cache) return {};
  return JSON.parse(cache);
};

const MAX_CACHE_SIZE = 100;
const MAX_MESSAGES_PER_CONTACT = 50; // Limit message history in cache

const setCache = (key, cache) => {
  try {
    // Trim messages for each contact to reduce size
    const trimmedCache = Object.entries(cache).reduce((acc, [id, entry]) => {
      if (entry.data.messages?.length > MAX_MESSAGES_PER_CONTACT) {
        entry.data.messages = entry.data.messages.slice(-MAX_MESSAGES_PER_CONTACT);
      }
      acc[id] = entry;
      return acc;
    }, {});

    // Clean old entries if needed
    const entries = Object.entries(trimmedCache);
    if (entries.length > MAX_CACHE_SIZE) {
      const sortedEntries = entries.sort((a, b) => b[1].timestamp - a[1].timestamp);
      const cleanedCache = Object.fromEntries(sortedEntries.slice(0, MAX_CACHE_SIZE));
      localStorage.setItem(key, JSON.stringify(cleanedCache));
    } else {
      localStorage.setItem(key, JSON.stringify(trimmedCache));
    }
  } catch (error) {
    if (error.name === 'QuotaExceededError') {
      // If we hit storage limit, clear older entries more aggressively
      const existingCache = getCache(key);
      const entries = Object.entries(existingCache)
        .sort((a, b) => b[1].timestamp - a[1].timestamp)
        .slice(0, MAX_CACHE_SIZE / 2); // Keep only half
      
      localStorage.setItem(key, JSON.stringify(Object.fromEntries(entries)));
      console.warn('Storage quota exceeded, cleared older cache entries');
    } else {
      console.error('Cache error:', error);
    }
  }
};

const ContactInfoSkeleton = () => (
  <Box p={4}>
    <VStack spacing={4} align="stretch">
      <Skeleton height="40px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="20px" />
      <Skeleton height="100px" />
      <Skeleton height="40px" />
    </VStack>
  </Box>
);

const Conversations = () => {
  const [selectedContact, setSelectedContact] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [contactCache, setContactCache] = useState({});
  const [hasMore, setHasMore] = useState(true);
  const [activeTab, setActiveTab] = useState('all');
  const [userTags, setUserTags] = useState({});
  const location = useLocation();
  const [isSearching, setIsSearching] = useState(false);
  const [quickResponses, setQuickResponses] = useState([]);
  const [sseConnected, setSSEConnected] = useState(false);

  const fetchingRef = useRef(false);
  const activeTabRef = useRef(activeTab);

  const backgroundColor = useColorModeValue('gray.50', 'gray.900');
  const borderColor = useColorModeValue('gray.200', 'gray.700');

  const fetchContacts = useCallback(async (pageNumber, tab) => {
    if (fetchingRef.current) return;
    fetchingRef.current = true;
    setLoading(true);
    
    try {
      const token = JSON.parse(localStorage.getItem('userInfo')).token;
      
      // Fetch conversations
      const conversationsResponse = await axios.get(
        `/api/conversations?page=${pageNumber}&limit=15&tab=${tab || activeTab}&optedOut=false`, 
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      
      if (conversationsResponse.data && conversationsResponse.data.contacts) {
        const filteredContacts = conversationsResponse.data.contacts.filter(contact => !contact.optedOut);
        
        // Fetch full contact data for each contact in parallel
        const contactDetailsPromises = filteredContacts.map(contact =>
          axios.get(`/api/contacts/${contact._id}?includeMessages=true`, {
            headers: { Authorization: `Bearer ${token}` },
          }).catch(error => {
            console.error(`Error fetching details for contact ${contact._id}:`, error);
            return { data: contact }; // Fallback to basic contact data if fetch fails
          })
        );

        const contactDetailsResponses = await Promise.all(contactDetailsPromises);
        
        // Get existing caches
        const conversationsCache = getCache(CONVERSATIONS_CACHE_KEY);
        const contactInfoCache = getCache(CONTACT_INFO_CACHE_KEY);
        const now = Date.now();

        // Merge new data with existing cache
        const updatedConversationsCache = {
          ...conversationsCache,
          ...contactDetailsResponses.reduce((acc, response, index) => {
            const contactId = filteredContacts[index]._id;
            acc[contactId] = {
              data: filteredContacts[index],
              timestamp: now
            };
            return acc;
          }, {})
        };

        const updatedContactInfoCache = {
          ...contactInfoCache,
          ...contactDetailsResponses.reduce((acc, response, index) => {
            const contactId = filteredContacts[index]._id;
            acc[contactId] = {
              data: response.data,
              timestamp: now
            };
            return acc;
          }, {})
        };

        // Limit cache size (optional, adjust MAX_CACHE_SIZE as needed)
        const MAX_CACHE_SIZE = 100;
        const cleanCacheIfNeeded = (cache) => {
          const entries = Object.entries(cache);
          if (entries.length > MAX_CACHE_SIZE) {
            // Sort by timestamp and keep only the most recent entries
            const sortedEntries = entries.sort((a, b) => b[1].timestamp - a[1].timestamp);
            return Object.fromEntries(sortedEntries.slice(0, MAX_CACHE_SIZE));
          }
          return cache;
        };

        setCache(CONVERSATIONS_CACHE_KEY, cleanCacheIfNeeded(updatedConversationsCache));
        setCache(CONTACT_INFO_CACHE_KEY, cleanCacheIfNeeded(updatedContactInfoCache));

        // Update state
        if (pageNumber === 1) {
          setContacts(filteredContacts);
        } else {
          setContacts(prevContacts => {
            const newContacts = [...prevContacts];
            filteredContacts.forEach(contact => {
              if (!contact.optedOut && !newContacts.some(c => c._id === contact._id)) {
                newContacts.push(contact);
              }
            });
            return newContacts;
          });
        }
        
        setHasMore(filteredContacts.length === 15);
      }
    } catch (error) {
      setError('Failed to fetch contacts');
    } finally {
      setLoading(false);
      fetchingRef.current = false;
    }
  }, [activeTab]);

  useEffect(() => {
    activeTabRef.current = activeTab;
  }, [activeTab]);

  useEffect(() => {
    setPage(1);
    fetchContacts(1, activeTab);
  }, [activeTab, fetchContacts]);

  const loadMore = useCallback(() => {
    if (!loading && hasMore && !fetchingRef.current) {
      const scrollContainer = document.getElementById('scrollableDiv');
      const scrollHeight = scrollContainer?.scrollHeight;
      const scrollTop = scrollContainer?.scrollTop;
      
      const nextPage = page + 1;
      setPage(nextPage);
      
      fetchContacts(nextPage, activeTabRef.current).then(() => {
        if (scrollContainer && scrollHeight && scrollTop) {
          const isNearBottom = scrollTop + scrollContainer.clientHeight >= scrollHeight - 100;
          if (!isNearBottom) {
            scrollContainer.scrollTop = scrollTop;
          }
        }
      });
    }
  }, [loading, hasMore, page, fetchContacts]);

  const handleTabChange = useCallback((newTab) => {
    if (newTab !== activeTab) {  // Only proceed if the tab is actually changing
      setActiveTab(newTab);
      setPage(1);
      setContacts([]);
    }
  }, [activeTab]);

  const updateContactWithNewMessage = useCallback((contactId, newMessage, currentCache) => {
    const isIncoming = newMessage.direction === 'inbound';

    // Update the cache
    setContactCache((prevCache) => {
      const existingContact = prevCache[contactId] || {};
      const updatedMessages = [...(existingContact.messages || []), newMessage];
      
      return {
        ...prevCache,
        [contactId]: {
          ...existingContact,
          lastMessage: newMessage,
          lastMessageAt: newMessage.timestamp,
          unread: isIncoming ? true : existingContact.unread,
          messages: updatedMessages,
        },
      };
    });

    // Update contacts list
    setContacts(prevContacts => {
      const existingContact = prevContacts.find(c => c._id === contactId);
      if (existingContact) {
        const updatedContact = {
          ...existingContact,
          lastMessage: newMessage,
          lastMessageAt: newMessage.timestamp,
          unread: isIncoming ? true : existingContact.unread,
        };
        return [
          updatedContact,
          ...prevContacts.filter(c => c._id !== contactId)
        ];
      }
      return prevContacts;
    });

    // Update selected contact if needed
    if (selectedContact && selectedContact._id === contactId) {
      setSelectedContact((prevContact) => ({
        ...prevContact,
        messages: [...(prevContact.messages || []), newMessage],
        lastMessage: newMessage,
        lastMessageAt: newMessage.timestamp,
        unread: isIncoming ? true : prevContact.unread,
      }));
    }
  }, [selectedContact]);

  const handleDripUpdate = useCallback((contactId, updatedDrip) => {
    setContacts((prevContacts) => {
      return prevContacts.map((contact) => {
        if (contact._id === contactId) {
          const updatedMessages = [...(contact.messages || []), {
            text: updatedDrip.message,
            timestamp: updatedDrip.sentAt,
            isIncoming: false,
            isDrip: true
          }];
          return {
            ...contact,
            messages: updatedMessages,
            lastMessage: {
              text: updatedDrip.message,
              timestamp: updatedDrip.sentAt
            }
          };
        }
        return contact;
      });
    });

    if (selectedContact && selectedContact._id === contactId) {
      setSelectedContact((prevContact) => ({
        ...prevContact,
        messages: [...(prevContact.messages || []), {
          text: updatedDrip.message,
          timestamp: updatedDrip.sentAt,
          isIncoming: false,
          isDrip: true
        }],
        lastMessage: {
          text: updatedDrip.message,
          timestamp: updatedDrip.sentAt
        }
      }));
    }
  }, [selectedContact]);

  const addNewContact = useCallback((newContact) => {
    setContacts((prevContacts) => [newContact, ...prevContacts]);
    setContactCache((prevCache) => ({
      ...prevCache,
      [newContact._id]: newContact,
    }));
  }, []);

  const shouldShowInCurrentTab = useCallback((contact) => {
    switch (activeTab) {
      case 'unread':
        return contact.unread && !contact.archived;
      case 'starred':
        return contact.starred && !contact.archived;
      case 'archived':
        return contact.archived;
      case 'all':
        return !contact.archived;
      default:
        return true;
    }
  }, [activeTab]);

  const handleSSEEvent = useCallback((data) => {
    console.log('Received SSE event:', data);
    
    const updateContactsAndCaches = (contactId, updatedContact) => {
      const now = Date.now();
      
      // Update both caches
      const conversationsCache = getCache(CONVERSATIONS_CACHE_KEY);
      const contactInfoCache = getCache(CONTACT_INFO_CACHE_KEY);
      
      const updatedCaches = {
        conversations: {
          ...conversationsCache,
          [contactId]: {
            data: updatedContact,
            timestamp: now
          }
        },
        contactInfo: {
          ...contactInfoCache,
          [contactId]: {
            data: updatedContact,
            timestamp: now
          }
        }
      };

      setCache(CONVERSATIONS_CACHE_KEY, updatedCaches.conversations);
      setCache(CONTACT_INFO_CACHE_KEY, updatedCaches.contactInfo);

      // Update contacts list if contact should appear in current tab
      if (shouldShowInCurrentTab(updatedContact)) {
        setContacts(prevContacts => {
          const existingIndex = prevContacts.findIndex(c => c._id === contactId);
          const newContacts = existingIndex >= 0
            ? prevContacts.filter(c => c._id !== contactId)
            : [...prevContacts];
          
          return [updatedContact, ...newContacts];
        });
      }

      // Update selected contact if it matches
      if (selectedContact?._id === contactId) {
        setSelectedContact(updatedContact);
      }

      // Update contact cache
      setContactCache(prev => ({
        ...prev,
        [contactId]: updatedContact
      }));
    };
    
    switch (data.type) {
      case 'newMessage':
      case 'messageSent':
        if (data.fullContact) {
          // Handle full contact update
          updateContactsAndCaches(data.contactId, {
            ...data.fullContact,
            lastMessageAt: new Date().toISOString()
          });
        } else if (data.message) {
          // Handle message-only update
          const existingContact = contactCache[data.contactId] || 
                                contacts.find(c => c._id === data.contactId);
          
          if (existingContact) {
            const updatedContact = {
              ...existingContact,
              messages: [...(existingContact.messages || []), data.message],
              lastMessage: data.message,
              lastMessageAt: new Date().toISOString(),
              // Update unread status for incoming messages
              unread: data.message.direction === 'inbound' && 
                     selectedContact?._id !== data.contactId ? true : existingContact.unread
            };
            
            updateContactsAndCaches(data.contactId, updatedContact);
          } else {
            // If contact doesn't exist in cache or current list, fetch it
            const fetchAndUpdateContact = async () => {
              try {
                const token = JSON.parse(localStorage.getItem('userInfo')).token;
                const response = await axios.get(`/api/contacts/${data.contactId}?includeMessages=true`, {
                  headers: { Authorization: `Bearer ${token}` },
                });
                
                if (response.data) {
                  updateContactsAndCaches(data.contactId, response.data);
                }
              } catch (error) {
                console.error('Error fetching contact:', error);
              }
            };
            
            fetchAndUpdateContact();
          }
        }
        break;

      case 'messageStatus':
        // Update message status in all relevant places
        const existingContact = contactCache[data.contactId] || 
                              contacts.find(c => c._id === data.contactId);
        
        if (existingContact) {
          const updatedContact = {
            ...existingContact,
            messages: existingContact.messages?.map(msg =>
              msg.messageId === data.messageId
                ? { ...msg, status: data.status }
                : msg
            )
          };
          
          updateContactsAndCaches(data.contactId, updatedContact);
        }
        break;

      case 'contactOptOut':
        // Remove from all data stores
        setContacts(prev => prev.filter(c => c._id !== data.contactId));
        setContactCache(prev => {
          const newCache = { ...prev };
          delete newCache[data.contactId];
          return newCache;
        });
        if (selectedContact?._id === data.contactId) {
          setSelectedContact(null);
        }
        break;

      // Add any other cases as needed
    }
  }, [activeTab, selectedContact, contacts, contactCache, shouldShowInCurrentTab]);

  const isInitialMount = useRef(true);

  // Add fetchTags function
  const fetchTags = useCallback(async () => {
    try {
      const token = JSON.parse(localStorage.getItem('userInfo')).token;
      const response = await axios.get('/api/tags', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      
      // Convert array to object with name as key for easier lookup
      const tagsMap = response.data.reduce((acc, tag) => {
        acc[tag.name] = tag;
        return acc;
      }, {});
      
      setUserTags(tagsMap);
    } catch (error) {
      console.error('Error fetching tags:', error);
      toast.error('Failed to fetch tags');
    }
  }, []);

  const fetchQuickResponses = useCallback(async () => {
    try {
      const token = JSON.parse(localStorage.getItem('userInfo')).token;
      const { data } = await axios.get('/api/responses', {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setQuickResponses(data);
    } catch (error) {
      console.error('Error fetching quick responses:', error);
      toast.error('Failed to fetch quick responses');
    }
  }, []);

  // Single consolidated SSE initialization
  useEffect(() => {
    const handleSSEConnected = () => {
      console.log('SSE Connected successfully');
      setSSEConnected(true);
    };

    // Initial data fetching
    fetchTags();
    fetchQuickResponses();
    fetchContacts(page);

    // Single SSE initialization
    sseManager.disconnect(); // Clean up any existing connection
    sseManager.addListener('connected', handleSSEConnected);
    sseManager.connect();

    // Add event listeners
    const events = ['newMessage', 'newContact', 'messageSent', 'messageStatus', 'contactOptOut'];
    events.forEach(event => sseManager.addListener(event, handleSSEEvent));

    // Cleanup function
    return () => {
      sseManager.disconnect();
      sseManager.removeListener('connected', handleSSEConnected);
      events.forEach(event => sseManager.removeListener(event, handleSSEEvent));
    };
  }, []); // Empty dependency array - only run once on mount

  const fetchFullContactData = useCallback(async (contactId) => {
    try {
      const token = JSON.parse(localStorage.getItem('userInfo')).token;
      const response = await axios.get(`/api/contacts/${contactId}?includeMessages=true`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data;
    } catch (error) {
      console.error('Error fetching full contact data:', error);
      toast.error('Failed to fetch contact details');
      throw error;
    }
  }, []);

  const handleSelectContact = useCallback(async (contact) => {
    try {
      let fullContactData;
      const cache = getCache(CONTACT_INFO_CACHE_KEY);
      
      // Check if we have valid cached data
      if (cache[contact._id]?.timestamp > Date.now() - CACHE_EXPIRY && cache[contact._id].data.messages) {
        fullContactData = cache[contact._id].data;
      } else {
        fullContactData = await fetchFullContactData(contact._id);
        // Update cache with full contact data
        const contactInfoCache = getCache(CONTACT_INFO_CACHE_KEY);
        contactInfoCache[contact._id] = {
          data: fullContactData,
          timestamp: Date.now()
        };
        setCache(CONTACT_INFO_CACHE_KEY, contactInfoCache);
      }

      setSelectedContact(fullContactData);

      // If the contact was unread, mark it as read
      if (fullContactData.unread) {
        try {
          const token = JSON.parse(localStorage.getItem('userInfo')).token;
          const response = await axios.put(
            `/api/contacts/${fullContactData._id}/mark-read`,
            {},
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (response.data) {
            const updatedContact = { ...fullContactData, unread: false };
            
            // Update contacts list
            setContacts((prevContacts) =>
              prevContacts.map((c) =>
                c._id === updatedContact._id ? updatedContact : c
              )
            );

            // Update contact cache
            setContactCache((prevCache) => ({
              ...prevCache,
              [updatedContact._id]: updatedContact,
            }));

            // Update selected contact
            setSelectedContact(updatedContact);
          }
        } catch (error) {
          console.error('Error marking contact as read:', error);
          toast.error('Failed to mark contact as read');
        }
      }
    } catch (error) {
      console.error('Error fetching full contact data:', error);
      toast.error('Failed to fetch contact details');
    }
  }, [fetchFullContactData]);

  const handleUpdateContact = useCallback((updatedContact) => {
    // Update the contacts list
    setContacts(prevContacts =>
      prevContacts.map(contact =>
        contact._id === updatedContact._id ? updatedContact : contact
      )
    );

    // Update selected contact if it's the same one
    if (selectedContact?._id === updatedContact._id) {
      setSelectedContact(updatedContact);
    }

    // Update contact cache
    setContactCache(prevCache => ({
      ...prevCache,
      [updatedContact._id]: updatedContact
    }));
  }, [selectedContact]);

  const handleSaveContact = async (updatedContact) => {
    try {
      // Log the data being sent
      console.log('Saving contact data:', updatedContact);

      const token = JSON.parse(localStorage.getItem('userInfo')).token;
      const response = await axios.put(
        `/api/contacts/${updatedContact._id}`,
        {
          ...updatedContact,
          // Ensure tags is an array of ObjectIds
          tags: Array.isArray(updatedContact.tags) ? updatedContact.tags : []
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json'
          },
        }
      );

      if (response.data) {
        setContacts((prevContacts) =>
          prevContacts.map((c) =>
            c._id === updatedContact._id ? response.data : c
          )
        );
        setSelectedContact(response.data);
        toast.success('Contact updated successfully');
      }
    } catch (error) {
      console.error('Error updating contact:', error);
      // Log the actual error response
      if (error.response) {
        console.error('Server error response:', error.response.data);
      }
      toast.error('Failed to update contact');
    }
  };

  // Add a useEffect to handle new messages from SSE
  useEffect(() => {
    const handleNewMessage = (data) => {
      if (data.contactId && selectedContact?._id === data.contactId) {
        // Update the selected contact's messages
        setSelectedContact(prev => {
          if (!prev) return prev;
          return {
            ...prev,
            messages: [...(prev.messages || []), data.message],
            lastMessageAt: new Date(),
            unread: true
          };
        });
      }
    };

    // Subscribe to SSE events
    sseManager.addListener('newMessage', handleNewMessage);

    return () => {
      sseManager.removeListener('newMessage', handleNewMessage);
    };
  }, [selectedContact]);

  // Add debounced search function
  const debouncedSearch = useCallback(
    debounce(async (query) => {
      if (!query) {
        // If search is cleared, fetch normal contacts
        setPage(1);
        fetchContacts(1, activeTab);
        return;
      }

      setIsSearching(true);
      try {
        const token = JSON.parse(localStorage.getItem('userInfo')).token;
        const response = await axios.get(
          `/api/conversations/search?q=${query}&tab=${activeTab}&page=1&limit=15`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        
        if (response.data) {
          setContacts(response.data.contacts);
          setHasMore(response.data.hasMore);
        }
      } catch (error) {
        console.error('Search error:', error);
        toast.error('Failed to search conversations');
      } finally {
        setIsSearching(false);
      }
    }, 500),
    [activeTab, fetchContacts]
  );

  // Add this new function
  const handleRefresh = useCallback(async () => {
    setPage(1);
    setContacts([]);
    setHasMore(true);
    try {
      await fetchContacts(1, activeTab);
    } catch (error) {
      console.error('Error refreshing conversations:', error);
      toast.error('Failed to refresh conversations');
    }
  }, [activeTab, fetchContacts]);

  const handleSignOut = useCallback(() => {
    localStorage.removeItem('userInfo');
    window.location.href = '/login';
  }, []);

  // Single event listener registration effect
  useEffect(() => {
    if (sseConnected) {
      const events = ['newMessage', 'newContact', 'messageSent', 'messageStatus', 'contactOptOut'];
      events.forEach(event => sseManager.addListener(event, handleSSEEvent));
      
      return () => {
        events.forEach(event => sseManager.removeListener(event, handleSSEEvent));
      };
    }
  }, [sseConnected, handleSSEEvent]);

  // Add this effect to clear cache on unmount
  useEffect(() => {
    // This runs when component mounts
    return () => {
      // This runs when component unmounts (user leaves the page)
      localStorage.removeItem(CONVERSATIONS_CACHE_KEY);
      localStorage.removeItem(CONTACT_INFO_CACHE_KEY);
      console.log('Cache cleared - user left conversations page');
    };
  }, []); // Empty dependency array means this only runs on mount/unmount

  return (
    <Flex direction="column" h="100vh">
      <Header 
        user={JSON.parse(localStorage.getItem('userInfo'))}
        onSignOut={handleSignOut}
        unreadNotifications={contacts.filter(c => c.unread).length}
      />
      <Flex h="calc(100vh - 64px)" direction="row" bg={backgroundColor} p={4} gap={4}>
        <Box
          w="30%"
          borderWidth="1px"
          borderColor={borderColor}
          p={3}
          display="flex"
          flexDirection="column"
          bg={useColorModeValue('white', 'gray.800')}
          borderRadius="xl"
          boxShadow="sm"
        >
          <Box flex="1" overflowY="auto">
            <ConversationList
              contacts={contacts}
              setContacts={setContacts}
              onSelectContact={handleSelectContact}
              selectedContactId={selectedContact?._id}
              onUpdateContact={handleUpdateContact}
              hasMore={hasMore}
              loadMore={loadMore}
              activeTab={activeTab}
              setActiveTab={handleTabChange}
              userTags={userTags}
              loading={loading || isSearching}
              onSearch={debouncedSearch}
              onRefresh={handleRefresh}
            />
          </Box>
        </Box>

        <Box
          w="40%"
          display="flex"
          flexDirection="column"
          borderWidth="1px"
          borderColor={borderColor}
          bg={useColorModeValue('white', 'gray.800')}
          borderRadius="xl"
          boxShadow="sm"
        >
          <MessageView
            contact={selectedContact}
            onUpdateContact={handleUpdateContact}
            isInModal={false}
            quickResponses={quickResponses}
          />
        </Box>

        <Box 
          w="30%" 
          p={3} 
          bg={useColorModeValue('white', 'gray.800')}
          borderWidth="1px"
          borderColor={borderColor}
          borderRadius="xl"
          boxShadow="sm"
        >
          {loading ? (
            <ContactInfoSkeleton />
          ) : error ? (
            <Text color="red.500" m="auto">
              {error}
            </Text>
          ) : selectedContact ? (
            <ContactInfo 
              contact={selectedContact} 
              onSave={handleSaveContact}
              userTags={userTags}
            />
          ) : (
            <Center height="100%">
              <Text fontSize="xl" color="gray.500" textAlign="center">
                Please select a conversation to display contact details.
              </Text>
            </Center>
          )}
        </Box>
      </Flex>
    </Flex>
  );
};

export default Conversations;
