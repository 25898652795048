import React, { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';
import {
  Box, Spinner, Text, IconButton, Button, Flex, SimpleGrid, VStack, HStack, Heading, Container,
  useColorModeValue, Badge, Menu, MenuButton, MenuList, MenuItem, useDisclosure, Modal,
  ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Input,
  InputGroup, InputLeftElement, Tooltip, useToast, Divider, Skeleton
} from '@chakra-ui/react';
import { FaEdit, FaPlus, FaEllipsisV, FaTrash, FaCopy, FaSearch, FaCalendarAlt, FaEnvelope, FaUsers, FaPause, FaPlay } from 'react-icons/fa';
import { getAuthHeaders } from '../utils/authHeaders';
import Header from '../components/Header';

const WorkflowsList = () => {
  const [workflows, setWorkflows] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);
  const toast = useToast();

  // Color mode values
  const bgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const inputBgColor = useColorModeValue('white', 'gray.800');
  const pageBgColor = useColorModeValue('gray.50', 'gray.800');

  useEffect(() => {
    const fetchWorkflows = async () => {
      try {
        const config = getAuthHeaders();
        const { data } = await axios.get('/api/workflows', config);
        setWorkflows(data);
        setLoading(false);
      } catch (error) {
        setError('Failed to fetch workflows');
        toast({
          title: "Error",
          description: "Failed to fetch workflows",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        console.error(`Error fetching workflows: ${error.message}\n${error.stack}`);
        setLoading(false);
      }
    };

    fetchWorkflows();
  }, [toast]);

  const handleCreateNewWorkflow = () => {
    navigate('/workflows/new');
  };

  const handleEditWorkflow = (id) => {
    navigate(`/workflows/edit/${id}`);
  };

  const handleDuplicateWorkflow = async (workflow) => {
    try {
      const config = getAuthHeaders();
      const { data } = await axios.post(`/api/workflows/duplicate/${workflow._id}`, {}, config);
      setWorkflows([...workflows, data]);
      toast({
        title: "Success",
        description: "Workflow duplicated successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: "Failed to duplicate workflow",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error('Error duplicating workflow:', error);
    }
  };

  const handleDeleteWorkflow = async (id) => {
    try {
      const config = getAuthHeaders();
      await axios.delete(`/api/workflows/${id}`, config);
      setWorkflows(workflows.filter(w => w._id !== id));
      toast({
        title: "Success",
        description: "Workflow deleted successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      const errorMessage = error.response?.data?.message || "Failed to delete workflow";
      
      if (errorMessage.includes("Cannot delete a running workflow")) {
        toast({
          title: "Workflow is Running",
          description: "Workflows cannot be deleted while they are running.",
          status: "warning",
          duration: 7000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error",
          description: errorMessage,
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const handlePauseWorkflow = async (id) => {
    try {
      const config = getAuthHeaders();
      await axios.post(`/api/workflows/${id}/pause`, {}, config);
      
      setWorkflows(workflows.map(w => 
        w._id === id ? { ...w, runningState: 'paused' } : w
      ));

      toast({
        title: "Success",
        description: "Workflow paused successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.message || "Failed to pause workflow",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error('Error pausing workflow:', error);
    }
  };

  const handleResumeWorkflow = async (id) => {
    try {
      const config = getAuthHeaders();
      await axios.post(`/api/workflows/${id}/resume`, {}, config);
      
      setWorkflows(workflows.map(w => 
        w._id === id ? { ...w, runningState: 'running' } : w
      ));

      toast({
        title: "Success",
        description: "Workflow resumed successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      toast({
        title: "Error",
        description: error.response?.data?.message || "Failed to resume workflow",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      console.error('Error resuming workflow:', error);
    }
  };

  const filteredWorkflows = workflows ? workflows.filter(workflow =>
    workflow.name.toLowerCase().includes(searchTerm.toLowerCase())
  ) : [];

  if (loading) {
    return (
      <>
        <Header />
        <Box bg={pageBgColor} minHeight="100vh" py={10}>
          <Container maxW="container.xl">
            <VStack spacing={8} align="stretch">
              <Flex justify="space-between" align="center">
                <Skeleton height="40px" width="200px" />
              </Flex>
              <Skeleton height="40px" />
              <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
                {[...Array(6)].map((_, index) => (
                  <Box
                    key={index}
                    p={5}
                    borderWidth={1}
                    borderRadius="lg"
                    borderColor={borderColor}
                    bg={bgColor}
                  >
                    <VStack align="stretch" spacing={3}>
                      <HStack justify="space-between">
                        <Skeleton height="24px" width="150px" />
                        <HStack>
                          <Skeleton height="20px" width="80px" />
                          <Skeleton height="32px" width="32px" borderRadius="md" />
                        </HStack>
                      </HStack>
                      <Skeleton height="1px" />
                      <HStack>
                        <Skeleton height="16px" width="150px" />
                      </HStack>
                      <HStack>
                        <Skeleton height="20px" width="100px" />
                        <Skeleton height="20px" width="100px" />
                      </HStack>
                    </VStack>
                  </Box>
                ))}
              </SimpleGrid>
            </VStack>
          </Container>
        </Box>
      </>
    );
  }

  if (error) {
    return (
      <>
        <Header />
        <Box bg={pageBgColor} minHeight="100vh" py={10}>
          <Container maxW="container.xl">
            <Box bg={bgColor} borderRadius="xl" p={8} boxShadow="lg">
              <Text color="red.500" textAlign="center">{error}</Text>
            </Box>
          </Container>
        </Box>
      </>
    );
  }

  if (!workflows || workflows.length === 0) {
    return (
      <>
        <Header />
        <Box bg={pageBgColor} minHeight="100vh" py={10}>
          <Container maxW="container.xl">
            <Box bg={bgColor} borderRadius="xl" p={8} boxShadow="lg">
              <VStack spacing={4} align="center">
                <Text fontSize="lg" fontWeight="medium" color="gray.500">
                  No workflows yet
                </Text>
                <Text color="gray.500" textAlign="center">
                  Create your first workflow to start automating your communications
                </Text>
                <Button
                  leftIcon={<FaPlus />}
                  colorScheme="blue"
                  size="lg"
                  borderRadius="full"
                  boxShadow="md"
                  onClick={handleCreateNewWorkflow}
                >
                  Create New Workflow
                </Button>
              </VStack>
            </Box>
          </Container>
        </Box>
      </>
    );
  }

  return (
    <>
      <Header />
      <Container maxW="container.xl" py={8}>
        <VStack spacing={8} align="stretch">
          <Flex justify="space-between" align="center">
            <Tooltip label="Create a new workflow" placement="left">
              <Button leftIcon={<FaPlus />} colorScheme="blue" onClick={handleCreateNewWorkflow}>
                Create New Workflow
              </Button>
            </Tooltip>
          </Flex>

          <InputGroup>
            <InputLeftElement pointerEvents="none">
              <FaSearch color="gray.300" />
            </InputLeftElement>
            <Input
              placeholder="Search workflows..."
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              bg={inputBgColor}
            />
          </InputGroup>

          <SimpleGrid columns={{ base: 1, md: 2, lg: 3 }} spacing={6}>
            {filteredWorkflows.map(workflow => (
              <Box
                key={workflow._id}
                p={5}
                borderWidth={1}
                borderRadius="lg"
                borderColor={borderColor}
                boxShadow="sm"
                bg={bgColor}
                transition="all 0.2s"
                _hover={{ boxShadow: 'md', transform: 'translateY(-2px)' }}
              >
                <VStack align="stretch" spacing={3}>
                  <HStack justify="space-between">
                    <Heading size="md" isTruncated>{workflow.name}</Heading>
                    <HStack>
                      <Badge colorScheme={workflow.status === 'published' ? 'green' : 'yellow'}>
                        {workflow.status}
                      </Badge>
                      {workflow.status === 'published' && (
                        <Badge colorScheme={workflow.runningState === 'running' ? 'blue' : 'gray'}>
                          {workflow.runningState}
                        </Badge>
                      )}
                      {workflow.status === 'published' && workflow.runningState === 'running' && (
                        <Tooltip label="Pause Workflow">
                          <IconButton
                            icon={<FaPause />}
                            size="sm"
                            colorScheme="yellow"
                            onClick={() => handlePauseWorkflow(workflow._id)}
                            aria-label="Pause Workflow"
                          />
                        </Tooltip>
                      )}
                      {workflow.status === 'published' && workflow.runningState === 'paused' && (
                        <Tooltip label="Resume Workflow">
                          <IconButton
                            icon={<FaPlay />}
                            size="sm"
                            colorScheme="green"
                            onClick={() => handleResumeWorkflow(workflow._id)}
                            aria-label="Resume Workflow"
                          />
                        </Tooltip>
                      )}
                      <Menu>
                        <MenuButton
                          as={IconButton}
                          aria-label='Options'
                          icon={<FaEllipsisV />}
                          variant='ghost'
                          size='sm'
                        />
                        <MenuList>
                          <MenuItem icon={<FaEdit />} onClick={() => handleEditWorkflow(workflow._id)}>
                            Edit
                          </MenuItem>
                          <MenuItem icon={<FaCopy />} onClick={() => handleDuplicateWorkflow(workflow)}>
                            Duplicate
                          </MenuItem>
                          <MenuItem icon={<FaTrash />} onClick={() => {
                            setSelectedWorkflow(workflow);
                            onOpen();
                          }}>
                            Delete
                          </MenuItem>
                        </MenuList>
                      </Menu>
                    </HStack>
                  </HStack>
                  <Divider />
                  <HStack>
                    <FaCalendarAlt />
                    <Text fontSize="sm" color="gray.500">
                      Created: {new Date(workflow.createdAt).toLocaleDateString()}
                    </Text>
                  </HStack>
                  <HStack>
                    <Badge colorScheme="blue" display="flex" alignItems="center">
                      <FaEnvelope style={{ marginRight: '4px' }} />
                      {workflow.items.filter(item => item.type === 'message').length} messages
                    </Badge>
                    <Badge colorScheme="green" display="flex" alignItems="center">
                      <FaUsers style={{ marginRight: '4px' }} />
                      {workflow.contacts?.length || 0} contacts
                    </Badge>
                  </HStack>
                </VStack>
              </Box>
            ))}
          </SimpleGrid>
        </VStack>

        <Modal isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Confirm Deletion</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              Are you sure you want to delete the workflow "{selectedWorkflow?.name}"?
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="red" mr={3} onClick={() => {
                handleDeleteWorkflow(selectedWorkflow._id);
                onClose();
              }}>
                Delete
              </Button>
              <Button variant="ghost" onClick={onClose}>Cancel</Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Container>
    </>
  );
};

export default WorkflowsList;
