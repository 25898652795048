import React, { useState, useEffect } from 'react';
import {
  Box,
  Container,
  VStack,
  Text,
  Heading,
  Card,
  CardBody,
  Stack,
  Badge,
  Icon,
  HStack,
  Button,
  useColorModeValue,
  useToast,
  Skeleton,
  SkeletonText,
} from '@chakra-ui/react';
import { CheckCircleIcon, WarningIcon, InfoIcon, ArrowForwardIcon } from '@chakra-ui/icons';
import axios from 'axios';
import Header from '../components/Header';
import BrandRegistration from '../components/BrandRegistration';
import CampaignRegistration from '../components/CampaignRegistration';

const LoadingSkeleton = () => (
  <VStack spacing={6} align="stretch">
    {/* Status Message Skeleton */}
    <Skeleton height="60px" borderRadius="md" />
    
    {/* Registration Status Skeleton */}
    <Card>
      <CardBody>
        <Stack spacing={6}>
          <Box>
            <Skeleton height="20px" width="150px" mb={3} />
            <Card variant="outline" p={4}>
              <Stack spacing={3}>
                <HStack justify="space-between">
                  <SkeletonText noOfLines={1} width="120px" />
                  <SkeletonText noOfLines={1} width="150px" />
                </HStack>
                <HStack justify="space-between">
                  <SkeletonText noOfLines={1} width="80px" />
                  <Skeleton height="20px" width="80px" />
                </HStack>
              </Stack>
            </Card>
          </Box>
        </Stack>
      </CardBody>
    </Card>

    {/* Steps Skeleton */}
    <Card>
      <CardBody>
        <VStack spacing={6} align="stretch">
          <Box>
            <Skeleton height="24px" width="200px" mb={2} />
            <SkeletonText noOfLines={2} spacing={4} mb={4} />
            <Stack spacing={2} pl={4} mb={4}>
              <SkeletonText noOfLines={3} spacing={3} />
            </Stack>
            <Skeleton height="40px" width="200px" />
          </Box>
        </VStack>
      </CardBody>
    </Card>
  </VStack>
);

const DLC = () => {
  const [registration, setRegistration] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [showBrandForm, setShowBrandForm] = useState(false);
  const [showCampaignForm, setShowCampaignForm] = useState(false);

  const cardBg = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const toast = useToast();

  useEffect(() => {
    const fetchRegistration = async () => {
      try {
        const userInfo = localStorage.getItem('userInfo');
        if (!userInfo) {
          throw new Error('No user info found');
        }

        const { token } = JSON.parse(userInfo);
        const { data } = await axios.get('/api/10dlc/brand', {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setRegistration(data);
      } catch (error) {
        console.error('Error fetching registration:', error);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRegistration();
  }, []);

  useEffect(() => {
    const successType = sessionStorage.getItem('registrationSuccess');
    if (successType) {
      const messages = {
        brand: 'Brand registration has been submitted for review.',
        campaign: 'Campaign registration has been submitted for review.'
      };

      toast({
        title: `${successType.charAt(0).toUpperCase() + successType.slice(1)} Registration Submitted`,
        description: messages[successType],
        status: 'success',
        duration: 5000,
        isClosable: true,
      });

      // Clear the success message
      sessionStorage.removeItem('registrationSuccess');
    }
  }, [toast]);

  const getStatusBadge = (status) => {
    const colorScheme = {
      pending: 'yellow',
      approved: 'green',
      rejected: 'red'
    }[status];

    const icons = {
      pending: InfoIcon,
      approved: CheckCircleIcon,
      rejected: WarningIcon
    };

    const StatusIcon = icons[status];

    return (
      <HStack>
        <Icon as={StatusIcon} color={`${colorScheme}.500`} />
        <Badge colorScheme={colorScheme}>{status}</Badge>
      </HStack>
    );
  };

  const renderRegistrationStatus = () => {
    if (!registration?.brand) return null;

    return (
      <Card bg={cardBg} borderWidth="1px" borderColor={borderColor} shadow="sm">
        <CardBody>
          <Stack spacing={6}>
            <Box>
              <Heading size="sm" mb={3}>Brand Registration</Heading>
              <Card variant="outline" p={4}>
                <Stack spacing={3}>
                  <HStack justify="space-between">
                    <Text fontWeight="medium">Company Name</Text>
                    <Text>{registration.brand.companyName}</Text>
                  </HStack>
                  <HStack justify="space-between">
                    <Text fontWeight="medium">Status</Text>
                    {getStatusBadge(registration.brand.status)}
                  </HStack>
                </Stack>
              </Card>
            </Box>

            {registration.campaign && (
              <Box>
                <Heading size="sm" mb={3}>Campaign Registration</Heading>
                <Card variant="outline" p={4}>
                  <Stack spacing={3}>
                    <HStack justify="space-between">
                      <Text fontWeight="medium">Campaign Name</Text>
                      <Text>{registration.campaign.campaignName}</Text>
                    </HStack>
                    <HStack justify="space-between">
                      <Text fontWeight="medium">Status</Text>
                      {getStatusBadge(registration.campaign.status)}
                    </HStack>
                  </Stack>
                </Card>
              </Box>
            )}
          </Stack>
        </CardBody>
      </Card>
    );
  };

  const renderStatusMessage = () => {
    if (!registration?.brand) return null;

    const messages = {
      brandPending: (
        <Card bg="yellow.50" color="yellow.800" p={4}>
          <HStack>
            <Icon as={InfoIcon} />
            <Text>Your brand registration is pending approval.</Text>
          </HStack>
        </Card>
      ),
      brandRejected: (
        <Card bg="red.50" color="red.800" p={4}>
          <HStack>
            <Icon as={WarningIcon} />
            <Text>Your brand registration was rejected. Please contact support.</Text>
          </HStack>
        </Card>
      ),
      campaignPending: (
        <Card bg="yellow.50" color="yellow.800" p={4}>
          <HStack>
            <Icon as={InfoIcon} />
            <Text>Your campaign registration is pending approval.</Text>
          </HStack>
        </Card>
      ),
      campaignRejected: (
        <Card bg="red.50" color="red.800" p={4}>
          <HStack>
            <Icon as={WarningIcon} />
            <Text>Your campaign registration was rejected. Please contact support.</Text>
          </HStack>
        </Card>
      ),
      allApproved: (
        <Card bg="green.50" color="green.800" p={4}>
          <HStack>
            <Icon as={CheckCircleIcon} />
            <Text>All registrations approved! You can now send messages.</Text>
          </HStack>
        </Card>
      )
    };

    if (registration.brand.status === 'pending') return messages.brandPending;
    if (registration.brand.status === 'rejected') return messages.brandRejected;
    if (registration.brand.status === 'approved' && registration.campaign?.status === 'pending') return messages.campaignPending;
    if (registration.brand.status === 'approved' && registration.campaign?.status === 'rejected') return messages.campaignRejected;
    if (registration.brand.status === 'approved' && registration.campaign?.status === 'approved') return messages.allApproved;
    return null;
  };

  const renderInitialSteps = () => {
    if (registration?.brand) return null;
    
    return (
      <Card>
        <CardBody>
          <VStack spacing={6} align="stretch">
            <Box>
              <Heading size="md" mb={2}>Step 1: Brand Registration</Heading>
              <Text color="gray.600" mb={4}>
                Register your business brand for 10DLC messaging compliance. You'll need:
              </Text>
              <Stack spacing={2} pl={4} mb={4}>
                <Text>• Company's legal name</Text>
                <Text>• Tax ID (EIN)</Text>
                <Text>• Business address</Text>
              </Stack>
              <Button
                rightIcon={<ArrowForwardIcon />}
                colorScheme="blue"
                onClick={() => setShowBrandForm(true)}
              >
                Start Brand Registration
              </Button>
            </Box>
          </VStack>
        </CardBody>
      </Card>
    );
  };

  const renderCampaignStep = () => {
    if (registration?.campaign) return null;

    const isBrandApproved = registration?.brand?.status === 'approved';
    
    return (
      <Card>
        <CardBody>
          <VStack spacing={6} align="stretch">
            <Box>
              <Heading size="md" mb={2}>Step 2: Campaign Registration</Heading>
              <Text color="gray.600" mb={4}>
                Create a messaging campaign for your approved brand. You'll need:
              </Text>
              <Stack spacing={2} pl={4} mb={4}>
                <Text>• Campaign name</Text>
                <Text>• Campaign description</Text>
              </Stack>
              <Button
                rightIcon={<ArrowForwardIcon />}
                colorScheme="blue"
                onClick={() => setShowCampaignForm(true)}
                isDisabled={!isBrandApproved}
                _hover={{ cursor: !isBrandApproved ? 'not-allowed' : 'pointer' }}
              >
                {isBrandApproved ? 'Start Campaign Registration' : 'Waiting for Brand Approval'}
              </Button>
              {!isBrandApproved && registration?.brand && (
                <Text fontSize="sm" color="gray.500" mt={2}>
                  You can register a campaign once your brand is approved
                </Text>
              )}
            </Box>
          </VStack>
        </CardBody>
      </Card>
    );
  };

  return (
    <Box>
      <Header />
      <Container maxW="container.xl" py={5}>
        <VStack spacing={6} align="stretch">          
          {loading ? (
            <LoadingSkeleton />
          ) : (
            <VStack spacing={6} align="stretch">
              {renderStatusMessage()}
              {renderRegistrationStatus()}
              {!showBrandForm && renderInitialSteps()}
              {!showBrandForm && renderCampaignStep()}
              
              {(showBrandForm && !registration?.brand) && (
                <BrandRegistration 
                  onRegistrationComplete={(data) => {
                    setRegistration(data);
                    setShowBrandForm(false);
                  }} 
                />
              )}
              
              {(showCampaignForm && registration?.brand?.status === 'approved' && !registration?.campaign) && (
                <CampaignRegistration 
                  registration={registration}
                  onRegistrationComplete={(data) => {
                    setRegistration(data);
                    setShowCampaignForm(false);
                  }}
                />
              )}
            </VStack>
          )}
        </VStack>
      </Container>
    </Box>
  );
};

export default DLC; 