import React, { useState, useEffect } from 'react';
import {
  Box, IconButton, Menu, MenuButton, MenuList, MenuItem,
  Modal, ModalOverlay, ModalContent, ModalBody, ModalCloseButton,
  Button, FormControl, FormLabel, Input, VStack, HStack, Text, Wrap, WrapItem,
  Flex, SimpleGrid, useColorModeValue, Heading, Divider, Avatar, Badge, Card, CardHeader, CardBody,
  Tag, TagLabel, TagCloseButton
} from '@chakra-ui/react';
import { FaCommentDots, FaInfoCircle, FaTrashAlt, FaExchangeAlt, FaEllipsisH } from 'react-icons/fa';
import { EditIcon, PhoneIcon, EmailIcon, InfoIcon, CheckIcon, CloseIcon } from '@chakra-ui/icons';
import axios from 'axios';
import { getToken } from '../utils/auth';
import { toast } from 'react-toastify';
import PipelineSelectionModal from './PipelineSelectionModal';

const TagSelector = ({ availableTags = [], selectedTags = [], onTagChange }) => {
  const [searchTerm, setSearchTerm] = useState('');

  const handleTagClick = (tagId) => {
    const newSelectedTags = selectedTags.includes(tagId)
      ? selectedTags.filter(t => t !== tagId)
      : [...selectedTags, tagId];
    onTagChange(newSelectedTags);
  };

  const filteredTags = availableTags.filter(tag => 
    tag.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
    !selectedTags.includes(tag.id) // Exclude selected tags
  );

  return (
    <Box>
      <Input
        placeholder="Search tags..."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        mb={4}
      />
      <Flex direction="column">
        <Box mb={4}>
          <Text fontWeight="bold" mb={2}>Available Tags:</Text>
          <Wrap>
            {filteredTags.map(tag => (
              <WrapItem key={tag.id}>
                <Tag 
                  size="md" 
                  cursor="pointer"
                  onClick={() => handleTagClick(tag.id)}
                  backgroundColor={tag.color || 'gray.200'} // Apply tag color
                  color="white"
                >
                  <TagLabel>{tag.name}</TagLabel>
                </Tag>
              </WrapItem>
            ))}
          </Wrap>
        </Box>
        <Box>
          <Text fontWeight="bold" mb={2}>Added Tags:</Text>
          <Wrap>
            {selectedTags.map(tagId => {
              const tag = availableTags.find(t => t.id === tagId);
              return tag ? (
                <WrapItem key={tagId}>
                  <Tag 
                    size="md"
                    backgroundColor={tag.color || 'blue.500'} // Apply tag color
                    color="white"
                  >
                    <TagLabel>{tag.name}</TagLabel>
                    <TagCloseButton onClick={() => handleTagClick(tagId)} />
                  </Tag>
                </WrapItem>
              ) : null;
            })}
          </Wrap>
        </Box>
      </Flex>
    </Box>
  );
};

const OpportunityActions = ({ 
  contact, 
  onContactRemoved, 
  pipelines, 
  currentPipelineId, 
  onPipelineUpdated, 
  onOpenMessageView,
  onMenuOpen,
  onMenuClose 
}) => {
  const [isSwitchPipelineOpen, setIsSwitchPipelineOpen] = useState(false);
  const [isContactDetailsOpen, setIsContactDetailsOpen] = useState(false);
  const [selectedPipeline, setSelectedPipeline] = useState('');
  const [isUpdating, setIsUpdating] = useState(false);
  const [contactDetails, setContactDetails] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [editedContact, setEditedContact] = useState(null);
  const [availableTags, setAvailableTags] = useState([]);
  const [userTags, setUserTags] = useState([]); // Add state for tags

  const bgColor = useColorModeValue('white', 'gray.700');
  const cardBgColor = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const inputBgColor = useColorModeValue('white', 'gray.700');
  const dividerColor = useColorModeValue('gray.200', 'gray.600');

  useEffect(() => {
    if (isContactDetailsOpen && !contactDetails) {
      fetchContactDetails();
    }
  }, [isContactDetailsOpen]);

  useEffect(() => {
    const fetchTags = async () => {
      try {
        const token = getToken();
        const response = await axios.get('/api/tags', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setUserTags(response.data); // Store the entire tag objects
      } catch (error) {
        console.error('Error fetching tags:', error);
        toast.error('Failed to fetch tags');
      }
    };

    fetchTags();
  }, []);

  const fetchContactDetails = async () => {
    try {
      const token = getToken();
      const [contactResponse, tagsResponse] = await Promise.all([
        axios.get(`/api/contacts/${contact._id}`, {
          headers: { Authorization: `Bearer ${token}` }
        }),
        axios.get('/api/tags', {
          headers: { Authorization: `Bearer ${token}` }
        })
      ]);

      const contactData = {
        ...contactResponse.data,
        tags: Array.isArray(contactResponse.data.tags) 
          ? contactResponse.data.tags 
          : contactResponse.data.tags.split(',').map(tag => tag.trim())
      };

      setContactDetails(contactData);
      setEditedContact(contactData);
      setAvailableTags(tagsResponse.data.map(tag => tag.name));
    } catch (error) {
      console.error(`Error fetching contact details: ${error.message}\n${error.stack}`);
      toast.error('Failed to fetch contact details');
    }
  };

  const openContactDetails = () => {
    setIsContactDetailsOpen(true);
    fetchContactDetails(); // Fetch details every time the modal is opened
  };

  const closeContactDetails = () => {
    setIsContactDetailsOpen(false);
    setContactDetails(null); // Reset contact details
    setEditedContact(null);
    setEditMode(false);
  };

  const handleRemoveFromPipeline = async () => {
    if (isUpdating) return;
    setIsUpdating(true);

    const token = getToken();
    try {
      await axios.put(`/api/contacts/${contact._id}/opportunity`, { opportunityId: null }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      toast.success('Contact removed from pipeline');
      onContactRemoved(contact._id);
    } catch (error) {
      toast.error('Failed to remove contact from pipeline');
      console.error(`Error removing contact from pipeline: ${error.message}\n${error.stack}`);
    } finally {
      setIsUpdating(false);
    }
  };

  const handlePipelineChange = (pipelineId) => {
    setSelectedPipeline(pipelineId);
  };

  const confirmSwitchPipeline = async () => {
    if (isUpdating || !selectedPipeline) {
      toast.error('Please select a pipeline to switch to');
      return;
    }

    setIsUpdating(true);

    const token = getToken();
    try {
      await axios.put(`/api/contacts/${contact._id}/opportunity`, { opportunityId: selectedPipeline }, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      toast.success('Contact moved to new pipeline');
      onPipelineUpdated(contact, selectedPipeline);
      setIsSwitchPipelineOpen(false);
    } catch (error) {
      toast.error('Failed to move contact to new pipeline');
      console.error(`Error moving contact to new pipeline: ${error.message}\n${error.stack}`);
    } finally {
      setIsUpdating(false);
    }
  };

  const handleEdit = () => setEditMode(true);

  const handleSave = async () => {
    try {
      const token = getToken();
      const contactToSend = {
        ...editedContact,
        tags: editedContact.tags // Ensure tags are sent as an array of IDs
      };
      const response = await axios.put(`/api/contacts/${contactDetails._id}`, contactToSend, {
        headers: { Authorization: `Bearer ${token}` }
      });
      const updatedContact = {
        ...response.data,
        tags: editedContact.tags // Ensure tags remain as an array of IDs
      };
      setContactDetails(updatedContact);
      setEditedContact(updatedContact);
      setEditMode(false);
      toast.success('Contact updated successfully');
      closeContactDetails(); // Use the closeContactDetails function to close the modal
    } catch (error) {
      console.error(`Error updating contact: ${error.message}\n${error.stack}`);
      toast.error('Failed to update contact');
    }
  };

  const handleCancel = () => {
    setEditedContact(contactDetails);
    setEditMode(false);
  };

  const handleChange = (field, value) => setEditedContact({ ...editedContact, [field]: value });

  const handleTagsChange = (selectedTagIds) => setEditedContact({ ...editedContact, tags: selectedTagIds });

  const fields = [
    { name: 'firstName', label: 'First Name', icon: InfoIcon },
    { name: 'lastName', label: 'Last Name', icon: InfoIcon },
    { name: 'phone', label: 'Phone', icon: PhoneIcon },
    { name: 'email', label: 'Email', icon: EmailIcon },
    { name: 'address', label: 'Address', icon: InfoIcon },
    { name: 'city', label: 'City', icon: InfoIcon },
    { name: 'state', label: 'State', icon: InfoIcon },
  ];

  return (
    <Box 
      display="flex" 
      justifyContent="space-around"
      position="relative"
      zIndex={2}
    >
      <IconButton
        icon={<FaCommentDots />}
        onClick={() => onOpenMessageView(contact)}
        aria-label="Open Message View"
      />
      <IconButton
        icon={<FaInfoCircle />}
        onClick={openContactDetails}
        aria-label="View Contact Details"
      />
      <Box
        position="relative"
        zIndex={1200}
      >
        <Menu
          onOpen={onMenuOpen}
          onClose={onMenuClose}
        >
          <MenuButton as={IconButton} icon={<FaEllipsisH />} aria-label="More Actions" />
          <MenuList
            shadow="lg"
            bg={useColorModeValue('white', 'gray.700')}
            borderWidth="1px"
            borderColor={useColorModeValue('gray.200', 'gray.600')}
            transform="none"
            transition="opacity 0.15s ease"
          >
            <MenuItem 
              icon={<FaTrashAlt />} 
              onClick={handleRemoveFromPipeline} 
              isDisabled={isUpdating}
              _hover={{ bg: useColorModeValue('gray.100', 'gray.600') }}
            >
              Remove from Pipeline
            </MenuItem>
            <MenuItem 
              icon={<FaExchangeAlt />} 
              onClick={() => setIsSwitchPipelineOpen(true)} 
              isDisabled={isUpdating}
              _hover={{ bg: useColorModeValue('gray.100', 'gray.600') }}
            >
              Switch Pipeline
            </MenuItem>
          </MenuList>
        </Menu>
      </Box>
      <PipelineSelectionModal
        isOpen={isSwitchPipelineOpen}
        onClose={() => setIsSwitchPipelineOpen(false)}
        onPipelineChange={handlePipelineChange}
        pipelines={pipelines.filter(pipeline => pipeline._id !== currentPipelineId)}
        onConfirm={confirmSwitchPipeline}
        isDisabled={isUpdating}
      />
      <Modal isOpen={isContactDetailsOpen} onClose={closeContactDetails} size="2xl">
        <ModalOverlay />
        <ModalContent bg={bgColor} mt={10}>
          <ModalCloseButton 
            position="absolute"
            right={3}
            top={3}
            zIndex={1}
            bg={cardBgColor}
            borderRadius="full"
          />
          <ModalBody p={6}>
            {contactDetails && (
              <Card bg={cardBgColor} shadow="md" borderRadius="lg" overflow="hidden">
                <CardHeader pt={10}>
                  <Flex justify="space-between" align="center">
                    <HStack spacing={4}>
                      <Avatar size="lg" name={`${contactDetails.firstName} ${contactDetails.lastName}`} src={contactDetails.avatar} />
                      <Box>
                        <Heading size="md" color={textColor}>
                          {contactDetails.firstName} {contactDetails.lastName}
                        </Heading>
                        <Text color="gray.500">{contactDetails.email}</Text>
                      </Box>
                    </HStack>
                    {!editMode && (
                      <IconButton
                        aria-label="Edit contact"
                        icon={<EditIcon />}
                        onClick={handleEdit}
                        colorScheme="blue"
                        variant="ghost"
                      />
                    )}
                  </Flex>
                </CardHeader>
                <Divider borderColor={dividerColor} />
                <CardBody>
                  <SimpleGrid columns={{ base: 1, md: 2 }} spacing={4}>
                    {fields.map((field) => (
                      <FormControl key={field.name}>
                        <FormLabel color="gray.500" fontSize="sm">
                          <HStack spacing={1}>
                            <field.icon />
                            <Text>{field.label}</Text>
                          </HStack>
                        </FormLabel>
                        {editMode ? (
                          <Input
                            value={editedContact[field.name]}
                            onChange={(e) => handleChange(field.name, e.target.value)}
                            bg={cardBgColor}
                            size="sm"
                          />
                        ) : (
                          <Text fontSize="sm" color={textColor}>{contactDetails[field.name]}</Text>
                        )}
                      </FormControl>
                    ))}
                  </SimpleGrid>
                  
                  <VStack spacing={4} mt={6} align="stretch">
                    <FormControl>
                      <FormLabel color="gray.500" fontSize="sm">Tags</FormLabel>
                      {editMode ? (
                        <TagSelector
                          availableTags={userTags.map(tag => ({ id: tag._id, name: tag.name, color: tag.color }))}
                          selectedTags={editedContact.tags}
                          onTagChange={handleTagsChange}
                        />
                      ) : (
                        <Wrap>
                          {contactDetails.tags.map(tagId => {
                            const tag = userTags.find(t => t._id === tagId);
                            return tag ? (
                              <WrapItem key={tagId}>
                                <Tag
                                  size="md"
                                  backgroundColor={tag.color || 'blue.500'}
                                  color="white"
                                >
                                  {tag.name}
                                </Tag>
                              </WrapItem>
                            ) : null;
                          })}
                        </Wrap>
                      )}
                    </FormControl>
                  </VStack>

                  {editMode && (
                    <HStack justify="flex-end" spacing={4} mt={6}>
                      <Button onClick={handleCancel} variant="outline" leftIcon={<CloseIcon />} size="sm">
                        Cancel
                      </Button>
                      <Button onClick={handleSave} colorScheme="green" leftIcon={<CheckIcon />} size="sm">
                        Save
                      </Button>
                    </HStack>
                  )}
                </CardBody>
              </Card>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default OpportunityActions;
