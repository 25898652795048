import React, { useEffect, useState } from 'react';
import {
  Box,
  Heading,
  SimpleGrid,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  useColorModeValue,
  Icon,
  Skeleton,
  Container,
  Text,
} from '@chakra-ui/react';
import { FaUsers, FaStream, FaPaperPlane, FaBullseye, FaRocket, FaClock } from 'react-icons/fa';
import Header from '../components/Header';
import { get } from '../utils/api';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js';
import StatCard from '../components/StatCard';

// Register ChartJS components
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

const Dashboard = () => {
  const [stats, setStats] = useState({
    contactCount: 0,
    pendingDrips: 0,
    dripsSentToday: 0,
    remainingToday: 0,
    opportunityCount: 0,
    runningWorkflows: 0,
  });
  const [isLoading, setIsLoading] = useState(true);
  const [dailyStats, setDailyStats] = useState({ sent: [], scheduled: [] });
  const bgColor = useColorModeValue('gray.50', 'gray.900');

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Messages Sent (Past 6 Days)'
      }
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          precision: 0
        }
      }
    }
  };

  const chartData = {
    labels: dailyStats.sent.map(stat => {
      const date = new Date(stat.date);
      return date.toLocaleDateString('en-US', { 
        month: 'short', 
        day: 'numeric' 
      });
    }),
    datasets: [
      {
        label: 'Messages Sent',
        data: dailyStats.sent.map(stat => stat.count),
        borderColor: 'rgb(53, 162, 235)',
        backgroundColor: 'rgba(53, 162, 235, 0.5)',
        tension: 0.3
      }
    ]
  };

  useEffect(() => {
    const fetchStats = async () => {
      try {
        setIsLoading(true);
        const [contacts, drips, opportunities, workflows] = await Promise.all([
          get('/api/analytics/contacts/count'),
          get('/api/analytics/drips/stats'),
          get('/api/analytics/opportunities/count'),
          get('/api/analytics/workflows/stats'),
        ]);

        setStats({
          contactCount: contacts.count,
          pendingDrips: drips.pending,
          dripsSentToday: drips.sentToday,
          remainingToday: drips.remainingToday,
          opportunityCount: opportunities.count,
          runningWorkflows: workflows.running,
        });
        setDailyStats(drips.dailyStats);
      } catch (error) {
        console.error('Error fetching dashboard stats:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchStats();
    // Set up auto-refresh every 5 minutes
    const interval = setInterval(fetchStats, 300000);
    return () => clearInterval(interval);
  }, []);

  return (
    <>
      <Header />
      <Box minH="100vh" bg={bgColor}>
        <Container maxW="7xl" py={8}>
          <SimpleGrid 
            columns={{ base: 1, md: 2, lg: 3 }} 
            spacing={6}
            transition="all 0.2s"
          >
            <StatCard
              title="Total Contacts"
              value={stats.contactCount}
              icon={FaUsers}
              isLoading={isLoading}
              helpText="Active contacts in your CRM"
            />
            <StatCard
              title="Pending Messages"
              value={stats.pendingDrips}
              icon={FaStream}
              isLoading={isLoading}
              helpText="Total messages waiting to be sent"
            />
            <StatCard
              title="Messages Sent Today"
              value={stats.dripsSentToday}
              icon={FaPaperPlane}
              isLoading={isLoading}
              helpText="Successfully delivered today"
            />
            <StatCard
              title="Remaining Today"
              value={stats.remainingToday}
              icon={FaClock}
              isLoading={isLoading}
              helpText="Messages that will be sent today"
            />
            <StatCard
              title="Pipeline"
              value={stats.opportunityCount}
              icon={FaBullseye}
              isLoading={isLoading}
              helpText="Opportunities in your pipeline"
            />
            <StatCard
              title="Active Workflows"
              value={stats.runningWorkflows}
              icon={FaRocket}
              isLoading={isLoading}
              helpText="Currently running automations"
            />
          </SimpleGrid>
          
          <Box mt={8} p={6} bg={useColorModeValue('white', 'gray.800')} borderRadius="xl" boxShadow="lg">
            <Line options={chartOptions} data={chartData} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default Dashboard;
