import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import {
  Box,
  Flex,
  IconButton,
  Text,
  useColorModeValue,
  Avatar,
  HStack,
  Divider,
} from '@chakra-ui/react';
import { 
  HamburgerIcon, 
  SettingsIcon,
} from '@chakra-ui/icons';

const getPageTitle = (pathname) => {
  switch (pathname) {
    case '/dashboard':
      return 'Dashboard';
    case '/conversations':
      return 'Conversations';
    case '/contacts':
      return 'Contacts';
    case '/workflows':
      return 'Workflows';
    case '/opportunities':
      return 'Opportunities';
    case '/tags':
      return 'Tags';
    case '/responses':
      return 'Quick Responses';
    case '/uploads':
      return 'Uploads';
    case '/billing':
      return 'Billing';
    case '/phone-numbers':
      return 'Phone Numbers';
    case '/settings':
      return 'Settings';
    case '/10DLC':
      return '10DLC Registration';
    default:
      if (pathname.startsWith('/workflows/edit/')) {
        return 'Edit Workflow';
      } else if (pathname.startsWith('/workflows/new')) {
        return 'New Workflow';
      } else if (pathname.startsWith('/contacts/')) {
        return 'Contact Profile';
      }
      return 'Paladin CRM';
  }
};

const Header = ({ onSignOut }) => {
  const location = useLocation();
  const navigate = useNavigate();
  const bgColor = useColorModeValue('white', 'gray.800');
  const borderColor = useColorModeValue('gray.200', 'gray.700');
  const textColor = useColorModeValue('gray.700', 'gray.200');
  const iconColor = useColorModeValue('gray.600', 'gray.400');

  const userData = localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : null;

  const handleSettingsClick = () => {
    navigate('/settings');
  };

  return (
    <Box px={4} py={2}>
      <Box
        bg={bgColor}
        px={6}
        borderRadius="xl"
        borderWidth="1px"
        borderColor={borderColor}
        boxShadow="sm"
        transition="all 0.2s"
        _hover={{ boxShadow: 'md' }}
      >
        <Flex h={14} alignItems="center" justifyContent="space-between">
          <HStack spacing={8} alignItems="center">
            <IconButton
              size="sm"
              icon={<HamburgerIcon />}
              aria-label="Open Menu"
              display={{ base: 'inherit', md: 'none' }}
              variant="ghost"
              color={iconColor}
              _hover={{ bg: useColorModeValue('gray.100', 'gray.700') }}
            />
            <Text 
              fontSize="lg" 
              fontWeight="bold"
              color={textColor}
              letterSpacing="tight"
            >
              {getPageTitle(location.pathname)}
            </Text>
          </HStack>

          <Flex alignItems="center">
            <HStack spacing={5}>
              <IconButton
                size="sm"
                icon={<SettingsIcon />}
                aria-label="Settings"
                variant="ghost"
                color={iconColor}
                onClick={handleSettingsClick}
                _hover={{ bg: useColorModeValue('gray.100', 'gray.700') }}
              />
              
              <Divider orientation="vertical" h="32px" />
              
              <HStack spacing={3}>
                <Avatar
                  size="sm"
                  name={userData?.name || ''}
                  src={userData?.avatar}
                />
                <Text 
                  fontSize="sm" 
                  fontWeight="medium" 
                  display={{ base: 'none', md: 'block' }}
                  color={textColor}
                >
                  {userData?.name || ''}
                </Text>
              </HStack>
            </HStack>
          </Flex>
        </Flex>
      </Box>
    </Box>
  );
};

export default Header;