import { getToken } from './auth';

export const getAuthHeaders = () => {
  try {
    const token = getToken();
    if (!token) {
      console.warn('No token found, returning empty headers');
      return {};
    }
    return {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
  } catch (error) {
    console.error('Error getting auth headers:', error.message, error.stack);
    return {};
  }
};