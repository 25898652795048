import React, { useState, useEffect, useCallback } from 'react';
import { Box, VStack, Text, Heading, Tag, HStack, Divider, Icon, useColorModeValue, IconButton, Input, Button, useToast, Wrap, WrapItem, Menu, MenuButton, MenuList, MenuItem, Popover, PopoverTrigger, PopoverContent, PopoverBody, PopoverHeader, PopoverFooter, PopoverArrow, PopoverCloseButton, Tooltip, Skeleton, Select } from '@chakra-ui/react';
import { PhoneIcon, EmailIcon, StarIcon, EditIcon, CheckIcon, CloseIcon, AddIcon, ChevronDownIcon } from '@chakra-ui/icons';
import { FaCity, FaMapMarkerAlt, FaBriefcase, FaRobot } from 'react-icons/fa';
import { BiWorld } from 'react-icons/bi';
import axios from 'axios';
import { toast } from 'react-toastify';

const ContactInfo = ({ contact, onSave, userTags }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [editedContact, setEditedContact] = useState(null);
  const [workflows, setWorkflows] = useState([]);
  const [selectedWorkflow, setSelectedWorkflow] = useState(null);
  const [isWorkflowPopoverOpen, setIsWorkflowPopoverOpen] = useState(false);
  const [activeWorkflowDrips, setActiveWorkflowDrips] = useState([]);
  const [opportunities, setOpportunities] = useState([]);
  const [isLoadingOpportunities, setIsLoadingOpportunities] = useState(true);

  const bgColor = useColorModeValue('white', 'gray.700');
  const textColor = useColorModeValue('gray.800', 'white');
  const mutedTextColor = useColorModeValue('gray.600', 'gray.400');
  const iconColor = useColorModeValue('blue.500', 'blue.300');
  const dividerColor = useColorModeValue('gray.200', 'gray.600');

  // Add these new color values
  const workflowBorderColor = useColorModeValue('gray.200', 'gray.600');
  const workflowBgColor = useColorModeValue('gray.50', 'gray.700');
  const workflowHoverBorderColor = useColorModeValue('blue.200', 'blue.500');
  const workflowIconColor = useColorModeValue('blue.500', 'blue.200');
  const cancelHoverBgColor = useColorModeValue('red.50', 'red.900');

  // Add loading states
  const [isLoadingWorkflowData, setIsLoadingWorkflowData] = useState(true);

  const scrollbarTrackColor = useColorModeValue('#f1f1f1', '#2D3748');
  const scrollbarThumbColor = useColorModeValue('#888', '#4A5568');
  const scrollbarThumbHoverColor = useColorModeValue('#555', '#2D3748');

  useEffect(() => {
    if (contact) {
      setEditedContact({
        ...contact,
        tags: Array.isArray(contact.tags) ? contact.tags : []
      });
    }
  }, [contact]);

  useEffect(() => {
    if (contact) {
      console.log('Contact data received:', contact);
      console.log('Tags:', contact.tags);
    }
  }, [contact]);

  // Modify the workflow fetch effects to track loading state
  useEffect(() => {
    const fetchWorkflowData = async () => {
      setIsLoadingWorkflowData(true);
      try {
        const token = JSON.parse(localStorage.getItem('userInfo')).token;
        
        // Fetch both workflows and active drips in parallel
        const [workflowsResponse, activeDripsResponse] = await Promise.all([
          axios.get('/api/workflows', {
            headers: { Authorization: `Bearer ${token}` }
          }),
          axios.get(`/api/individual-drips/contact/${contact._id}`, {
            headers: { Authorization: `Bearer ${token}` }
          })
        ]);

        setWorkflows(workflowsResponse.data);
        setActiveWorkflowDrips(activeDripsResponse.data);
      } catch (error) {
        console.error('Error fetching workflow data:', error);
        toast({
          title: 'Error',
          description: 'Failed to fetch workflow data',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      } finally {
        setIsLoadingWorkflowData(false);
      }
    };

    if (contact?._id) {
      fetchWorkflowData();
    }
  }, [contact?._id, toast]);

  useEffect(() => {
    const fetchOpportunities = async () => {
      try {
        const token = JSON.parse(localStorage.getItem('userInfo')).token;
        const response = await axios.get('/api/opportunities', {
          headers: { Authorization: `Bearer ${token}` }
        });
        setOpportunities(response.data);
      } catch (error) {
        console.error('Error fetching opportunities:', error);
        toast.error('Failed to fetch opportunities');
      } finally {
        setIsLoadingOpportunities(false);
      }
    };

    fetchOpportunities();
  }, []);

  const handleAddToWorkflow = async () => {
    if (!selectedWorkflow) return;

    try {
      const token = JSON.parse(localStorage.getItem('userInfo')).token;
      await axios.post('/api/individual-drips', {
        workflowId: selectedWorkflow._id,
        contactId: contact._id
      }, {
        headers: { Authorization: `Bearer ${token}` }
      });

      toast.success(`Added contact to workflow: ${selectedWorkflow.name}`);

      // Refresh active drips
      const response = await axios.get(`/api/individual-drips/contact/${contact._id}`, {
        headers: { Authorization: `Bearer ${token}` }
      });
      setActiveWorkflowDrips(response.data);

    } catch (error) {
      console.error('Error adding contact to workflow:', error);
      toast.error(error.response?.data?.message || 'Failed to add contact to workflow');
    }

    setIsWorkflowPopoverOpen(false);
    setSelectedWorkflow(null);
  };

  const handleCancelDrip = async (dripId) => {
    try {
      const token = JSON.parse(localStorage.getItem('userInfo')).token;
      await axios.put(`/api/individual-drips/${dripId}/cancel`, {}, {
        headers: { Authorization: `Bearer ${token}` }
      });

      // Remove from active drips
      setActiveWorkflowDrips(prev => prev.filter(drip => drip._id !== dripId));

      toast.success('Workflow cancelled successfully');
    } catch (error) {
      console.error('Error cancelling drip:', error);
      toast.error('Failed to cancel workflow');
    }
  };

  const handleOpportunityChange = async (opportunityId) => {
    try {
      const token = JSON.parse(localStorage.getItem('userInfo')).token;
      await axios.put(
        `/api/contacts/${contact._id}/opportunity`,
        { opportunityId },
        { headers: { Authorization: `Bearer ${token}` }}
      );
      
      // Update local state through the onSave callback
      onSave({
        ...contact,
        opportunity: opportunityId
      });
      
    } catch (error) {
      console.error('Error updating opportunity:', error);
      toast.error('Failed to update opportunity');
    }
  };

  if (!contact) {
    return (
      <Box p={4} textAlign="center">
        <Text color={mutedTextColor}>Select a contact to view information</Text>
      </Box>
    );
  }

  const displayName = contact.firstName || contact.lastName
    ? `${contact.firstName || ''} ${contact.lastName || ''}`.trim()
    : contact.phone;

  const contactDetails = [
    { key: 'phone', icon: PhoneIcon, label: 'Phone' },
    { key: 'email', icon: EmailIcon, label: 'Email' },
    { key: 'address', icon: FaMapMarkerAlt, label: 'Address' },
    { key: 'city', icon: FaCity, label: 'City' },
    { key: 'state', icon: BiWorld, label: 'State' },
    { key: 'occupation', icon: FaBriefcase, label: 'Occupation' },
  ];

  const handleEdit = () => setIsEditing(true);

  const handleCancel = () => {
    setEditedContact(contact);
    setIsEditing(false);
  };

  const handleSave = () => {
    if (typeof onSave === 'function') {
      // Ensure tags is an array before saving
      const contactToSave = {
        ...editedContact,
        tags: Array.isArray(editedContact.tags) ? editedContact.tags : []
      };
      console.log('Saving contact with data:', contactToSave);
      onSave(contactToSave);
      
      setEditedContact(contactToSave);
      setIsEditing(false);
    } else {
      console.error('onSave is not a function');
      toast({
        title: "Error",
        description: "Unable to save changes. Please try again later.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const handleChange = (key, value) => {
    setEditedContact(prev => ({ ...prev, [key]: value }));
  };

  const handleTagChange = (e) => {
    // Only process actual tags, not status fields
    const tagNames = [...new Set(
      e.target.value.split(',')
        .map(tag => tag.trim())
        .filter(Boolean)
        .filter(tag => tag !== 'unread' && tag !== 'starred') // Prevent status fields from being added as tags
    )];
    
    setEditedContact(prev => ({ 
      ...prev, 
      tags: tagNames
    }));
  };

  const handleAddTag = (tagId) => {
    console.log('Adding tag:', tagId);
    setEditedContact(prev => ({
      ...prev,
      tags: [...new Set([...(prev.tags || []), tagId])] // Store as ObjectIDs
    }));
  };

  const handleRemoveTag = (tagId) => {
    console.log('Removing tag:', tagId);
    setEditedContact(prev => ({
      ...prev,
      tags: (prev.tags || []).filter(tag => tag !== tagId)
    }));
  };

  const renderTagsSection = () => (
    <VStack align="start" width="100%" spacing={2}>
      <Text fontWeight="bold" color={textColor}>Tags:</Text>
      {isEditing ? (
        <VStack align="start" width="100%" spacing={4}>
          {/* Selected Tags */}
          <Box width="100%">
            <Text fontSize="sm" mb={2} color={mutedTextColor}>Selected Tags:</Text>
            <Wrap spacing={2}>
              {editedContact.tags?.map(tagId => {
                const tag = Object.values(userTags).find(t => t._id === tagId);
                return tag ? (
                  <WrapItem key={tagId}>
                    <Tag
                      size="md"
                      backgroundColor={tag.color || 'blue.500'}
                      color="white"
                    >
                      <HStack spacing={2}>
                        <Text>{tag.name}</Text>
                        <CloseIcon 
                          cursor="pointer" 
                          boxSize={2} 
                          onClick={() => handleRemoveTag(tagId)}
                        />
                      </HStack>
                    </Tag>
                  </WrapItem>
                ) : null;
              })}
            </Wrap>
          </Box>
          
          {/* Available Tags */}
          <Box width="100%">
            <Text fontSize="sm" mb={2} color={mutedTextColor}>Available Tags:</Text>
            <Wrap spacing={2}>
              {Object.values(userTags)
                .filter(tag => !editedContact.tags?.includes(tag._id))
                .map(tag => (
                  <WrapItem key={tag._id}>
                    <Button
                      size="sm"
                      leftIcon={<AddIcon boxSize={3} />}
                      onClick={() => handleAddTag(tag._id)}
                      backgroundColor={tag.color || 'blue.500'}
                      color="white"
                      _hover={{
                        opacity: 0.8
                      }}
                    >
                      {tag.name}
                    </Button>
                  </WrapItem>
                ))}
            </Wrap>
          </Box>
        </VStack>
      ) : (
        <Wrap spacing={2}>
          {contact.tags?.map(tagId => {
            const tag = Object.values(userTags).find(t => t._id === tagId);
            return tag ? (
              <WrapItem key={tagId}>
                <Tag
                  size="md"
                  backgroundColor={tag.color || 'blue.500'}
                  color="white"
                >
                  {tag.name}
                </Tag>
              </WrapItem>
            ) : null;
          })}
        </Wrap>
      )}
    </VStack>
  );

  const renderWorkflowSection = () => (
    <VStack align="start" width="100%" spacing={3}>
      <HStack justify="space-between" width="100%" mb={2}>
        <Text fontWeight="bold" color={textColor}>Active Workflows</Text>
        {isLoadingWorkflowData ? (
          <Skeleton height="32px" width="140px" />
        ) : (
          <Tooltip 
            label="Contact must complete or be removed from current workflow before being added to a new one"
            isDisabled={activeWorkflowDrips.length === 0}
            hasArrow
          >
            <Box>
              <Popover
                isOpen={isWorkflowPopoverOpen && activeWorkflowDrips.length === 0}
                onClose={() => setIsWorkflowPopoverOpen(false)}
                placement="left-start"
                strategy="fixed"
                modifiers={[
                  {
                    name: 'preventOverflow',
                    options: {
                      boundary: 'viewport',
                      padding: 12
                    }
                  }
                ]}
              >
                <PopoverTrigger>
                  <Button
                    size="sm"
                    leftIcon={<FaRobot />}
                    colorScheme="blue"
                    variant="outline"
                    onClick={() => !activeWorkflowDrips.length && setIsWorkflowPopoverOpen(true)}
                    isDisabled={activeWorkflowDrips.length > 0}
                    opacity={activeWorkflowDrips.length > 0 ? 0.6 : 1}
                    cursor={activeWorkflowDrips.length > 0 ? 'not-allowed' : 'pointer'}
                  >
                    Add to Workflow
                  </Button>
                </PopoverTrigger>
                <PopoverContent width="250px">
                  <PopoverArrow />
                  <PopoverCloseButton />
                  <PopoverHeader fontWeight="medium">Select Workflow</PopoverHeader>
                  <PopoverBody>
                    <VStack align="stretch" spacing={2} maxHeight="200px" overflowY="auto">
                      {workflows
                        .filter(workflow => workflow.status === 'published') // Filter for published workflows only
                        .map(workflow => (
                          <Button
                            key={workflow._id}
                            size="sm"
                            justifyContent="flex-start"
                            variant={selectedWorkflow?._id === workflow._id ? "solid" : "ghost"}
                            colorScheme="blue"
                            onClick={() => setSelectedWorkflow(workflow)}
                          >
                            {workflow.name}
                          </Button>
                        ))}
                    </VStack>
                  </PopoverBody>
                  <PopoverFooter>
                    <Button
                      size="sm"
                      width="100%"
                      colorScheme="blue"
                      isDisabled={!selectedWorkflow}
                      onClick={handleAddToWorkflow}
                    >
                      Add to Workflow
                    </Button>
                  </PopoverFooter>
                </PopoverContent>
              </Popover>
            </Box>
          </Tooltip>
        )}
      </HStack>

      {isLoadingWorkflowData ? (
        <Skeleton height="48px" borderRadius="md" width="100%" />
      ) : (
        activeWorkflowDrips.length > 0 ? (
          <VStack align="stretch" width="100%" spacing={2}>
            {activeWorkflowDrips.map(drip => (
              <HStack 
                key={drip._id} 
                justify="space-between" 
                p={3}
                borderWidth="1px" 
                borderRadius="md"
                borderColor={workflowBorderColor}
                bg={workflowBgColor}
                _hover={{
                  borderColor: workflowHoverBorderColor,
                  transition: 'all 0.2s'
                }}
              >
                <Text fontWeight="medium">
                  {drip.workflow.name}
                </Text>
                <IconButton
                  size="sm"
                  icon={<CloseIcon />}
                  variant="ghost"
                  colorScheme="red"
                  onClick={() => handleCancelDrip(drip._id)}
                  aria-label="Cancel workflow"
                  _hover={{
                    bg: cancelHoverBgColor
                  }}
                />
              </HStack>
            ))}
          </VStack>
        ) : (
          <Text color={mutedTextColor} fontSize="sm" fontStyle="italic">
            No active workflows
          </Text>
        )
      )}
    </VStack>
  );

  const renderOpportunitySection = () => (
    <VStack align="start" width="100%" spacing={3}>
      <HStack justify="space-between" width="100%" mb={2}>
        <Text fontWeight="bold" color={textColor}>Opportunity</Text>
      </HStack>

      {isLoadingOpportunities ? (
        <Skeleton height="40px" />
      ) : (
        <Select
          value={contact.opportunity || ''}
          onChange={(e) => handleOpportunityChange(e.target.value)}
          placeholder="Select an opportunity"
        >
          <option value="">No Opportunity</option>
          {opportunities.map(opportunity => (
            <option key={opportunity._id} value={opportunity._id}>
              {opportunity.name}
            </option>
          ))}
        </Select>
      )}
    </VStack>
  );

  // Add this new render function after renderWorkflowSection
  const renderPreviousWorkflowsSection = () => (
    <VStack align="start" width="100%" spacing={3}>
      <Text fontWeight="bold" color={textColor}>Previous Workflows</Text>
      
      {isLoadingWorkflowData ? (
        <Skeleton height="48px" borderRadius="md" width="100%" />
      ) : (
        contact.workflowHistory?.length > 0 ? (
          <VStack align="stretch" width="100%" spacing={2}>
            {contact.workflowHistory.map(workflowId => {
              const workflow = workflows.find(w => w._id === workflowId);
              return workflow ? (
                <HStack 
                  key={workflowId}
                  p={3}
                  borderWidth="1px"
                  borderRadius="md"
                  borderColor={workflowBorderColor}
                  bg={workflowBgColor}
                >
                  <Text fontSize="sm">
                    {workflow.name}
                  </Text>
                </HStack>
              ) : null;
            })}
          </VStack>
        ) : (
          <Text color={mutedTextColor} fontSize="sm" fontStyle="italic">
            No previous workflows
          </Text>
        )
      )}
    </VStack>
  );

  return (
    <Box height="100%" bg={bgColor} borderRadius="xl">
      <Box 
        p={4} 
        height="100%" 
        position="relative"
        overflowY="auto"
        sx={{
          '&::-webkit-scrollbar': {
            width: '6px',
          },
          '&::-webkit-scrollbar-track': {
            background: scrollbarTrackColor,
          },
          '&::-webkit-scrollbar-thumb': {
            background: scrollbarThumbColor,
            borderRadius: '6px',
          },
          '&::-webkit-scrollbar-thumb:hover': {
            background: scrollbarThumbHoverColor,
          },
        }}
      >
        <VStack align="start" spacing={6}>
          {/* Contact name and edit button */}
          <HStack justify="space-between" width="100%" align="start">
            <VStack align="start" spacing={1} maxW="calc(100% - 40px)">
              {isEditing ? (
                // Edit mode - show input fields
                <VStack align="start" width="100%" spacing={2}>
                  <Input
                    value={editedContact.firstName || ''}
                    onChange={(e) => handleChange('firstName', e.target.value)}
                    placeholder="First Name"
                    size="lg"
                    fontWeight="bold"
                  />
                  <Input
                    value={editedContact.lastName || ''}
                    onChange={(e) => handleChange('lastName', e.target.value)}
                    placeholder="Last Name"
                    size="lg"
                    fontWeight="bold"
                  />
                </VStack>
              ) : (
                // View mode - show text
                <Heading as="h2" size="lg" color={textColor} noOfLines={1}>
                  {displayName.length > 30 
                    ? `${displayName.slice(0, 30)}...` 
                    : displayName}
                </Heading>
              )}
            </VStack>
            {!isEditing && (
              <IconButton
                icon={<EditIcon />}
                aria-label="Edit contact"
                onClick={handleEdit}
                colorScheme="blue"
                size="sm"
                flexShrink={0}
              />
            )}
          </HStack>

          <Divider borderColor={dividerColor} />

          {/* Contact details */}
          <VStack align="start" spacing={4} width="100%">
            <Wrap spacing={4} width="100%">
              {contactDetails.map(({ key, icon, label }) => (
                <WrapItem key={key} minW="45%" flexGrow={1}>
                  <HStack width="100%">
                    <Box color={iconColor}>
                      <Icon as={icon} boxSize={5} />
                    </Box>
                    {isEditing ? (
                      <Input
                        value={editedContact[key] || ''}
                        onChange={(e) => handleChange(key, e.target.value)}
                        placeholder={label}
                      />
                    ) : (
                      <Text flex={1} color={textColor}>
                        {contact[key] || 'N/A'}
                      </Text>
                    )}
                  </HStack>
                </WrapItem>
              ))}
            </Wrap>
          </VStack>

          <Divider borderColor={dividerColor} />
          
          {/* Reordered sections */}
          {renderOpportunitySection()}
          <Divider borderColor={dividerColor} />
          
          {renderWorkflowSection()}
          {renderPreviousWorkflowsSection()}
          <Divider borderColor={dividerColor} />
          
          <VStack align="start" width="100%" spacing={2}>
            {renderTagsSection()}
          </VStack>

          {/* Save/Cancel buttons when editing */}
          {isEditing && (
            <HStack spacing={2} width="100%" justify="flex-end">
              <Button leftIcon={<CloseIcon />} onClick={handleCancel} size="sm">
                Cancel
              </Button>
              <Button leftIcon={<CheckIcon />} onClick={handleSave} colorScheme="blue" size="sm">
                Save
              </Button>
            </HStack>
          )}
        </VStack>
      </Box>
    </Box>
  );
};

export default ContactInfo;
