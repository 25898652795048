import { useEffect } from 'react';
import { EventSourcePolyfill } from 'event-source-polyfill';

class SSEManager {
  constructor() {
    this.eventSource = null;
    this.listeners = {};
    this.reconnectTimeout = null;
    this.backendUrl = process.env.REACT_APP_API_URL || '/api';
    this.maxReconnectAttempts = 5;
    this.reconnectAttempts = 0;
    this.reconnectDelay = 5000;
    this.isConnecting = false;
  }

  connect() {
    if (this.isConnecting) {
      console.log('Connection already in progress');
      return;
    }

    console.log('SSE connect called');
    if (!this.eventSource || this.eventSource.readyState === EventSource.CLOSED) {
      this.isConnecting = true;
      
      const userInfo = localStorage.getItem('userInfo');
      if (!userInfo) {
        console.error('No userInfo found');
        return;
      }

      const { token } = JSON.parse(userInfo);
      if (!token) {
        console.error('No authentication token found in userInfo');
        return;
      }

      console.log('Attempting to connect to SSE');
      
      try {
        const baseUrl = this.backendUrl.endsWith('/api') 
          ? this.backendUrl 
          : `${this.backendUrl}/api`;
        
        const url = new URL(`${baseUrl}/sse`, window.location.origin);
        
        this.eventSource = new EventSourcePolyfill(url.toString(), {
          withCredentials: true,
          headers: {
            'Authorization': `Bearer ${token}`,
            'Cache-Control': 'no-cache',
            'Connection': 'keep-alive'
          },
          heartbeatTimeout: 60000
        });

        this.eventSource.onopen = (event) => {
          console.log('SSE connection opened:', event);
          this.reconnectAttempts = 0;
          this.isConnecting = false;
          clearTimeout(this.reconnectTimeout);
        };

        this.eventSource.onerror = (event) => {
          this.isConnecting = false;
          this.handleError(event);
        };

        this.eventSource.onmessage = this.handleMessage.bind(this);
      } catch (error) {
        console.error('Error creating EventSource:', error);
        this.handleError(error);
      }
    }
  }

  handleMessage(event) {
    console.log('Raw SSE event received:', event);
    try {
      const data = JSON.parse(event.data);
      console.log('Parsed SSE data:', data);
      console.log('Current listeners:', this.listeners);
      if (data.type && this.listeners[data.type]) {
        console.log(`Calling ${this.listeners[data.type].length} listeners for event type: ${data.type}`);
        this.listeners[data.type].forEach(callback => callback(data));
      } else if (!data.type) {
        console.warn('Received message without type:', data);
      } else {
        console.warn('No listeners for event type:', data.type);
      }
    } catch (error) {
      console.error('Error processing SSE message:', error);
      console.error('Raw event data:', event.data);
    }
  }

  handleError(error) {
    console.error('SSE error:', error);
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = null;
    }
    
    // Implement exponential backoff
    if (this.reconnectAttempts < this.maxReconnectAttempts) {
      const delay = Math.min(1000 * Math.pow(2, this.reconnectAttempts), 30000);
      this.reconnectAttempts++;
      
      clearTimeout(this.reconnectTimeout);
      this.reconnectTimeout = setTimeout(() => {
        console.log(`Attempting to reconnect (attempt ${this.reconnectAttempts})...`);
        this.connect();
      }, delay);
    } else {
      console.error('Max reconnection attempts reached');
    }
  }

  disconnect() {
    if (this.eventSource) {
      this.eventSource.close();
      this.eventSource = null;
    }
  }

  addListener(eventType, callback) {
    if (!this.listeners[eventType]) {
      this.listeners[eventType] = [];
    }
    this.listeners[eventType].push(callback);
    console.log(`Added listener for event type: ${eventType}`);
    console.log('Current listeners:', this.listeners);
  }

  removeListener(eventType, callback) {
    if (this.listeners[eventType]) {
      this.listeners[eventType] = this.listeners[eventType].filter(cb => cb !== callback);
      console.log(`Removed listener for event type: ${eventType}`);
      console.log('Current listeners:', this.listeners);
    }
  }
}

const sseManager = new SSEManager();

export const useSSE = (eventType, callback) => {
  useEffect(() => {
    sseManager.addListener(eventType, callback);
    return () => {
      sseManager.removeListener(eventType, callback);
    };
  }, [eventType, callback]);
};

export default sseManager;
