import React, { useState } from 'react';
import { 
  VStack, 
  HStack, 
  FormControl, 
  FormLabel, 
  Input, 
  Grid, 
  GridItem, 
  Text, 
  Wrap, 
  WrapItem, 
  Button, 
  Divider, 
  Box, 
  Flex, 
  Tag, 
  TagLabel, 
  TagCloseButton, 
  useColorModeValue, 
  Tooltip, 
  useToast 
} from '@chakra-ui/react';
import { AddIcon, CloseIcon } from '@chakra-ui/icons';
import DayChecklist from './DayChecklist';

const TimeRangeInput = ({ start, end, onChange }) => {
  const toast = useToast();
  const barBg = useColorModeValue('gray.200', 'gray.600');
  const activeBg = useColorModeValue('blue.500', 'blue.300');
  const textColor = useColorModeValue('white', 'gray.800');

  const [tempStart, setTempStart] = useState(start);
  const [tempEnd, setTempEnd] = useState(end);
  const [isValid, setIsValid] = useState(true);

  const validateTime = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    const isValid = (hours > 9 || (hours === 9 && minutes === 0)) && (hours < 20 || (hours === 20 && minutes === 0));
    
    if (!isValid) {
      toast({
        title: "Invalid time",
        description: "Time must be between 9:00 AM and 8:00 PM",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setIsValid(false);
      return false;
    }
    setIsValid(true);
    return true;
  };

  const handleTimeChange = (type) => (e) => {
    if (type === 'start') {
      setTempStart(e.target.value);
    } else {
      setTempEnd(e.target.value);
    }
  };

  const handleBlur = (type) => () => {
    const timeToValidate = type === 'start' ? tempStart : tempEnd;
    if (validateTime(timeToValidate)) {
      onChange({ start: type === 'start' ? tempStart : start, end: type === 'end' ? tempEnd : end });
    } else {
      // Revert to previous valid time
      if (type === 'start') {
        setTempStart(start);
      } else {
        setTempEnd(end);
      }
    }
  };

  const calculateDuration = (start, end) => {
    const [startHours, startMinutes] = start.split(':').map(Number);
    const [endHours, endMinutes] = end.split(':').map(Number);
    let durationMinutes = (endHours * 60 + endMinutes) - (startHours * 60 + startMinutes);
    if (durationMinutes < 0) durationMinutes += 24 * 60;
    return durationMinutes;
  };

  const calculateBarPosition = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    return (hours * 60 + minutes) / 1440 * 100;
  };

  const startPosition = calculateBarPosition(start);
  const endPosition = calculateBarPosition(end);
  const durationMinutes = calculateDuration(start, end);

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent form submission
      e.target.blur(); // Trigger the onBlur event
    }
  };

  return (
    <VStack align="stretch" spacing={4}>
      <Box
        h="30px"
        bg={barBg}
        borderRadius="full"
        position="relative"
        overflow="hidden"
      >
        {startPosition <= endPosition ? (
          <Box
            position="absolute"
            left={`${startPosition}%`}
            right={`${100 - endPosition}%`}
            top="0"
            bottom="0"
            bg={activeBg}
          />
        ) : (
          <>
            <Box
              position="absolute"
              left="0"
              right={`${100 - endPosition}%`}
              top="0"
              bottom="0"
              bg={activeBg}
            />
            <Box
              position="absolute"
              left={`${startPosition}%`}
              right="0"
              top="0"
              bottom="0"
              bg={activeBg}
            />
          </>
        )}
        <Text
          position="absolute"
          width="100%"
          textAlign="center"
          color={textColor}
          fontWeight="bold"
          lineHeight="30px"
          textShadow="0 0 2px rgba(0,0,0,0.5)"
        >
          {Math.floor(durationMinutes / 60)}h {durationMinutes % 60}m
        </Text>
      </Box>
      <HStack justify="space-between">
        <Input
          type="time"
          value={tempStart}
          onChange={handleTimeChange('start')}
          onBlur={handleBlur('start')}
          onKeyDown={handleKeyDown}
          size="sm"
          width="auto"
        />
        <Input
          type="time"
          value={tempEnd}
          onChange={handleTimeChange('end')}
          onBlur={handleBlur('end')}
          onKeyDown={handleKeyDown}
          size="sm"
          width="auto"
        />
      </HStack>
    </VStack>
  );
};

const TagSelector = ({ availableTags = [], selectedTags = [], onTagChange }) => {
  const textColor = useColorModeValue('gray.800', 'white');
  const mutedTextColor = useColorModeValue('gray.600', 'gray.400');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const boxBg = useColorModeValue('gray.50', 'gray.700');

  const handleRemoveTag = (tagId) => {
    const newSelectedTags = selectedTags.filter(id => id !== tagId);
    onTagChange(newSelectedTags);
  };

  const handleAddTag = (tagId) => {
    const newSelectedTags = [...selectedTags, tagId];
    onTagChange(newSelectedTags);
  };

  return (
    <HStack align="start" width="100%" spacing={4}>
      {/* Available Tags Box */}
      <Box 
        width="50%" 
        p={4} 
        borderRadius="md" 
        border="1px" 
        borderColor={borderColor}
        bg={boxBg}
        minH="200px"
      >
        <Text fontSize="md" fontWeight="medium" mb={3} color={textColor}>Available Tags</Text>
        <Wrap spacing={2}>
          {availableTags
            .filter(tag => !selectedTags.includes(tag._id))
            .map(tag => (
              <WrapItem key={tag._id}>
                <Tag
                  size="md"
                  backgroundColor={tag.color || 'blue.500'}
                  color="white"
                  borderRadius="full"
                  px={3}
                  py={1}
                  cursor="pointer"
                  onClick={() => handleAddTag(tag._id)}
                  _hover={{ opacity: 0.8 }}
                >
                  <HStack spacing={2}>
                    <AddIcon boxSize={2.5} />
                    <Text>{tag.name}</Text>
                  </HStack>
                </Tag>
              </WrapItem>
            ))}
          {availableTags.filter(tag => !selectedTags.includes(tag._id)).length === 0 && (
            <Text fontSize="sm" color={mutedTextColor}>No tags available</Text>
          )}
        </Wrap>
      </Box>

      {/* Selected Tags Box */}
      <Box 
        width="50%" 
        p={4} 
        borderRadius="md" 
        border="1px" 
        borderColor={borderColor}
        bg={boxBg}
        minH="200px"
      >
        <Text fontSize="md" fontWeight="medium" mb={3} color={textColor}>Selected Tags</Text>
        <Wrap spacing={2}>
          {selectedTags.map(tagId => {
            const tag = availableTags.find(t => t._id === tagId);
            if (!tag) return null;
            return (
              <WrapItem key={tagId}>
                <Tag
                  size="md"
                  backgroundColor={tag.color || 'blue.500'}
                  color="white"
                  borderRadius="full"
                  px={3}
                  py={1}
                >
                  <HStack spacing={2}>
                    <Text>{tag.name}</Text>
                    <CloseIcon 
                      cursor="pointer" 
                      boxSize={2.5}
                      onClick={() => handleRemoveTag(tagId)}
                      _hover={{ opacity: 0.8 }}
                    />
                  </HStack>
                </Tag>
              </WrapItem>
            );
          })}
          {selectedTags.length === 0 && (
            <Text fontSize="sm" color={mutedTextColor}>No tags selected</Text>
          )}
        </Wrap>
      </Box>
    </HStack>
  );
};

const WorkflowSettings = ({
  settings,
  setSettings,
  availableTags = [],
  phoneNumbers = [],
  assignedNumbers = [],
  setAssignedNumbers,
  handleChange,
  handleDaysChange,
  handleAcceptableTimesChange,
  handleTagsChange,
  handleNumbersChange
}) => {
  const formLabelColor = useColorModeValue('gray.700', 'gray.200');
  const buttonBg = useColorModeValue('gray.100', 'gray.700');
  const buttonHoverBg = useColorModeValue('gray.200', 'gray.600');
  const assignedButtonBg = useColorModeValue('blue.100', 'blue.700');
  const assignedButtonHoverBg = useColorModeValue('blue.200', 'blue.600');

  const handleAssignNumber = (number) => {
    handleNumbersChange([...assignedNumbers, number]);
  };

  const handleUnassignNumber = (number) => {
    handleNumbersChange(assignedNumbers.filter(n => n !== number));
  };

  const handleAssignAll = () => {
    handleNumbersChange([...new Set([...assignedNumbers, ...phoneNumbers])]);
  };

  const handleUnassignAll = () => {
    handleNumbersChange([]);
  };

  return (
    <VStack spacing={8} align="stretch">
      <FormControl>
        <FormLabel fontSize="lg" color={formLabelColor}>Schedule</FormLabel>
        <DayChecklist selectedDays={settings.days} onChange={handleDaysChange} />
      </FormControl>

      <Divider />

      <FormControl>
        <FormLabel fontSize="lg" color={formLabelColor}>Assign Phone Numbers</FormLabel>
        <HStack spacing={4} mb={4}>
          <Button
            size="sm"
            colorScheme="blue"
            onClick={handleAssignAll}
          >
            Assign All
          </Button>
          <Button
            size="sm"
            colorScheme="red"
            onClick={handleUnassignAll}
          >
            Unassign All
          </Button>
        </HStack>
        <Grid templateColumns="repeat(auto-fill, minmax(150px, 1fr))" gap={4}>
          {phoneNumbers.map(number => (
            <GridItem key={number}>
              <Button
                size="sm"
                width="100%"
                bg={assignedNumbers.includes(number) ? assignedButtonBg : buttonBg}
                _hover={{
                  bg: assignedNumbers.includes(number) ? assignedButtonHoverBg : buttonHoverBg
                }}
                onClick={() => assignedNumbers.includes(number) ? handleUnassignNumber(number) : handleAssignNumber(number)}
              >
                {number}
              </Button>
            </GridItem>
          ))}
        </Grid>
      </FormControl>

      <Divider />

      <HStack spacing={8} align="flex-start">
        <FormControl flex={1}>
          <Tooltip label="This is the maximum total texts that can be sent out from this workflow per message." placement="top">
            <FormLabel fontSize="lg" color={formLabelColor}>Max Texts Per Period</FormLabel>
          </Tooltip>
          <Input
            type="number"
            name="maxTextsPerPeriod"
            value={settings.maxTextsPerPeriod}
            onChange={handleChange}
            required
          />
        </FormControl>
        <FormControl flex={1}>
          <Tooltip label="Maximum number of messages that can be sent per minute." placement="top">
            <FormLabel fontSize="lg" color={formLabelColor}>Messages Per Minute</FormLabel>
          </Tooltip>
          <Input
            type="number"
            name="dripsPerMinute"
            value={settings.dripsPerMinute}
            onChange={handleChange}
            min={1}
            required={settings.status === 'published'} // Only required when publishing
          />
        </FormControl>
        <FormControl flex={2}>
          <FormLabel fontSize="lg" color={formLabelColor}>Acceptable Times</FormLabel>
          <TimeRangeInput
            start={settings.acceptableTimes.start}
            end={settings.acceptableTimes.end}
            onChange={handleAcceptableTimesChange}
          />
        </FormControl>
      </HStack>

      <Divider />

      <FormControl>
        <FormLabel fontSize="lg" color={formLabelColor}>Tags</FormLabel>
        <TagSelector
          availableTags={availableTags}
          selectedTags={settings.tags}
          onTagChange={handleTagsChange}
        />
      </FormControl>
    </VStack>
  );
};

export default WorkflowSettings;
